// import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
// import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
// import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
// import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
// import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
// import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
// import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
// import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone';
// import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';

const menuItems = [
    {
        heading: 'DASHBOARD',
        items: [
            {
                name: 'Dashboards',
                icon: SmartToyTwoToneIcon,
                link: '/v2/dashboards',
                items: [
                    {
                        name: 'Icon Menus',
                        link: '/v2/dashboards/iconMenu',
                        badge: '',
                        badgeTooltip: 'Reports Dashboard - version 3.0'
                    }
                ]
            }
        ]
    },

    // Start RPMS Menu
    {
        heading: 'PARISH MANAGEMENT',
        items: [
            {
                name: 'Member\'s Profile',
                icon: AccountTreeTwoToneIcon,
                link: '/v2/parishManagement/members/viewMember/66e543f45fd25abf7ad7dd15'
            },
            {
                name: 'Testimonies',
                icon: AccountTreeTwoToneIcon,
                link: '/v2/parishManagement/testimonies/list'
            },
            {
                name: 'Sermons',
                icon: AccountTreeTwoToneIcon,
                link: '/v2/parishManagement/sermons/list'
            },
            {
                name: 'Upcoming Events',
                icon: AccountTreeTwoToneIcon,
                link: '/v2/parishManagement/events/list'
            },
            {
                name: 'Birthday List',
                icon: AccountTreeTwoToneIcon,
                link: '/v2/parishManagement/birthdays/list'
            },
            {
                name: 'Anniversary List',
                icon: AccountTreeTwoToneIcon,
                link: '/v2/parishManagement/anniversaries/list'
            }
            // {
            //     name: 'Payments',
            //     icon: AccountTreeTwoToneIcon,
            //     link: '/v2/parishManagement/payments/list'
            // },
            // {
            //     name: 'Sermons',
            //     icon: AccountTreeTwoToneIcon,
            //     link: '/v2/parishManagement/sermons/list'
            // },
            // {
            //     name: 'Testimonies',
            //     icon: AccountTreeTwoToneIcon,
            //     link: '/v2/parishManagement/testimonies/list'
            // },
            // {
            //     name: 'TitheBook',
            //     icon: AccountTreeTwoToneIcon,
            //     link: '/v2/parishManagement/titheBook/list'
            // },
            // {
            //     name: 'Income',
            //     icon: AccountTreeTwoToneIcon,
            //     link: '/v2/parishManagement/income/list'
            // },
            // {
            //     name: 'Expenditure',
            //     icon: AccountTreeTwoToneIcon,
            //     link: '/v2/parishManagement/expenditure/list'
            // },
            // {
            //     name: 'Titles',
            //     icon: AccountTreeTwoToneIcon,
            //     link: '/v2/parishManagement/titles/list'
            // },
            // {
            //     name: 'Designations',
            //     icon: AccountTreeTwoToneIcon,
            //     link: '/v2/parishManagement/designations/list'
            // },
            // {
            //     name: 'Parish Members',
            //     icon: AssignmentIndTwoToneIcon,
            //     link: '/v2/management/users',
            //     items: [
            //         {
            //             name: 'View Members',
            //             link: 'management/users/list'
            //         },
            //         {
            //             name: 'Add Members',
            //             link: 'management/users/single'
            //         },
            //         {
            //             name: 'View Workers',
            //             link: 'management/users/list'
            //         },
            //         {
            //             name: 'Add Workers',
            //             link: 'management/users/single'
            //         }
            //     ]
            // },
            // {
            //     name: 'House Fellowship',
            //     icon: StorefrontTwoToneIcon,
            //     link: '/v2/management/commerce',
            //     items: [
            //         {
            //             name: 'View Fellowship',
            //             link: 'management/users/list'
            //         },
            //         {
            //             name: 'Add Fellowship',
            //             link: 'management/users/single'
            //         },
            //         {
            //             name: 'Map Locations',
            //             link: 'management/users/single'
            //         }
            //     ]
            // },
            // {
            //     name: 'Follow Up',
            //     icon: StorefrontTwoToneIcon,
            //     link: '/v2/management/commerce',
            //     items: [
            //         {
            //             name: 'View Follow Ups',
            //             link: 'management/users/list'
            //         },
            //         {
            //             name: 'Add Follow Up',
            //             link: 'management/users/single'
            //         }
            //     ]
            // },
            // {
            //     name: 'Testimonies',
            //     icon: StorefrontTwoToneIcon,
            //     link: '/v2/management/commerce',
            //     items: [
            //         {
            //             name: 'View Testimonies',
            //             link: 'management/users/list'
            //         },
            //         {
            //             name: 'Add Testimonies',
            //             link: 'management/users/single'
            //         }
            //     ]
            // },
            // {
            //     name: 'Sermon Register',
            //     icon: StorefrontTwoToneIcon,
            //     link: '/v2/management/commerce',
            //     items: [
            //         {
            //             name: 'View Sermons',
            //             link: 'management/users/list'
            //         },
            //         {
            //             name: 'Add Sermon',
            //             link: 'management/users/single'
            //         }
            //     ]
            // },
            // {
            //     name: 'Reminders',
            //     icon: StorefrontTwoToneIcon,
            //     link: '/v2/management/commerce',
            //     items: [
            //         {
            //             name: 'Birthday Reminders',
            //             link: 'management/users/list'
            //         },
            //         {
            //             name: 'Anniversary Reminders',
            //             link: 'management/users/single'
            //         },
            //         {
            //             name: 'Notifications',
            //             link: 'management/users/list'
            //         }
            //     ]
            // }
        ]
    }
];

export default menuItems;
