import Authenticated from 'src/components/Authenticated';
import { Navigate } from 'react-router-dom';

import BoxedSidebarLayout from 'src/layouts/BoxedSidebarLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import AccentHeaderLayout from 'src/layouts/AccentHeaderLayout';
import AccentSidebarLayout from 'src/layouts/AccentSidebarLayout';
import ExtendedSidebarLayout from 'src/layouts/ExtendedSidebarLayout';
import CollapsedSidebarLayout from 'src/layouts/CollapsedSidebarLayout';
import BottomNavigationLayout from 'src/layouts/BottomNavigationLayout';
import TopNavigationLayout from 'src/layouts/TopNavigationLayout';

import blocksRoutes from './blocks';
import applicationsRoutes from './applications';
import managementRoutes from './management';
import documentationRoutes from './documentation';
import accountRoutes from './account';
import baseRoutes from './base';

import dashboardsRoutes from './dashboards';

// Remittance Routes
import userRoutes from './common/userAccounts/userRoutes';
import directoryRoutes from './common/directory/directoryRoutes';
import adminReportRoutes from './common/admin/adminReportRoutes';

import rpmsRoutes from './common/rpms/rpmsRoutes';
import messagingRoutes from './common/messaging/messagingRoutes';

// import financeRoutes from './common/finance/financeRoutes';
// import paymentItemRoutes from './common/finance/paymentItemRoutes';

import adminTempReportRoutes from './common/adminTemp/adminReportRoutes';
// import newUpdateRoutes from './common/newUpdate/newUpdateRoutes';
import adminDemoRoutes from './common/adminTemp/adminDemoRoutes';
// test
import sampleReportRoutes from './common/admin/sampleReportRoutes';

import financeRoutes from './common/finance/financeRoutes';
import paymentItemRoutes from './common/finance/paymentItemRoutes';
// import financeSettingsRoutes from './common/finance/financeSettingsRoutes';
import vision2032Routes from './common/vision2032/vision2032Routes';

const router = [
    {
        path: 'account',
        children: accountRoutes
    },
    {
        path: '*',
        element: <BaseLayout />,
        children: baseRoutes
    },

    // Documentation

    {
        path: 'docs',
        element: <DocsLayout />,
        children: documentationRoutes
    },

    // Boxed Sidebar Layout

    {
        path: 'boxed-sidebar',
        element: (
            <Authenticated>
                <BoxedSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: '/',
                element: <Navigate to="dashboards" replace />
            },
            {
                path: 'dashboards',
                children: dashboardsRoutes
            },
            {
                path: 'blocks',
                children: blocksRoutes
            },
            {
                path: 'applications',
                children: applicationsRoutes
            },
            {
                path: 'management',
                children: managementRoutes
            },
            {
                path: 'userAccounts',
                children: userRoutes
            },
            {
                path: 'directory',
                children: directoryRoutes
            },
            {
                path: 'admin',
                children: adminReportRoutes
            },
            {
                path: '/finance',
                children: financeRoutes
            },
            {
                path: 'parishManagement',
                children: rpmsRoutes
            },
            {
                path: 'messaging',
                children: messagingRoutes
            },
            {
                path: 'vision2032',
                children: vision2032Routes
            }
        ]
    },

    // Accent Sidebar Layout

    {
        path: 'accent-sidebar',
        element: (
            <Authenticated>
                <AccentSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: '/',
                element: <Navigate to="dashboards" replace />
            },
            {
                path: 'dashboards',
                children: dashboardsRoutes
            },
            {
                path: 'blocks',
                children: blocksRoutes
            },
            {
                path: 'applications',
                children: applicationsRoutes
            },
            {
                path: 'management',
                children: managementRoutes
            },
            {
                path: 'userAccounts',
                children: userRoutes
            },
            {
                path: 'directory',
                children: directoryRoutes
            },
            {
                path: 'admin',
                children: adminReportRoutes
            },
            {
                path: '/finance',
                children: financeRoutes
            },
            {
                path: 'parishManagement',
                children: rpmsRoutes
            },
            {
                path: 'messaging',
                children: messagingRoutes
            },
            {
                path: 'vision2032',
                children: vision2032Routes
            }
        ]
    },

    // Collapsed Sidebar Layout

    {
        path: 'collapsed-sidebar',
        element: (
            <Authenticated>
                <CollapsedSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: '/',
                element: <Navigate to="dashboards" replace />
            },
            {
                path: 'dashboards',
                children: dashboardsRoutes
            },
            {
                path: 'blocks',
                children: blocksRoutes
            },
            {
                path: 'applications',
                children: applicationsRoutes
            },
            {
                path: 'management',
                children: managementRoutes
            },
            {
                path: 'userAccounts',
                children: userRoutes
            },
            {
                path: 'directory',
                children: directoryRoutes
            },
            {
                path: 'admin',
                children: adminReportRoutes
            },
            {
                path: '/finance',
                children: financeRoutes
            },
            {
                path: 'parishManagement',
                children: rpmsRoutes
            },
            {
                path: 'messaging',
                children: messagingRoutes
            },
            {
                path: 'vision2032',
                children: vision2032Routes
            }
        ]
    },

    // Bottom Navigation Layout

    {
        path: 'bottom-navigation',
        element: (
            <Authenticated>
                <BottomNavigationLayout />
            </Authenticated>
        ),
        children: [
            {
                path: '/',
                element: <Navigate to="dashboards" replace />
            },
            {
                path: 'dashboards',
                children: dashboardsRoutes
            },
            {
                path: 'blocks',
                children: blocksRoutes
            },
            {
                path: 'applications',
                children: applicationsRoutes
            },
            {
                path: 'management',
                children: managementRoutes
            },
            {
                path: 'userAccounts',
                children: userRoutes
            },
            {
                path: 'directory',
                children: directoryRoutes
            },
            {
                path: 'admin',
                children: adminReportRoutes
            },
            {
                path: '/finance',
                children: financeRoutes
            },
            {
                path: 'parishManagement',
                children: rpmsRoutes
            },
            {
                path: 'messaging',
                children: messagingRoutes
            },
            {
                path: 'vision2032',
                children: vision2032Routes
            }
        ]
    },

    // Top Navigation Layout

    {
        path: 'top-navigation',
        element: (
            <Authenticated>
                <TopNavigationLayout />
            </Authenticated>
        ),
        children: [
            {
                path: '/',
                element: <Navigate to="dashboards" replace />
            },
            {
                path: 'dashboards',
                children: dashboardsRoutes
            },
            {
                path: 'blocks',
                children: blocksRoutes
            },
            {
                path: 'applications',
                children: applicationsRoutes
            },
            {
                path: 'management',
                children: managementRoutes
            },
            {
                path: 'userAccounts',
                children: userRoutes
            },
            {
                path: 'directory',
                children: directoryRoutes
            },
            {
                path: 'admin',
                children: adminReportRoutes
            },
            {
                path: '/finance',
                children: financeRoutes
            },
            {
                path: 'parishManagement',
                children: rpmsRoutes
            },
            {
                path: 'messaging',
                children: messagingRoutes
            },
            {
                path: 'vision2032',
                children: vision2032Routes
            }
        ]
    },

    // Accent Header Layout

    {
        path: 'accent-header',
        element: (
            <Authenticated>
                <AccentHeaderLayout />
            </Authenticated>
        ),
        children: [
            {
                path: '/',
                element: <Navigate to="dashboards" replace />
            },
            {
                path: 'dashboards',
                children: dashboardsRoutes
            },
            {
                path: 'blocks',
                children: blocksRoutes
            },
            {
                path: 'applications',
                children: applicationsRoutes
            },
            {
                path: 'management',
                children: managementRoutes
            },
            {
                path: 'userAccounts',
                children: userRoutes
            },
            {
                path: 'directory',
                children: directoryRoutes
            },
            {
                path: 'admin',
                children: adminReportRoutes
            },
            {
                path: '/finance',
                children: financeRoutes
            },
            {
                path: 'parishManagement',
                children: rpmsRoutes
            },
            {
                path: 'messaging',
                children: messagingRoutes
            }
        ]
    },

    // Extended Sidebar Layout

    {
        path: 'v2',
        element: (
            <Authenticated>
                <ExtendedSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: '/',
                element: <Navigate to="dashboards" replace />
            },
            {
                path: 'dashboards',
                children: dashboardsRoutes
            },
            // {
            //     path: 'blocks',
            //     children: blocksRoutes
            // },
            // {
            //     path: 'applications',
            //     children: applicationsRoutes
            // },
            // {
            //     path: 'management',
            //     children: managementRoutes
            // },
            {
                path: 'userAccounts',
                children: userRoutes
            },
            {
                path: 'directory',
                children: directoryRoutes
            },
            {
                path: 'admin',
                children: adminReportRoutes
            },
            {
                path: 'adminTemp',
                children: adminTempReportRoutes
            },
            {
                path: 'adminDemo',
                children: adminDemoRoutes
            },
            {
                path: 'sample',
                children: sampleReportRoutes
            },

            // {
            //     path: 'newUpdate',
            //     children: newUpdateRoutes
            // },

            {
                path: '/finance',
                children: financeRoutes
            },

            {
                path: 'parishManagement',
                children: rpmsRoutes
            },
            {
                path: 'messaging',
                children: messagingRoutes
            },
            {
                path: 'finance',
                children: financeRoutes
            },
            {
                path: 'financePaymentItems',
                children: paymentItemRoutes
            },
            {
                path: 'vision2032',
                children: vision2032Routes
            }
        ]
    },

    {
        path: 'extended-sidebar',
        element: (
            <Authenticated>
                <ExtendedSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: '/',
                element: <Navigate to="dashboards" replace />
            },
            {
                path: 'dashboards',
                children: dashboardsRoutes
            },
            {
                path: 'blocks',
                children: blocksRoutes
            },
            {
                path: 'applications',
                children: applicationsRoutes
            },
            {
                path: 'management',
                children: managementRoutes
            },
            {
                path: 'userAccounts',
                children: userRoutes
            },
            {
                path: 'directory',
                children: directoryRoutes
            },
            {
                path: 'admin',
                children: adminReportRoutes
            },
            {
                path: 'adminTemp',
                children: adminTempReportRoutes
            },
            {
                path: 'adminDemo',
                children: adminDemoRoutes
            },
            {
                path: 'sample',
                children: sampleReportRoutes
            },
            {
                path: '/finance',
                children: financeRoutes
            },
            {
                path: 'parishManagement',
                children: rpmsRoutes
            },
            {
                path: 'messaging',
                children: messagingRoutes
            },
            {
                path: 'finance',
                children: financeRoutes
            },
            {
                path: 'financePaymentItems',
                children: paymentItemRoutes
            },
            {
                path: 'vision2032',
                children: vision2032Routes
            }
        ]
    },

    // Finance
    {
        path: 'finance',
        element: (
            <Authenticated>
                <ExtendedSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: '/',
                element: <Navigate to="i" replace />
            },
            {
                path: 'i',
                children: financeRoutes
            }
        ]
    },

    // Payment Item
    {
        path: 'finance/payment-item',
        element: (
            <Authenticated>
                <ExtendedSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: '/',
                element: <Navigate to="i" replace />
            },
            {
                path: 'i',
                children: paymentItemRoutes
            }
        ]
    }

    // Finance settings
    // {
    //   path: 'finance/settings',
    //   element: (
    //     <Authenticated>
    //       <ExtendedSidebarLayout />
    //     </Authenticated>
    //   ),
    //   children: [
    //     {
    //       path: '/',
    //       element: <Navigate to="i" replace />
    //     },
    //     {
    //       path: 'i',
    //       children: financeSettingsRoutes
    //     },
    //   ]
    // }
];

export default router;
