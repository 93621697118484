const znJSON = {
    Blueprints: '藍圖',
    'Extended Sidebar': '擴展側邊欄',
    'Accent Sidebar': '口音側邊欄',
    'Accent Header': '口音標題',
    'Boxed Sidebar': '盒裝邊欄',
    'Collapsed Sidebar': '折疊邊欄',
    'Bottom Navigation': '底部導航',
    'Top Navigation': '頂部導航',
    'Lead Developer': '首席開發人員',
    'Mega menu': '超級菜單',
    Doctors: '醫生',
    Reports: '報告',
    'Custom dashboard built using the included components':
        '使用包含的組件構建的自定義儀表板',
    Hospital: '醫院',
    Export: '出口',
    'Last year': '去年',
    Expenses: '花費',
    Products: '產品',
    Statistics: '統計數據',
    General: '一般的',
    Automation: '自動化',
    'Data Display': '數據顯示',
    Calendar: '日曆',
    Mailbox: '郵箱',
    Dashboards: '仪表板',
    Analytics: '分析工具',
    Banking: '银行业',
    Commerce: '商业',
    Crypto: '加密货币',
    Finance: '金融',
    Fitness: '健康',
    Healthcare: '卫生保健',
    'Doctors Page': '医生页面',
    'Hospital Overview': '医院概况',
    Helpdesk: '服务台',
    Learning: '学习',
    Monitoring: '监控方式',
    Tasks: '任务',
    Applications: '应用领域',
    'File Manager': '文件管理器',
    'Jobs Platform': '工作平台',
    Messenger: '信使',
    'Projects Board': '项目委员会',
    Management: '管理',
    Users: '用户数',
    'List All': '全部列出',
    'User Profile': '用户资料',
    Projects: '专案',
    Shop: '店铺',
    'Products List': '产品清单',
    'View Product': '查看产品',
    'Create Product': '创建产品',
    Invoices: '发票',
    'View Single': '查看单人',
    'Auth Pages': '验证页面',
    Login: '登录',
    Basic: '基本的',
    Cover: '覆盖',
    Register: '登记',
    Wizard: '巫师',
    'Recover Password': '恢复密码',
    Status: '地位',
    Foundation: '基础',
    'Extra Pages': '额外页面',
    'Error 404': '错误404',
    'Error 500': '错误500',
    'Coming Soon': '即将推出',
    Maintenance: '维护',
    Overview: '概述',
    'Layout Starters': '布局启动器',
    'Starter Kit 1': '入门套件1',
    'Starter Kit 2': '入门套件2',
    Documentation: '文献资料',
    Welcome: '欢迎',
    Help: '帮助',
    'Features tour': '特色游',
    'Getting started guide': '入门指南',
    'Contact support': '联系支持',
    Version: '版本',
    Search: '搜索',
    Notifications: '通知事项',
    Settings: '设定值',
    'Language Switcher': '语言切换器',
    'Sign out': '登出',
    'Change Theme': '改变主题',
    'View all notifications': '查看所有通知',
    'Tokyo NextJS Typescript Admin Dashboard': '东京React管理员仪表板',
    'International Reporting, Remittance and Church Process Automation System by RCCG':
        '高性能React模板由跨多个产品细分市场的大量功能强大的Material-UI组件构建，可实现快速，完美的应用程序开发流程',
    'Browse Live Preview': '浏览实时预览',
    'Live Preview': '实时预览',
    'Key Features': '主要特征',
    dashboards: '仪表板',
    applications: '应用领域',
    'management sections': '管理部分',
    'dark/light themes': '深色/浅色主题',
    components: '成分',
    'Some of the features that make Tokyo one of the best admin templates available today':
        '使东京成为当今可用的最佳管理模板之一的一些功能',
    'Design Source Files': '设计源文件',
    'Start working on your project directly from one of the included starter kits or use the Figma/Sketch files to create a prototype first':
        '直接从随附的入门工具包之一开始处理您的项目，或使用Figma / Sketch文件首先创建原型',
    'We only translated a small part of the template, for demonstration purposes':
        '我们仅翻译了模板的一小部分，以进行演示'
};

export default znJSON;
