import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
    (
        <Suspense fallback={<SuspenseLoader />}>
            <Component {...props} />
        </Suspense>
    );

// Admin Progress Reports
const AdminReports = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport'))
);

const NewAdminReport = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/create'))
);
const ViewAdminReport = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/view'))
);
const ShowAdminReport = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/view'))
);
const ShowAdminMonthlyReport = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/monthlyReport'))
);
const AdminMonthlyReportLevel = Loader(
    lazy(() =>
        import('src/content/common/adminTemp/AdminReport/adminReportLevel')
    )
);

// Admin Reports Source Documents
const AdminSourceDocs = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminSourceDocs'))
);
const NewAdminSourceDoc = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminSourceDocs/create'))
);
const SourceDoc = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminSourceDoc/create'))
);
const ShowDoc = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminSourceDoc/show'))
);
const ShowAdminSourceDoc = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminSourceDocs/show'))
);

// Virtual Reports
const VirtualReports = Loader(
    lazy(() => import('src/content/common/adminTemp/VirtualReport'))
);
const NewVirtualReport = Loader(
    lazy(() => import('src/content/common/adminTemp/VirtualReport/create'))
);
const ShowVirtualReport = Loader(
    lazy(() => import('src/content/common/adminTemp/VirtualReport/show'))
);

// Hgs Reports
const HgsReports = Loader(
    lazy(() => import('src/content/common/adminTemp/HgsReport'))
);
const NewHgsReport = Loader(
    lazy(() => import('src/content/common/adminTemp/HgsReport/create'))
);
const ShowHgsReport = Loader(
    lazy(() => import('src/content/common/adminTemp/HgsReport/show'))
);

const ShowViewCenterReport = Loader(
    lazy(() => import('src/content/common/adminTemp/HgsReport/viewCenter'))
);
const CreateViewCenterReport = Loader(
    lazy(() =>
        import('src/content/common/adminTemp/HgsReport/viewCenter/create')
    )
);
const MailboxStatus = Loader(
    lazy(() => import('src/content/common/adminTemp/Mailbox'))
);

const Mailbox = Loader(
    lazy(() => import('src/content/common/messaging/annoucement'))
);

const Emailbox = Loader(lazy(() => import('src/content/applications/Mailbox')));
const Messaging = Loader(
    lazy(() => import('src/content/common/messaging/Mailbox'))
);

// LetsGoFishing Reports
const LetsGoFishingReports = Loader(
    lazy(() => import('src/content/common/adminTemp/LetsGoFishingReport'))
);

// Csr Reports
const CsrReports = Loader(
    lazy(() => import('src/content/common/adminTemp/CsrReport'))
);

const NewCsrReport = Loader(
    lazy(() => import('src/content/common/adminTemp/CsrReport/create'))
);
const ShowCsrReport = Loader(
    lazy(() => import('src/content/common/adminTemp/CsrReport/view'))
);
const SocialCsrReport = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/indexTable5'))
);
const ReportManager = Loader(
    lazy(() => import('src/content/common/adminTemp/ReportManager'))
);
const ProgressReport = Loader(
    lazy(() => import('src/content/common/adminTemp/ProgressReport'))
);
const TrialBalance = Loader(
    lazy(() => import('src/content/common/adminTemp/TrialBalance'))
);
const ChurchPlanting = Loader(
    lazy(() => import('src/content/common/adminTemp/ChurchPlanting/index'))
);
const ChurchProperty = Loader(
    lazy(() => import('src/content/common/adminTemp/ChurchProperty/show'))
);
const StatusPage = Loader(
    lazy(() => import('src/content/common/adminTemp/StatusReport/show'))
);
const ComplaincePage = Loader(
    lazy(() => import('src/content/common/adminTemp/ComplainceReport/stats'))
);
const RemittancePage = Loader(
    lazy(() =>
        import('src/content/common/adminTemp/ComplainceReport/RemittanceReport')
    )
);

const ParishPlanted = Loader(
    lazy(() => import('src/content/common/adminTemp/ParishesPlanted/create'))
);
const ChurchAnalysis = Loader(
    lazy(() => import('src/content/common/adminTemp/ChurchAnalysis/create'))
);
const AreaRemittance = Loader(
    lazy(() => import('src/content/common/adminTemp/AreaRemittance/create'))
);
// const UploadDoc = Loader(
//     lazy(() => import('src/content/common/adminTemp/UploadDocs/create'))
// );
const ProductCopy = Loader(
    lazy(() => import('src/content/common/adminTemp/ProductCopy'))
);
const InvoiceCopy = Loader(
    lazy(() => import('src/content/common/adminTemp/InvoiceCopy'))
);

// const NewParish = Loader(
//     lazy(() => import('src/content/common/adminTemp/NewParish/create'))
// );

// Admin Settings
const AdminReportSettings = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReportSettings'))
);
const ServiceDays = Loader(
    lazy(() => import('src/content/common/adminTemp/ServiceDays'))
);
const ReasonsForNonRemittance = Loader(
    lazy(() => import('src/content/common/adminTemp/ReasonsForNonRemittance'))
);
const CsrCategories = Loader(
    lazy(() => import('src/content/common/adminTemp/CsrCategories'))
);
const SubCsrCategories = Loader(
    lazy(() => import('src/content/common/adminTemp/SubCsrCategories'))
);

// Sample tables
const SampleDataTable = Loader(
    lazy(() => import('src/content/common/z_SampleDataFolder/DataTable-01'))
);

const SampleDataTable1 = Loader(
    lazy(() => import('src/content/common/z_SampleDataFolder/DataTable-01'))
);
const SampleDataTable2 = Loader(
    lazy(() => import('src/content/common/z_SampleDataFolder/DataTable-02'))
);
const SampleDataTable3 = Loader(
    lazy(() => import('src/content/common/z_SampleDataFolder/DataTable-03'))
);
const SampleDataTable4 = Loader(
    lazy(() => import('src/content/common/z_SampleDataFolder/DataTable-04'))
);
const SampleDataTable5 = Loader(
    lazy(() => import('src/content/common/z_SampleDataFolder/DataTable-05'))
);

const SampleReactTable = Loader(
    lazy(() => import('src/content/common/z_SampleDataFolder/ReactTable-01'))
);
const IndexTable1 = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/indexTable1'))
);
const IndexTable2 = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/indexTable2'))
);
const IndexTable3 = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/indexTable3'))
);
const IndexTable4 = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/indexTable4'))
);
const IndexTable5 = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/indexTable5'))
);
const IndexTable6 = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/indexTable6'))
);
const IndexTable7 = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/indexTable7'))
);
const IndexTable8 = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/indexTable8'))
);
const IndexTable9 = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/indexTable9'))
);

const IndexTable10 = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/IndexTable10'))
);
const IndexTable11 = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/IndexTable11'))
);
const IndexTable12 = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/IndexTable12'))
);

const MonthlyGeneralProgressReport = Loader(
    lazy(() =>
        import('src/content/common/adminTemp/AdminReport/MonthlyGeneralReport')
    )
);
const SourceUpload = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport'))
);

// App Icons
const NewAppIcon = Loader(
    lazy(() => import('src/content/common/adminTemp/AppIcon/create'))
);
// Virtual Service Report
const VirtualServiceReport = Loader(
    lazy(() =>
        import('src/content/common/adminTemp/VirtualServiceReport/create')
    )
);
const HGS = Loader(lazy(() => import('src/content/common/adminTemp/HGS')));
const ApprovalReport = Loader(
    lazy(() => import('src/content/common/adminTemp/ApprovalTable'))
);
const AreaRemit = Loader(
    lazy(() => import('src/content/common/adminTemp/AreaRemit'))
);
const ReportStatus = Loader(
    lazy(() => import('src/content/common/adminTemp/ReportStatus'))
);
const AttendanceReport = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/monthlyReport'))
);

const Vision2032Report = Loader(
    lazy(() => import('src/content/common/adminTemp/Vision2032Report'))
);
const Event = Loader(lazy(() => import('src/content/common/adminTemp/Event')));
const AnnualPerformance = Loader(
    lazy(() => import('src/content/common/adminTemp/AnnualPerformance'))
);
const AvgAttendance = Loader(
    lazy(() => import('src/content/common/adminTemp/AvgAttendance'))
);

const Vision2032 = Loader(
    lazy(() => import('src/content/common/adminTemp/Vision2032'))
);

const MainReport = Loader(
    lazy(() => import('src/content/common/adminTemp/NationalPerformance'))
);
const ListedReport = Loader(
    lazy(() => import('src/content/common/adminTemp/AvgService'))
);
const Vision2032Tiers = Loader(
    lazy(() => import('src/content/common/adminTemp/Vision2032Tiers'))
);
const SingleTier = Loader(
    lazy(() => import('src/content/common/adminTemp/Vision2032Tiers/single'))
);

const RegionToTiers = Loader(
    lazy(() => import('src/content/common/adminTemp/Vision2032Tiers/region'))
);

const PerformanceGoals = Loader(
    lazy(() => import('src/content/common/adminTemp/PerformanceGoals'))
);
const ViewAvgService = Loader(
    lazy(() => import('src/content/common/adminTemp/AvgService/report'))
);
const ViewChurchProperty = Loader(
    lazy(() => import('src/content/common/adminTemp/ChurchProperty/view'))
);
const ViewHgsReport = Loader(
    lazy(() => import('src/content/common/adminTemp/HgsReport/view'))
);
const ViewHgsZoneReport = Loader(
    lazy(() => import('src/content/common/adminTemp/HgsReport/zone'))
);
const ViewHgsAreaReport = Loader(
    lazy(() => import('src/content/common/adminTemp/HgsReport/area'))
);
const ViewHgsParishReport = Loader(
    lazy(() => import('src/content/common/adminTemp/HgsReport/parish'))
);
const ViewProvService = Loader(
    lazy(() => import('src/content/common/adminTemp/AvgService/zone'))
);
const ViewZoneService = Loader(
    lazy(() => import('src/content/common/adminTemp/AvgService/area'))
);
const ViewAreaService = Loader(
    lazy(() => import('src/content/common/adminTemp/AvgService/area'))
);
const ViewParishService = Loader(
    lazy(() => import('src/content/common/adminTemp/AvgService/parish'))
);
const Converts = Loader(
    lazy(() => import('src/content/common/adminTemp/Converts'))
);
const HouseFellowshipAttd = Loader(
    lazy(() => import('src/content/common/adminTemp/HouseFellowshipAvgAttd'))
);
const MrrFirstTimers = Loader(
    lazy(() => import('src/content/common/adminTemp/MrrFirstTimers'))
);
const MrrConverts = Loader(
    lazy(() => import('src/content/common/adminTemp/MrrConverts'))
);
const CsrCompliance = Loader(
    lazy(() => import('src/content/common/adminTemp/CsrCompliance'))
);
const CsrBeneficiaries = Loader(
    lazy(() => import('src/content/common/adminTemp/CsrBeneficiaries'))
);

const ViewReportStatus = Loader(
    lazy(() => import('src/content/common/adminTemp/ReportStatus/statistics'))
);
const AdminReportStatus = Loader(
    lazy(() => import('src/content/common/adminTemp/ReportStatus/view'))
);

const adminReports = [
    {
        path: '/',
        element: <Navigate to="adminReports" replace />
    },
    {
        path: 'adminReports',
        children: [
            {
                path: '/',
                element: <Navigate to="newAdminReport" replace />
            },
            {
                path: 'SampleDataTable',
                element: <SampleDataTable />
            },
            {
                path: 'SampleDataTable1',
                element: <SampleDataTable1 />
            },
            {
                path: 'SampleDataTable2',
                element: <SampleDataTable2 />
            },
            {
                path: 'SampleDataTable3',
                element: <SampleDataTable3 />
            },
            {
                path: 'SampleDataTable4',
                element: <SampleDataTable4 />
            },
            {
                path: 'SampleDataTable5',
                element: <SampleDataTable5 />
            },
            {
                path: 'SampleReactTable',
                element: <SampleReactTable />
            },
            {
                path: 'adminReportSettings',
                element: <AdminReportSettings />
            },
            {
                path: 'serviceDays',
                element: <ServiceDays />
            },
            {
                path: 'reasonsForNonRemittance',
                element: <ReasonsForNonRemittance />
            },
            {
                path: 'newAdminReport',
                element: <NewAdminReport />
            },
            {
                path: 'viewAdminReport',
                element: <AdminReports />
            },
            {
                path: 'IndexTable1',
                element: <IndexTable1 />
            },
            {
                path: 'IndexTable2',
                element: <IndexTable2 />
            },
            {
                path: 'IndexTable3',
                element: <IndexTable3 />
            },
            {
                path: 'IndexTable4',
                element: <IndexTable4 />
            },
            {
                path: 'IndexTable5',
                element: <IndexTable5 />
            },
            {
                path: 'IndexTable6',
                element: <IndexTable6 />
            },
            {
                path: 'IndexTable7',
                element: <IndexTable7 />
            },
            {
                path: 'IndexTable8',
                element: <IndexTable8 />
            },
            {
                path: 'IndexTable9',
                element: <IndexTable9 />
            },
            {
                path: 'IndexTable10',
                element: <IndexTable10 />
            },
            {
                path: 'IndexTable11',
                element: <IndexTable11 />
            },
            {
                path: 'IndexTable12',
                element: <IndexTable12 />
            },

            {
                path: 'viewHgsCenter',
                element: <ShowViewCenterReport />
            },
            {
                path: 'createHgsReport',
                element: <CreateViewCenterReport />
            },

            {
                path: 'monthlyReports',
                element: <MonthlyGeneralProgressReport />
            },
            {
                path: 'monthlyAdminReport',
                element: <ShowAdminMonthlyReport />
            },
            {
                path: 'AdminReport',
                element: <ShowAdminMonthlyReport />
            },
            {
                path: 'adminReportLevel',
                element: <AdminMonthlyReportLevel />
            },
            {
                path: 'viewVirtualReport',
                element: <VirtualServiceReport />
            },
            {
                path: 'hgs',
                element: <HGS />
            },
            {
                path: 'loginChart',
                element: <ApprovalReport />
            },
            {
                path: 'areaRemit',
                element: <AreaRemit />
            },
            {
                path: 'reportStatus',
                element: <ReportStatus />
            },
            {
                path: 'attendanceReport',
                element: <AttendanceReport />
            },
            {
                path: 'showAdminReport',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },

                    {
                        path: ':year/:month/:parishCode',
                        element: <ShowAdminReport />
                    }
                ]
            },
            {
                path: 'newAdminSourceDoc',
                element: <NewAdminSourceDoc />
            },
            {
                path: 'sourceDoc',
                element: <SourceDoc />
            },
            {
                path: 'showDoc',
                element: <ShowDoc />
            },
            {
                path: 'viewAdminSourceDoc',
                element: <AdminSourceDocs />
            },
            {
                path: 'showAdminSourceDoc',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },

                    {
                        path: ':year/:month/:parishCode',
                        element: <ShowAdminSourceDoc />
                    }
                ]
            },
            {
                path: 'newVirtualReport',
                element: <NewVirtualReport />
            },
            {
                path: 'viewVirtualReport',
                element: <VirtualReports />
            },
            {
                path: 'announcement',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="compose" replace />
                    },

                    {
                        path: 'tag/:labelTag',
                        element: <Mailbox />
                    },
                    {
                        path: 'tag/:labelTag/:mailboxCategory',
                        element: <Mailbox />
                    },

                    {
                        path: ':categoryTag',
                        element: <Mailbox />
                    },
                    {
                        path: ':categoryTag/:mailboxCategory',
                        element: <Mailbox />
                    }
                ]
            },
            {
                path: 'showVirtualReport',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },

                    {
                        path: ':year/:month/:parishCode',
                        element: <ShowVirtualReport />
                    }
                ]
            },
            {
                path: 'newHgsReport',
                element: <NewHgsReport />
            },
            {
                path: 'viewHgsReport',
                element: <HgsReports />
            },
            {
                path: 'showHgsReport',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },

                    {
                        path: ':year/:month/:parishCode',
                        element: <ShowHgsReport />
                    }
                ]
            },
            {
                path: 'viewLetsGoFishingReport',
                element: <LetsGoFishingReports />
            },
            {
                path: 'csrCategories',
                element: <CsrCategories />
            },
            {
                path: 'subCsrCategories',
                element: <SubCsrCategories />
            },
            {
                path: 'newCsrReport',
                element: <NewCsrReport />
            },
            {
                path: 'viewCsrReport',
                element: <CsrReports />
            },
            {
                path: 'socialCsrReport',
                element: <SocialCsrReport />
            },
            {
                path: 'reportManager',
                element: <ReportManager />
            },
            {
                path: 'progressReport',
                element: <ProgressReport />
            },
            {
                path: 'trialBalance',
                element: <TrialBalance />
            },
            {
                path: 'event',
                element: <Event />
            },
            {
                path: 'annualPerformance',
                // element: <AnnualPerformance />
                children: [
                    {
                        path: ':id',
                        element: <AnnualPerformance />
                    },

                    {
                        path: '/',
                        element: <AnnualPerformance />
                    }
                ]
            },

            {
                path: 'avgAttendance',
                element: <AvgAttendance />
            },
            {
                path: 'vision2032',
                element: <Vision2032 />
            },
            {
                path: 'vision2032Report',
                element: <Vision2032Report />
            },

            {
                path: 'avgService',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="list" replace />
                    },
                    // {
                    //     path: 'list',
                    //     element: <AvgService />
                    // },
                    {
                        path: 'view',
                        children: [
                            {
                                path: '/',
                                element: <Navigate to="1" replace />
                            },

                            {
                                path: ':parishId',
                                element: <ViewAvgService />
                            }
                        ]
                    },
                    {
                        path: 'province',
                        children: [
                            {
                                path: '/',
                                element: <Navigate to="1" replace />
                            },

                            {
                                path: ':parishId',
                                element: <ViewProvService />
                            }
                        ]
                    },
                    {
                        path: 'zone',
                        children: [
                            {
                                path: '/',
                                element: <Navigate to="1" replace />
                            },

                            {
                                path: ':parishId',
                                element: <ViewZoneService />
                            }
                        ]
                    },
                    {
                        path: 'area',
                        children: [
                            {
                                path: '/',
                                element: <Navigate to="1" replace />
                            },

                            {
                                path: ':parishId',
                                element: <ViewAreaService />
                            }
                        ]
                    },
                    {
                        path: 'parish',
                        children: [
                            {
                                path: '/',
                                element: <Navigate to="1" replace />
                            },

                            {
                                path: ':parishId',
                                element: <ViewParishService />
                            }
                        ]
                    }
                ]
            },
            {
                path: '/vision2032',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="vision2032" replace />
                    },
                    {
                        path: '/tiers',
                        children: [
                            {
                                path: '/',
                                element: <Navigate to="list" replace />
                            },
                            {
                                path: 'list',
                                element: <Vision2032Tiers />
                            },
                            {
                                path: 'link',
                                element: <RegionToTiers />
                            },
                            {
                                path: 'view',
                                children: [
                                    {
                                        path: '/',
                                        element: <Navigate to="1" replace />
                                    },
                                    {
                                        path: ':tierId',
                                        element: <SingleTier />
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: '/main',
                        children: [
                            {
                                path: '/',
                                element: <Navigate to="list" replace />
                            },
                            {
                                path: 'list',
                                element: <MainReport />
                            }
                        ]
                    },
                    {
                        path: '/listed',
                        children: [
                            {
                                path: '/',
                                element: <Navigate to="list" replace />
                            },
                            {
                                path: 'list',
                                element: <ListedReport />
                            },
                            {
                                path: 'view',
                                children: [
                                    {
                                        path: '/',
                                        element: <Navigate to="1" replace />
                                    },
                                    {
                                        path: ':parishId',
                                        element: <ViewAvgService />
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },

            {
                path: 'performanceGoals',
                element: <PerformanceGoals />
            },
            {
                path: 'converts',
                element: <Converts />
            },
            {
                path: 'houseFellowshipAttd',
                element: <HouseFellowshipAttd />
            },
            {
                path: 'mrrFirstTimers',
                element: <MrrFirstTimers />
            },
            {
                path: 'mrrConverts',
                element: <MrrConverts />
            },
            {
                path: 'csrCompliance',
                element: <CsrCompliance />
            },
            {
                path: 'csrBeneficiaries',
                element: <CsrBeneficiaries />
            },

            {
                path: 'churchPlanting',
                element: <ChurchPlanting />
            },
            {
                path: 'reportStatus',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: 'list',
                        element: <ReportStatus />
                    },
                    {
                        path: 'view',
                        children: [
                            {
                                path: ':parishId',
                                element: <ViewReportStatus />
                            }
                        ]
                    },
                    {
                        path: 'status',
                        children: [
                            {
                                path: ':parishId',
                                element: <AdminReportStatus />
                            }
                        ]
                    },
                    {
                        path: ':parishId',
                        element: <ViewReportStatus />
                    }
                ]
            },
            {
                path: 'churchProperty',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="list" replace />
                    },
                    {
                        path: 'list',
                        element: <ChurchProperty />
                    },
                    {
                        path: 'view',
                        children: [
                            {
                                path: '/',
                                element: <Navigate to="1" replace />
                            },

                            {
                                path: ':parishId',
                                element: <ViewChurchProperty />
                            }
                        ]
                    }
                ]
            },
            {
                path: 'statusPage',
                element: <StatusPage />
            },
            {
                path: 'complaincePage',
                element: <ComplaincePage />
            },
            {
                path: 'remittancePage',
                element: <RemittancePage />
            },
            {
                path: 'sourceUpload',
                element: <SourceUpload />
            },
            // {
            //     path: 'loginChart',
            //     element: <LoginChart />
            // },
            {
                path: 'parishPlanted',
                element: <ParishPlanted />
            },
            {
                path: 'churchAnalysis',
                element: <ChurchAnalysis />
            },
            {
                path: 'areaRemittance',
                element: <AreaRemittance />
            },
            {
                path: 'productCopy',
                element: <ProductCopy />
            },
            {
                path: 'invoiceCopy',
                element: <InvoiceCopy />
            },
            {
                path: 'mailbox',
                element: <MailboxStatus />
            },
            {
                path: 'hgsReport',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="list" replace />
                    },
                    {
                        path: 'list',
                        element: <HgsReports />
                    },
                    {
                        path: 'province',
                        children: [
                            {
                                path: '/',
                                element: <Navigate to="1" replace />
                            },

                            {
                                path: ':parishId',
                                element: <ViewHgsZoneReport />
                            }
                        ]
                    },
                    {
                        path: 'zone',
                        children: [
                            {
                                path: '/',
                                element: <Navigate to="1" replace />
                            },

                            {
                                path: ':parishId',
                                element: <ViewHgsReport />
                            }
                        ]
                    },
                    {
                        path: 'area',
                        children: [
                            {
                                path: '/',
                                element: <Navigate to="1" replace />
                            },

                            {
                                path: ':parishId',
                                element: <ViewHgsAreaReport />
                            }
                        ]
                    },
                    {
                        path: 'parish',
                        children: [
                            {
                                path: '/',
                                element: <Navigate to="1" replace />
                            },

                            {
                                path: ':parishId',
                                element: <ViewHgsParishReport />
                            }
                        ]
                    }
                ]
            },

            // {
            //     path: `inbox`,
            //     element: <Single />
            // },
            // {
            //     path: 'mail',
            //     element: <MailboxFlow />
            // },
            {
                path: 'viewVision2032Report',
                element: <Vision2032Report />
            },

            {
                path: 'showCsrReport',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },

                    {
                        path: ':year/:month/:parishCode',
                        element: <ShowCsrReport />
                    }
                ]
            },

            {
                path: 'emailbox',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="inbox" replace />
                    },

                    {
                        path: 'tag/:labelTag',
                        element: <Emailbox />
                    },
                    {
                        path: 'tag/:labelTag/:mailboxCategory',
                        element: <Emailbox />
                    },
                    {
                        path: ':categoryTag',
                        element: <Emailbox />
                    },
                    {
                        path: ':categoryTag/:mailboxCategory',
                        element: <Emailbox />
                    }
                ]
            },
            {
                path: 'products',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="list" replace />
                    },
                    {
                        path: 'list',
                        element: <ShowAdminReport />
                    },
                    {
                        path: 'create',
                        element: <NewAdminReport />
                    },
                    {
                        path: 'single',
                        children: [
                            {
                                path: '/',
                                element: <Navigate to="1" replace />
                            },
                            {
                                path: ':reportId',
                                element: <ViewAdminReport />
                            }
                        ]
                    }
                ]
            },
            {
                path: 'messaging',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="inbox" replace />
                    },

                    {
                        path: 'tag/:labelTag',
                        element: <Messaging />
                    },
                    {
                        path: 'tag/:labelTag/:mailboxCategory',
                        element: <Messaging />
                    },
                    {
                        path: ':categoryTag',
                        element: <Messaging />
                    },
                    {
                        path: ':categoryTag/:mailboxCategory',
                        element: <Messaging />
                    }
                ]
            }
        ]
    },

    {
        path: 'app-icons',
        element: <NewAppIcon />
    }
];

export default adminReports;
