// import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
// import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
// import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
// import EventIcon from '@mui/icons-material/Event';
// import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
// import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
// import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
// import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
// import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone';
// import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';

const menuItems = [
    {
        heading: 'Main Menu',
        items: [
            {
                name: 'Main Menu',
                icon: SmartToyTwoToneIcon,

                items: [
                    {
                        name: 'Icon Menus',
                        link: '/v2/dashboards/iconMenu'
                    }
                ]
            }
        ]
    },

    // Start Directory Menu
    {
        heading: 'Directory',
        items: [
            {
                name: 'Directory',
                icon: AssignmentIndTwoToneIcon,

                items: [
                    {
                        name: 'Directory',
                        link: '/v2/dashboards/directory'
                    }
                ]
            }
        ]
    },

    // Start Deb log and Unlocks Menu
    {
        heading: 'Debt Log and Unlocks',
        items: [
            {
                name: 'Debt Log',
                icon: AssignmentIndTwoToneIcon,

                items: [
                    {
                        name: 'Debt Log and Extensions',
                        link: '/v2/dashboards/debt-log'
                    }
                ]
            }
        ]
    },

    // Start RPMS Menu
    {
        heading: 'Birthday & Events',
        items: [
            {
                name: 'Birthday & Events',
                icon: AssignmentIndTwoToneIcon,

                items: [
                    {
                        name: 'Birthday',
                        link: '/v2/dashboards/birthday'
                    }
                    // {
                    //     name: 'Members Birthday ',
                    //     link: '#'
                    // },
                    // {
                    //     name: 'Profiling for Members',
                    //     link: '#'
                    // }
                ]
            }
        ]
    },

    // Start NEW PAGE

    {
        heading: 'CSR',
        items: [
            {
                name: 'CSR',
                icon: AssignmentIndTwoToneIcon,

                items: [
                    {
                        name: 'CSR Main Compliance',
                        link: '/v2/dashboards/csr'
                    }
                    // {
                    //     name: 'CSR Most/Least Active',
                    //     link: '#'
                    // },
                    // {
                    //     name: 'CSR Report by State',
                    //     link: '#'
                    // },
                    // {
                    //     name: 'CSR Report Monthly ',
                    //     link: '#'
                    // },
                    // {
                    //     name: 'CSR Annual Report ',
                    //     link: '#'
                    // }
                ]
            }
        ]
    },

    // New updates start here
    {
        heading: 'Church & Pastor Manager',
        items: [
            {
                name: 'Church Manager',
                icon: AssignmentIndTwoToneIcon,

                items: [
                    {
                        name: 'Church Manager ',
                        link: '/v2/dashboards/church-manager'
                    }
                ]
            }
        ]
    },
    {
        heading: 'Manager Users ',
        items: [
            {
                name: ' Users',
                icon: AssignmentIndTwoToneIcon,

                items: [
                    {
                        name: ' Users',
                        link: '/v2/userAccounts/users'
                    }
                ]
            }
        ]
    },
    {
        heading: 'Payment Manager',
        items: [
            {
                name: 'Payment Manager',
                icon: AssignmentIndTwoToneIcon,

                items: [
                    {
                        name: 'Payment Manager',
                        link: '/v2/dashboards/payment-manager'
                    }
                ]
            }
        ]
    }
    // {
    //     heading: 'Reports',
    //     items: [
    //         {
    //             name: 'Reports',
    //             icon: AssignmentIndTwoToneIcon,
    //             link: '/v2/userAccounts/users',
    //             items: [
    //                 {
    //                     name: 'Annual Thanksgiving',
    //                     link: '#',
    //                     badge: '',
    //                     badgeTooltip: 'Annual Thanksgiving'
    //                 },

    //             ]
    //         }
    //     ]
    // }
];

export default menuItems;
