import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
    (
        <Suspense fallback={<SuspenseLoader />}>
            <Component {...props} />
        </Suspense>
    );

// Admin Progress Reports
const AdminReports = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport'))
);

const NewAdminReport = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/create'))
);
const ViewAdminReport = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/view'))
);
const ShowAdminReport = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/view'))
);
const ShowAdminMonthlyReport = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/monthlyReport'))
);
const AdminMonthlyReportLevel = Loader(
    lazy(() =>
        import('src/content/common/adminTemp/AdminReport/adminReportLevel')
    )
);

// Admin Reports Source Documents
const AdminSourceDocs = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminSourceDocs'))
);
const NewAdminSourceDoc = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminSourceDocs/create'))
);
const SourceDoc = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminSourceDoc/create'))
);
const ShowDoc = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminSourceDoc/show'))
);
const ShowAdminSourceDoc = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminSourceDocs/show'))
);

// Virtual Reports
const VirtualReports = Loader(
    lazy(() => import('src/content/common/adminTemp/VirtualReport'))
);
const NewVirtualReport = Loader(
    lazy(() => import('src/content/common/adminTemp/VirtualReport/create'))
);
const ShowVirtualReport = Loader(
    lazy(() => import('src/content/common/adminTemp/VirtualReport/show'))
);

// Hgs Reports
const HgsReports = Loader(
    lazy(() => import('src/content/common/adminTemp/HgsReport'))
);
const NewHgsReport = Loader(
    lazy(() => import('src/content/common/adminTemp/HgsReport/create'))
);
const ShowHgsReport = Loader(
    lazy(() => import('src/content/common/adminTemp/HgsReport/show'))
);

const ShowViewCenterReport = Loader(
    lazy(() => import('src/content/common/adminTemp/HgsReport/viewCenter'))
);
const CreateViewCenterReport = Loader(
    lazy(() =>
        import('src/content/common/adminTemp/HgsReport/viewCenter/create')
    )
);
const MailboxStatus = Loader(
    lazy(() => import('src/content/common/adminTemp/Mailbox'))
);

// const Mailbox = Loader(
//     lazy(() => import('src/content/common/newUpdate/Mailbox'))
// );

const Emailbox = Loader(lazy(() => import('src/content/applications/Mailbox')));
const Messaging = Loader(
    lazy(() => import('src/content/common/messaging/Mailbox'))
);

// LetsGoFishing Reports
const LetsGoFishingReports = Loader(
    lazy(() => import('src/content/common/adminTemp/LetsGoFishingReport'))
);

// Csr Reports
const CsrReports = Loader(
    lazy(() => import('src/content/common/adminTemp/CsrReport'))
);

const NewCsrReport = Loader(
    lazy(() => import('src/content/common/adminTemp/CsrReport/create'))
);
const ShowCsrReport = Loader(
    lazy(() => import('src/content/common/adminTemp/CsrReport/show'))
);
const SocialCsrReport = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/indexTable5'))
);
const ReportManager = Loader(
    lazy(() => import('src/content/common/adminTemp/ReportManager'))
);
const ProgressReport = Loader(
    lazy(() => import('src/content/common/adminTemp/ProgressReport'))
);
const TrialBalance = Loader(
    lazy(() => import('src/content/common/adminTemp/TrialBalance'))
);
const ChurchPlanting = Loader(
    lazy(() => import('src/content/common/adminTemp/ChurchPlanting/index'))
);
const ChurchProperty = Loader(
    lazy(() => import('src/content/common/adminTemp/ChurchProperty/show'))
);
const StatusPage = Loader(
    lazy(() => import('src/content/common/adminTemp/StatusReport/show'))
);
const ComplaincePage = Loader(
    lazy(() => import('src/content/common/adminTemp/ComplainceReport/stats'))
);
const RemittancePage = Loader(
    lazy(() =>
        import('src/content/common/adminTemp/ComplainceReport/RemittanceReport')
    )
);

const ParishPlanted = Loader(
    lazy(() => import('src/content/common/adminTemp/ParishesPlanted/create'))
);
const ChurchAnalysis = Loader(
    lazy(() => import('src/content/common/adminTemp/ChurchAnalysis/create'))
);
const AreaRemittance = Loader(
    lazy(() => import('src/content/common/adminTemp/AreaRemittance/create'))
);
// const UploadDoc = Loader(
//     lazy(() => import('src/content/common/adminTemp/UploadDocs/create'))
// );
const ProductCopy = Loader(
    lazy(() => import('src/content/common/adminTemp/ProductCopy'))
);
const InvoiceCopy = Loader(
    lazy(() => import('src/content/common/adminTemp/InvoiceCopy'))
);

// const NewParish = Loader(
//     lazy(() => import('src/content/common/adminTemp/NewParish/create'))
// );

// Admin Settings
const AdminReportSettings = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReportSettings'))
);
const ServiceDays = Loader(
    lazy(() => import('src/content/common/adminTemp/ServiceDays'))
);
const ReasonsForNonRemittance = Loader(
    lazy(() => import('src/content/common/adminTemp/ReasonsForNonRemittance'))
);
const CsrCategories = Loader(
    lazy(() => import('src/content/common/adminTemp/CsrCategories'))
);
const SubCsrCategories = Loader(
    lazy(() => import('src/content/common/adminTemp/SubCsrCategories'))
);

// Sample tables
const SampleDataTable = Loader(
    lazy(() => import('src/content/common/z_SampleDataFolder/DataTable-01'))
);

const SampleDataTable1 = Loader(
    lazy(() => import('src/content/common/z_SampleDataFolder/DataTable-01'))
);
const SampleDataTable2 = Loader(
    lazy(() => import('src/content/common/z_SampleDataFolder/DataTable-02'))
);
const SampleDataTable3 = Loader(
    lazy(() => import('src/content/common/z_SampleDataFolder/DataTable-03'))
);
const SampleDataTable4 = Loader(
    lazy(() => import('src/content/common/z_SampleDataFolder/DataTable-04'))
);
const SampleDataTable5 = Loader(
    lazy(() => import('src/content/common/z_SampleDataFolder/DataTable-05'))
);

const SampleReactTable = Loader(
    lazy(() => import('src/content/common/z_SampleDataFolder/ReactTable-02'))
);
const IndexTable1 = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/indexTable1'))
);
const IndexTable2 = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/indexTable2'))
);
const IndexTable3 = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/indexTable3'))
);
const IndexTable4 = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/indexTable4'))
);
const IndexTable5 = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/indexTable5'))
);
const IndexTable6 = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/indexTable6'))
);
const IndexTable7 = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/indexTable7'))
);
const IndexTable8 = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/indexTable8'))
);
const IndexTable9 = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/indexTable9'))
);

const IndexTable10 = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/IndexTable10'))
);
const IndexTable11 = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/IndexTable11'))
);
const IndexTable12 = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/IndexTable12'))
);

const MuiReactTableDemo1 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo1')
    )
);

const MuiReactTableDemo2 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo2')
    )
);

const MuiReactTableDemo3 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo3')
    )
);

const MuiReactTableDemo4 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo4')
    )
);

const MuiReactTableDemo5 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo5')
    )
);

const MuiReactTableDemo6 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo6')
    )
);

const MuiReactTableDemo7 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo7')
    )
);

const MuiReactTableDemo8 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo8')
    )
);

const MuiReactTableDemo9 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo9')
    )
);

const MuiReactTableDemo10 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo10')
    )
);

const MuiReactTableDemo11 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo11')
    )
);

const MuiReactTableDemo12 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo12')
    )
);

const MuiReactTableDemo13 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo13')
    )
);

const MuiReactTableDemo14 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo14')
    )
);

const MuiReactTableDemo15 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo15')
    )
);
const MuiReactTableDemo16 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo16')
    )
);
const MuiReactTableDemo17 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo17')
    )
);
const MuiReactTableDemo18 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo18')
    )
);
const MuiReactTableDemo19 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo19')
    )
);
const MuiReactTableDemo20 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo20')
    )
);
const MuiReactTableDemo21 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo21')
    )
);
const MuiReactTableDemo22 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo22')
    )
);
const MuiReactTableDemo23 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo23')
    )
);
const MuiReactTableDemo24 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo24')
    )
);
const MuiReactTableDemo25 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo25')
    )
);
const MuiReactTableDemo26 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo26')
    )
);
const MuiReactTableDemo27 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo27')
    )
);
const MuiReactTableDemo28 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo28')
    )
);
const MuiReactTableDemo29 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo29')
    )
);
const MuiReactTableDemo30 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo30')
    )
);
const MuiReactTableDemo31 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo31')
    )
);
const MuiReactTableDemo32 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo32')
    )
);
const MuiReactTableDemo33 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo33')
    )
);
const MuiReactTableDemo34 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo34')
    )
);
const MuiReactTableDemo35 = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo35')
    )
);

// geolocation
const GeolocationDemo1 = Loader(
    lazy(() => import('src/content/common/z_SampleDataFolder/GeolocationDemo1'))
);

const MonthlyGeneralProgressReport = Loader(
    lazy(() =>
        import('src/content/common/adminTemp/AdminReport/MonthlyGeneralReport')
    )
);
const SourceUpload = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport'))
);

// App Icons
const NewAppIcon = Loader(
    lazy(() => import('src/content/common/adminTemp/AppIcon/create'))
);
// Virtual Service Report
const VirtualServiceReport = Loader(
    lazy(() =>
        import('src/content/common/adminTemp/VirtualServiceReport/create')
    )
);
const HGS = Loader(lazy(() => import('src/content/common/adminTemp/HGS')));
const ApprovalReport = Loader(
    lazy(() => import('src/content/common/adminTemp/ApprovalTable'))
);
const AreaRemit = Loader(
    lazy(() => import('src/content/common/adminTemp/AreaRemit'))
);
const ReportStatus = Loader(
    lazy(() => import('src/content/common/adminTemp/ReportStatus'))
);
const AttendanceReport = Loader(
    lazy(() => import('src/content/common/adminTemp/AdminReport/monthlyReport'))
);

const Vision2032Report = Loader(
    lazy(() => import('src/content/common/adminTemp/Vision2032Report'))
);
const Event = Loader(lazy(() => import('src/content/common/adminTemp/Event')));
const AnnualPerformance = Loader(
    lazy(() => import('src/content/common/adminTemp/AnnualPerformance'))
);
const AvgAttendance = Loader(
    lazy(() => import('src/content/common/adminTemp/AvgAttendance'))
);

const Vision2032 = Loader(
    lazy(() => import('src/content/common/adminTemp/Vision2032'))
);

const NationalPerformance = Loader(
    lazy(() => import('src/content/common/adminTemp/NationalPerformance'))
);
const AvgService = Loader(
    lazy(() => import('src/content/common/adminTemp/AvgService'))
);
const Vision2032Tiers = Loader(
    lazy(() => import('src/content/common/adminTemp/Vision2032Tiers'))
);
const RegionToTiers = Loader(
    lazy(() => import('src/content/common/adminTemp/Vision2032Tiers/region'))
);
const SetBenchmarks = Loader(
    lazy(() =>
        import('src/content/common/adminTemp/Vision2032Tiers/benchmarks/set')
    )
);
const ViewBenchmarks = Loader(
    lazy(() =>
        import('src/content/common/adminTemp/Vision2032Tiers/benchmarks/view')
    )
);
const PerformanceGoals = Loader(
    lazy(() => import('src/content/common/adminTemp/PerformanceGoals'))
);
const ViewAvgService = Loader(
    lazy(() => import('src/content/common/adminTemp/AvgService/report'))
);
const ViewChurchProperty = Loader(
    lazy(() => import('src/content/common/adminTemp/ChurchProperty/view'))
);
const ViewHgsReport = Loader(
    lazy(() => import('src/content/common/adminTemp/HgsReport/view'))
);
const ViewHgsZoneReport = Loader(
    lazy(() => import('src/content/common/adminTemp/HgsReport/zone'))
);
const ViewHgsAreaReport = Loader(
    lazy(() => import('src/content/common/adminTemp/HgsReport/area'))
);
const ViewHgsParishReport = Loader(
    lazy(() => import('src/content/common/adminTemp/HgsReport/parish'))
);
const ViewProvService = Loader(
    lazy(() => import('src/content/common/adminTemp/AvgService/zone'))
);
const ViewZoneService = Loader(
    lazy(() => import('src/content/common/adminTemp/AvgService/area'))
);
const ViewAreaService = Loader(
    lazy(() => import('src/content/common/adminTemp/AvgService/area'))
);
const ViewParishService = Loader(
    lazy(() => import('src/content/common/adminTemp/AvgService/parish'))
);
const Converts = Loader(
    lazy(() => import('src/content/common/adminTemp/Converts'))
);
const HouseFellowshipAttd = Loader(
    lazy(() => import('src/content/common/adminTemp/HouseFellowshipAvgAttd'))
);
const MrrFirstTimers = Loader(
    lazy(() => import('src/content/common/adminTemp/MrrFirstTimers'))
);
const MrrConverts = Loader(
    lazy(() => import('src/content/common/adminTemp/MrrConverts'))
);
const CsrCompliance = Loader(
    lazy(() => import('src/content/common/adminTemp/CsrCompliance'))
);
const CsrBeneficiaries = Loader(
    lazy(() => import('src/content/common/adminTemp/CsrBeneficiaries'))
);

const ViewReportStatus = Loader(
    lazy(() => import('src/content/common/adminTemp/ReportStatus/statistics'))
);
const AdminReportStatus = Loader(
    lazy(() => import('src/content/common/adminTemp/ReportStatus/view'))
);
// const ViewMonthlyReport = Loader(
//     lazy(() => import('src/content/common/newUpdate/monthlyReport/view'))
// );

// const TrialBalance = Loader(
//     lazy(() => import('src/content/common/newUpdate/TrialBalance'))
// );

const adminDemos = [
    {
        path: '/',
        element: <Navigate to="adminDemos" replace />
    },
    {
        path: 'adminDemos',
        children: [
            {
                path: '/',
                element: <Navigate to="newAdminReport" replace />
            },
            {
                path: 'SampleDataTable',
                element: <SampleDataTable />
            },
            {
                path: 'SampleDataTable1',
                element: <SampleDataTable1 />
            },
            {
                path: 'SampleDataTable2',
                element: <SampleDataTable2 />
            },
            {
                path: 'SampleDataTable3',
                element: <SampleDataTable3 />
            },
            {
                path: 'SampleDataTable4',
                element: <SampleDataTable4 />
            },
            {
                path: 'SampleDataTable5',
                element: <SampleDataTable5 />
            },
            {
                path: 'SampleReactTable',
                element: <SampleReactTable />
            },
            {
                path: 'adminReportSettings',
                element: <AdminReportSettings />
            },
            {
                path: 'serviceDays',
                element: <ServiceDays />
            },
            {
                path: 'reasonsForNonRemittance',
                element: <ReasonsForNonRemittance />
            },
            {
                path: 'newAdminReport',
                element: <NewAdminReport />
            },
            {
                path: 'viewAdminReport',
                element: <AdminReports />
            },
            {
                path: 'IndexTable1',
                element: <IndexTable1 />
            },
            {
                path: 'IndexTable2',
                element: <IndexTable2 />
            },
            {
                path: 'IndexTable3',
                element: <IndexTable3 />
            },
            {
                path: 'IndexTable4',
                element: <IndexTable4 />
            },
            {
                path: 'IndexTable5',
                element: <IndexTable5 />
            },
            {
                path: 'IndexTable6',
                element: <IndexTable6 />
            },
            {
                path: 'IndexTable7',
                element: <IndexTable7 />
            },
            {
                path: 'IndexTable8',
                element: <IndexTable8 />
            },
            {
                path: 'IndexTable9',
                element: <IndexTable9 />
            },
            {
                path: 'IndexTable10',
                element: <IndexTable10 />
            },
            {
                path: 'IndexTable11',
                element: <IndexTable11 />
            },
            {
                path: 'IndexTable12',
                element: <IndexTable12 />
            },
            {
                path: 'MuiReactTableDemo1',
                element: <MuiReactTableDemo1 />
            },
            {
                path: 'MuiReactTableDemo2',
                element: <MuiReactTableDemo2 />
            },
            {
                path: 'MuiReactTableDemo3',
                element: <MuiReactTableDemo3 />
            },
            {
                path: 'MuiReactTableDemo4',
                element: <MuiReactTableDemo4 />
            },
            {
                path: 'MuiReactTableDemo4',
                element: <MuiReactTableDemo4 />
            },
            {
                path: 'MuiReactTableDemo5',
                element: <MuiReactTableDemo5 />
            },
            {
                path: 'MuiReactTableDemo6',
                element: <MuiReactTableDemo6 />
            },
            {
                path: 'MuiReactTableDemo7',
                element: <MuiReactTableDemo7 />
            },
            {
                path: 'MuiReactTableDemo8',
                element: <MuiReactTableDemo8 />
            },
            {
                path: 'MuiReactTableDemo9',
                element: <MuiReactTableDemo9 />
            },
            {
                path: 'MuiReactTableDemo10',
                element: <MuiReactTableDemo10 />
            },
            {
                path: 'MuiReactTableDemo11',
                element: <MuiReactTableDemo11 />
            },
            {
                path: 'MuiReactTableDemo12',
                element: <MuiReactTableDemo12 />
            },
            {
                path: 'MuiReactTableDemo13',
                element: <MuiReactTableDemo13 />
            },
            {
                path: 'MuiReactTableDemo14',
                element: <MuiReactTableDemo14 />
            },
            {
                path: 'MuiReactTableDemo15',
                element: <MuiReactTableDemo15 />
            },
            {
                path: 'MuiReactTableDemo16',
                element: <MuiReactTableDemo16 />
            },
            {
                path: 'MuiReactTableDemo17',
                element: <MuiReactTableDemo17 />
            },
            {
                path: 'MuiReactTableDemo18',
                element: <MuiReactTableDemo18 />
            },
            {
                path: 'MuiReactTableDemo19',
                element: <MuiReactTableDemo19 />
            },
            {
                path: 'MuiReactTableDemo20',
                element: <MuiReactTableDemo20 />
            },
            {
                path: 'MuiReactTableDemo21',
                element: <MuiReactTableDemo21 />
            },
            {
                path: 'MuiReactTableDemo22',
                element: <MuiReactTableDemo22 />
            },
            {
                path: 'MuiReactTableDemo23',
                element: <MuiReactTableDemo23 />
            },
            {
                path: 'MuiReactTableDemo24',
                element: <MuiReactTableDemo24 />
            },
            {
                path: 'MuiReactTableDemo25',
                element: <MuiReactTableDemo25 />
            },
            {
                path: 'MuiReactTableDemo26',
                element: <MuiReactTableDemo26 />
            },
            {
                path: 'MuiReactTableDemo27',
                element: <MuiReactTableDemo27 />
            },
            {
                path: 'MuiReactTableDemo28',
                element: <MuiReactTableDemo28 />
            },
            {
                path: 'MuiReactTableDemo29',
                element: <MuiReactTableDemo29 />
            },
            {
                path: 'MuiReactTableDemo30',
                element: <MuiReactTableDemo30 />
            },
            {
                path: 'MuiReactTableDemo31',
                element: <MuiReactTableDemo31 />
            },
            {
                path: 'MuiReactTableDemo32',
                element: <MuiReactTableDemo32 />
            },
            {
                path: 'MuiReactTableDemo33',
                element: <MuiReactTableDemo33 />
            },
            {
                path: 'MuiReactTableDemo34',
                element: <MuiReactTableDemo34 />
            },
            {
                path: 'MuiReactTableDemo35',
                element: <MuiReactTableDemo35 />
            },
            {
                path: 'GeolocationDemo1',
                element: <GeolocationDemo1 />
            },

            {
                path: 'viewHgsCenter',
                element: <ShowViewCenterReport />
            },
            {
                path: 'createHgsReport',
                element: <CreateViewCenterReport />
            },

            {
                path: 'monthlyReports',
                element: <MonthlyGeneralProgressReport />
            },
            {
                path: 'monthlyAdminReport',
                element: <ShowAdminMonthlyReport />
            },
            {
                path: 'AdminReport',
                element: <ShowAdminMonthlyReport />
            },
            {
                path: 'adminReportLevel',
                element: <AdminMonthlyReportLevel />
            },
            {
                path: 'viewVirtualReport',
                element: <VirtualServiceReport />
            },
            {
                path: 'hgs',
                element: <HGS />
            },
            {
                path: 'loginChart',
                element: <ApprovalReport />
            },
            {
                path: 'areaRemit',
                element: <AreaRemit />
            },
            {
                path: 'reportStatus',
                element: <ReportStatus />
            },
            {
                path: 'attendanceReport',
                element: <AttendanceReport />
            },
            {
                path: 'showAdminReport',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },

                    {
                        path: ':year/:month/:parishCode',
                        element: <ShowAdminReport />
                    }
                ]
            },
            {
                path: 'newAdminSourceDoc',
                element: <NewAdminSourceDoc />
            },
            {
                path: 'sourceDoc',
                element: <SourceDoc />
            },
            {
                path: 'showDoc',
                element: <ShowDoc />
            },
            {
                path: 'viewAdminSourceDoc',
                element: <AdminSourceDocs />
            },
            {
                path: 'showAdminSourceDoc',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },

                    {
                        path: ':year/:month/:parishCode',
                        element: <ShowAdminSourceDoc />
                    }
                ]
            },
            {
                path: 'newVirtualReport',
                element: <NewVirtualReport />
            },
            {
                path: 'viewVirtualReport',
                element: <VirtualReports />
            },
            {
                path: 'showVirtualReport',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },

                    {
                        path: ':year/:month/:parishCode',
                        element: <ShowVirtualReport />
                    }
                ]
            },
            {
                path: 'newHgsReport',
                element: <NewHgsReport />
            },
            {
                path: 'viewHgsReport',
                element: <HgsReports />
            },
            {
                path: 'showHgsReport',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },

                    {
                        path: ':year/:month/:parishCode',
                        element: <ShowHgsReport />
                    }
                ]
            },
            {
                path: 'viewLetsGoFishingReport',
                element: <LetsGoFishingReports />
            },
            {
                path: 'csrCategories',
                element: <CsrCategories />
            },
            {
                path: 'subCsrCategories',
                element: <SubCsrCategories />
            },
            {
                path: 'newCsrReport',
                element: <NewCsrReport />
            },
            {
                path: 'viewCsrReport',
                element: <CsrReports />
            },
            {
                path: 'socialCsrReport',
                element: <SocialCsrReport />
            },
            {
                path: 'reportManager',
                element: <ReportManager />
            },
            {
                path: 'progressReport',
                element: <ProgressReport />
            },
            {
                path: 'trialBalance',
                element: <TrialBalance />
            },
            {
                path: 'event',
                element: <Event />
            },
            {
                path: 'annualPerformance',
                // element: <AnnualPerformance />
                children: [
                    {
                        path: ':id',
                        element: <AnnualPerformance />
                    },

                    {
                        path: '/',
                        element: <AnnualPerformance />
                    }
                ]
            },

            {
                path: 'avgAttendance',
                element: <AvgAttendance />
            },
            {
                path: 'vision2032',
                element: <Vision2032 />
            },
            {
                path: 'vision2032Report',
                element: <Vision2032Report />
            },
            {
                path: 'nationalPerformance',
                element: <NationalPerformance />
            },

            {
                path: 'avgService',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="list" replace />
                    },
                    {
                        path: 'list',
                        element: <AvgService />
                    },
                    {
                        path: 'view',
                        children: [
                            {
                                path: '/',
                                element: <Navigate to="1" replace />
                            },

                            {
                                path: ':parishId',
                                element: <ViewAvgService />
                            }
                        ]
                    },
                    {
                        path: 'province',
                        children: [
                            {
                                path: '/',
                                element: <Navigate to="1" replace />
                            },

                            {
                                path: ':parishId',
                                element: <ViewProvService />
                            }
                        ]
                    },
                    {
                        path: 'zone',
                        children: [
                            {
                                path: '/',
                                element: <Navigate to="1" replace />
                            },

                            {
                                path: ':parishId',
                                element: <ViewZoneService />
                            }
                        ]
                    },
                    {
                        path: 'area',
                        children: [
                            {
                                path: '/',
                                element: <Navigate to="1" replace />
                            },

                            {
                                path: ':parishId',
                                element: <ViewAreaService />
                            }
                        ]
                    },
                    {
                        path: 'parish',
                        children: [
                            {
                                path: '/',
                                element: <Navigate to="1" replace />
                            },

                            {
                                path: ':parishId',
                                element: <ViewParishService />
                            }
                        ]
                    }
                ]
            },
            {
                path: 'tiers',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="list" replace />
                    },
                    {
                        path: 'list',
                        element: <Vision2032Tiers />
                    },
                    {
                        path: 'add',
                        element: <RegionToTiers />
                    },
                    {
                        path: 'benchmark',
                        element: <SetBenchmarks />
                    },
                    {
                        path: 'view',
                        element: <ViewBenchmarks />
                    }
                ]
            },

            {
                path: 'performanceGoals',
                element: <PerformanceGoals />
            },
            {
                path: 'converts',
                element: <Converts />
            },
            {
                path: 'houseFellowshipAttd',
                element: <HouseFellowshipAttd />
            },
            {
                path: 'mrrFirstTimers',
                element: <MrrFirstTimers />
            },
            {
                path: 'mrrConverts',
                element: <MrrConverts />
            },
            {
                path: 'csrCompliance',
                element: <CsrCompliance />
            },
            {
                path: 'csrBeneficiaries',
                element: <CsrBeneficiaries />
            },

            {
                path: 'churchPlanting',
                element: <ChurchPlanting />
            },
            {
                path: 'reportStatus',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: 'list',
                        element: <ReportStatus />
                    },
                    {
                        path: 'view',
                        children: [
                            {
                                path: ':parishId',
                                element: <ViewReportStatus />
                            }
                        ]
                    },
                    {
                        path: 'status',
                        children: [
                            {
                                path: ':parishId',
                                element: <AdminReportStatus />
                            }
                        ]
                    },
                    {
                        path: ':parishId',
                        element: <ViewReportStatus />
                    }
                ]
            },
            {
                path: 'churchProperty',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="list" replace />
                    },
                    {
                        path: 'list',
                        element: <ChurchProperty />
                    },
                    {
                        path: 'view',
                        children: [
                            {
                                path: '/',
                                element: <Navigate to="1" replace />
                            },

                            {
                                path: ':parishId',
                                element: <ViewChurchProperty />
                            }
                        ]
                    }
                ]
            },
            {
                path: 'statusPage',
                element: <StatusPage />
            },
            {
                path: 'complaincePage',
                element: <ComplaincePage />
            },
            {
                path: 'remittancePage',
                element: <RemittancePage />
            },
            {
                path: 'sourceUpload',
                element: <SourceUpload />
            },
            // {
            //     path: 'loginChart',
            //     element: <LoginChart />
            // },
            {
                path: 'parishPlanted',
                element: <ParishPlanted />
            },
            {
                path: 'churchAnalysis',
                element: <ChurchAnalysis />
            },
            {
                path: 'areaRemittance',
                element: <AreaRemittance />
            },
            {
                path: 'productCopy',
                element: <ProductCopy />
            },
            {
                path: 'invoiceCopy',
                element: <InvoiceCopy />
            },
            {
                path: 'mailbox',
                element: <MailboxStatus />
            },
            {
                path: 'hgsReport',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="list" replace />
                    },
                    {
                        path: 'list',
                        element: <HgsReports />
                    },
                    {
                        path: 'province',
                        children: [
                            {
                                path: '/',
                                element: <Navigate to="1" replace />
                            },

                            {
                                path: ':parishId',
                                element: <ViewHgsZoneReport />
                            }
                        ]
                    },
                    {
                        path: 'zone',
                        children: [
                            {
                                path: '/',
                                element: <Navigate to="1" replace />
                            },

                            {
                                path: ':parishId',
                                element: <ViewHgsReport />
                            }
                        ]
                    },
                    {
                        path: 'area',
                        children: [
                            {
                                path: '/',
                                element: <Navigate to="1" replace />
                            },

                            {
                                path: ':parishId',
                                element: <ViewHgsAreaReport />
                            }
                        ]
                    },
                    {
                        path: 'parish',
                        children: [
                            {
                                path: '/',
                                element: <Navigate to="1" replace />
                            },

                            {
                                path: ':parishId',
                                element: <ViewHgsParishReport />
                            }
                        ]
                    }
                ]
            },

            // {
            //     path: `inbox`,
            //     element: <Single />
            // },
            // {
            //     path: 'mail',
            //     element: <MailboxFlow />
            // },
            {
                path: 'viewVision2032Report',
                element: <Vision2032Report />
            },

            {
                path: 'showCsrReport',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },

                    {
                        path: ':year/:month/:parishCode',
                        element: <ShowCsrReport />
                    }
                ]
            },

            {
                path: 'emailbox',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="inbox" replace />
                    },

                    {
                        path: 'tag/:labelTag',
                        element: <Emailbox />
                    },
                    {
                        path: 'tag/:labelTag/:mailboxCategory',
                        element: <Emailbox />
                    },
                    {
                        path: ':categoryTag',
                        element: <Emailbox />
                    },
                    {
                        path: ':categoryTag/:mailboxCategory',
                        element: <Emailbox />
                    }
                ]
            },
            {
                path: 'products',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="list" replace />
                    },
                    {
                        path: 'list',
                        element: <ShowAdminReport />
                    },
                    {
                        path: 'create',
                        element: <NewAdminReport />
                    },
                    {
                        path: 'single',
                        children: [
                            {
                                path: '/',
                                element: <Navigate to="1" replace />
                            },
                            {
                                path: ':reportId',
                                element: <ViewAdminReport />
                            }
                        ]
                    }
                ]
            },
            {
                path: 'messaging',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="inbox" replace />
                    },

                    {
                        path: 'tag/:labelTag',
                        element: <Messaging />
                    },
                    {
                        path: 'tag/:labelTag/:mailboxCategory',
                        element: <Messaging />
                    },
                    {
                        path: ':categoryTag',
                        element: <Messaging />
                    },
                    {
                        path: ':categoryTag/:mailboxCategory',
                        element: <Messaging />
                    }
                ]
            }
        ]
    },

    {
        path: 'app-icons',
        element: <NewAppIcon />
    }
];

export default adminDemos;
