import { Suspense, lazy } from 'react';
// import { Navigate } from 'react-router-dom';
// import useAuth from 'src/hooks/useAuth';

import SuspenseLoader from 'src/components/SuspenseLoader';
// import { user } from 'src/utils/parishes';

const Loader = (Component) => (props) =>
    (
        <Suspense fallback={<SuspenseLoader />}>
            <Component {...props} />
        </Suspense>
    );

const IconMenu = Loader(
    lazy(() => {
        return import('src/content/common/dashboards/IconMenu');
    })
);

const DirectoryMenu = Loader(
    lazy(() => {
        return import(
            'src/content/common/dashboards/MainMenu/SubMenu/Directory'
        );
    })
);
const BirthdayMenu = Loader(
    lazy(() => {
        return import(
            'src/content/common/dashboards/MainMenu/SubMenu/Birthday'
        );
    })
);
const CSRMenu = Loader(
    lazy(() => {
        return import('src/content/common/dashboards/MainMenu/SubMenu/CSR');
    })
);

const AccountMenu = Loader(
    lazy(() => {
        return import(
            'src/content/common/dashboards/MainMenu/SubMenu/AccountManager'
        );
    })
);

const UserMenu = Loader(
    lazy(() => {
        return import('src/content/common/dashboards/MainMenu/SubMenu/User');
    })
);
const ChurchMenu = Loader(
    lazy(() => {
        return import(
            'src/content/common/dashboards/MainMenu/SubMenu/ChurchManager'
        );
    })
);

const PastorMenu = Loader(
    lazy(() => {
        return import(
            'src/content/common/dashboards/MainMenu/SubMenu/PastorManager'
        );
    })
);

const DebtMenu = Loader(
    lazy(() => {
        return import('src/content/common/dashboards/MainMenu/SubMenu/DebtLog');
    })
);

const IncomeMenu = Loader(
    lazy(() => {
        return import('src/content/common/dashboards/MainMenu/SubMenu/Income');
    })
);

const MessagesMenu = Loader(
    lazy(() => {
        return import('src/content/common/messaging/Mailbox');
    })
);
const PerformanceAdminMenu = Loader(
    lazy(() => {
        return import(
            'src/content/common/dashboards/MainMenu/SubMenu/PerformanceAdmin'
        );
    })
);
const PerformanceFinanceMenu = Loader(
    lazy(() => {
        return import(
            'src/content/common/dashboards/MainMenu/SubMenu/PerformanceFinance'
        );
    })
);

const MissionMenu = Loader(
    lazy(() => {
        return import('src/content/common/dashboards/MainMenu/SubMenu/Mission');
    })
);
const ComplianceMenu = Loader(
    lazy(() => {
        return import(
            'src/content/common/dashboards/MainMenu/SubMenu/Compliance'
        );
    })
);
const PaymentMenu = Loader(
    lazy(() => {
        return import(
            'src/content/common/dashboards/MainMenu/SubMenu/PaymentManager'
        );
    })
);

const ApprovalMenu = Loader(
    lazy(() => {
        return import(
            'src/content/common/dashboards/MainMenu/SubMenu/Approval'
        );
    })
);

const MonthMenu = Loader(
    lazy(() => {
        return import(
            'src/content/common/dashboards/MainMenu/SubMenu/MonthlyRemittance'
        );
    })
);

const RemMenu = Loader(
    lazy(() => {
        return import(
            'src/content/common/dashboards/MainMenu/SubMenu/RemittanceSettings'
        );
    })
);
const RPMSMenu = Loader(
    lazy(() => {
        return import('src/content/common/dashboards/MainMenu/SubMenu/RPMS');
    })
);

const IconMenuMember = Loader(
    lazy(() => import('src/content/common/dashboards/IconMenuMember'))
);

const dashboardsRoutes = [
    {
        path: '/',
        // element: <Navigate to="iconMenu" replace />
        element: <IconMenu />
    },
    {
        path: 'iconMenu',
        element: <IconMenu />
    },
    {
        path: 'directory',
        element: <DirectoryMenu />
    },
    {
        path: 'birthday',
        element: <BirthdayMenu />
    },
    {
        path: 'csr',
        element: <CSRMenu />
    },
    {
        path: 'account',
        element: <AccountMenu />
    },
    {
        path: 'user',
        element: <UserMenu />
    },
    {
        path: 'church-manager',
        element: <ChurchMenu />
    },
    {
        path: 'pastor-manager',
        element: <PastorMenu />
    },
    {
        path: 'debt-log',
        element: <DebtMenu />
    },
    {
        path: 'income-report',
        element: <IncomeMenu />
    },
    {
        path: 'messages',
        element: <MessagesMenu />
    },
    {
        path: 'performance-admin',
        element: <PerformanceAdminMenu />
    },
    {
        path: 'performance-finance',
        element: <PerformanceFinanceMenu />
    },
    {
        path: 'mission-report',
        element: <MissionMenu />
    },
    {
        path: 'compliance',
        element: <ComplianceMenu />
    },
    {
        path: 'payment-manager',
        element: <PaymentMenu />
    },
    {
        path: 'approvals',
        element: <ApprovalMenu />
    },
    {
        path: 'remittance',
        element: <MonthMenu />
    },
    {
        path: 'settings',
        element: <RemMenu />
    },
    {
        path: 'rpms',
        element: <RPMSMenu />
    },

    {
        path: 'iconMenuMember',
        element: <IconMenuMember />
    }
];

export default dashboardsRoutes;
