import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
    (
        <Suspense fallback={<SuspenseLoader />}>
            <Component {...props} />
        </Suspense>
    );

// User Accounts
const Users = Loader(
    lazy(() => import('src/content/common/userAccounts/Users'))
);
const SingleUser = Loader(
    lazy(() => import('src/content/common/userAccounts/Users/single'))
);

const userRoutes = [
    {
        path: '/',
        element: <Navigate to="users" replace />
    },
    {
        path: 'users',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <Users />
            },
            {
                path: 'view',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':userId',
                        element: <SingleUser />
                    }
                ]
            }
        ]
    }
];

export default userRoutes;
