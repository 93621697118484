import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
    (
        <Suspense fallback={<SuspenseLoader />}>
            <Component {...props} />
        </Suspense>
    );

// Admin Progress Reports
const AppVersion = Loader(
    lazy(() => import('src/content/common/utils/AppVersion'))
);

// Admin Progress Reports
const AdminReports = Loader(
    lazy(() => import('src/content/common/admin/AdminReport'))
);

const ParishAdminReports = Loader(
    lazy(() => import('src/content/common/admin/AdminReport/indexParish'))
);

const AreaAdminReports = Loader(
    lazy(() => import('src/content/common/admin/AdminReport/indexArea'))
);

const ZoneAdminReports = Loader(
    lazy(() => import('src/content/common/admin/AdminReport/indexZone'))
);

const ProvinceAdminReports = Loader(
    lazy(() => import('src/content/common/admin/AdminReport/indexProvince'))
);

const RegionAdminReports = Loader(
    lazy(() => import('src/content/common/admin/AdminReport/indexRegion'))
);

const SubContinentAdminReports = Loader(
    lazy(() => import('src/content/common/admin/AdminReport/indexSubContinent'))
);

const ContinentAdminReports = Loader(
    lazy(() => import('src/content/common/admin/AdminReport/indexContinent'))
);

const NewAdminReport = Loader(
    lazy(() => import('src/content/common/admin/AdminReport/create'))
);
const ViewAdminReport = Loader(
    lazy(() => import('src/content/common/admin/AdminReport/view'))
);
const ShowAdminReport = Loader(
    lazy(() => import('src/content/common/admin/AdminReport/view'))
);

const ViewAdminReportDetails = Loader(
    lazy(() =>
        import(
            'src/content/common/admin/AdminReport/viewReportDetails/viewReportDetailsByMonth'
        )
    )
);

// Admin Reports Source Documents
const AdminSourceDocs = Loader(
    lazy(() => import('src/content/common/admin/AdminSourceDocs'))
);
const NewAdminSourceDoc = Loader(
    lazy(() => import('src/content/common/admin/AdminSourceDocs/create'))
);
const UploadAdminSourceDoc = Loader(
    lazy(() => import('src/content/common/admin/AdminSourceDocs/upload'))
);
const ShowAdminSourceDoc = Loader(
    lazy(() => import('src/content/common/admin/AdminSourceDocs/show'))
);

// Performance Reports
const ParishPerformanceReportMain = Loader(
    lazy(() =>
        import('src/content/common/admin/AdminReport/performanceReports/main')
    )
);

// Virtual Reports
const VirtualReports = Loader(
    lazy(() => import('src/content/common/admin/VirtualReport'))
);
const NewVirtualReport = Loader(
    lazy(() => import('src/content/common/admin/VirtualReport/create'))
);
const ShowVirtualReport = Loader(
    lazy(() => import('src/content/common/admin/VirtualReport/show'))
);

// Hgs Reports
const HgsReports = Loader(
    lazy(() => import('src/content/common/admin/HgsReport'))
);
const NewHgsReport = Loader(
    lazy(() => import('src/content/common/admin/HgsReport/create'))
);
const ShowHgsReport = Loader(
    lazy(() => import('src/content/common/admin/HgsReport/show'))
);

// LetsGoFishing Reports
const LetsGoFishingReports = Loader(
    lazy(() => import('src/content/common/admin/LetsGoFishingReport'))
);

// Csr Reports
const CsrReports = Loader(
    lazy(() => import('src/content/common/admin/CsrReport'))
);
const NewCsrReport = Loader(
    lazy(() => import('src/content/common/admin/CsrReport/create'))
);
const ShowCsrReport = Loader(
    lazy(() => import('src/content/common/admin/CsrReport/view'))
);

const CsrImages = Loader(
    lazy(() => import('src/content/common/admin/CsrImageUpload'))
);
const NewCsrImage = Loader(
    lazy(() => import('src/content/common/admin/CsrImageUpload/create'))
);

const UploadCsrImage = Loader(
    lazy(() => import('src/content/common/admin/CsrImageUpload/create'))
);
const ShowCsrImage = Loader(
    lazy(() => import('src/content/common/admin/CsrImageUpload/show'))
);

const ComplianceReport = Loader(
    lazy(() => import('src/content/common/admin/ComplianceReport'))
);

// Admin Settings
const AdminReportSettings = Loader(
    lazy(() => import('src/content/common/admin/AdminReportSettings'))
);
const UnlockProvince = Loader(
    lazy(() => import('src/content/common/admin/UnlockProvince'))
);
const UnlockNewProvince = Loader(
    lazy(() => import('src/content/common/admin/UnlockProvince/create'))
);
const ServiceDays = Loader(
    lazy(() => import('src/content/common/admin/ServiceDays'))
);
const ReasonsForNonRemittance = Loader(
    lazy(() => import('src/content/common/admin/ReasonsForNonRemittance'))
);
const CsrCategories = Loader(
    lazy(() => import('src/content/common/admin/CsrCategories'))
);
const SubCsrCategories = Loader(
    lazy(() => import('src/content/common/admin/SubCsrCategories'))
);

// App Icons
const NewAppIcon = Loader(
    lazy(() => import('src/content/common/admin/AppIcon/create'))
);

// Demo
// const ViewAdminReportTableDemo = Loader(
//     lazy(() => import('src/content/common/admin/AdminReportMuiTableDemo'))
// );

const adminReports = [
    {
        path: '/',
        element: <Navigate to="adminReports" replace />
    },
    {
        path: 'adminReports',
        children: [
            {
                path: '/',
                element: <Navigate to="newAdminReport" replace />
            },
            {
                path: 'adminReportSettings',
                element: <AdminReportSettings />
            },
            {
                path: 'unlockNewProvince',
                element: <UnlockNewProvince />
            },
            {
                path: 'unlockProvince',
                element: <UnlockProvince />
            },
            {
                path: 'serviceDays',
                element: <ServiceDays />
            },
            {
                path: 'complianceReport',
                element: <ComplianceReport />
            },
            {
                path: 'reasonsForNonRemittance',
                element: <ReasonsForNonRemittance />
            },
            {
                path: 'newAdminReport',
                element: <NewAdminReport />
            },
            {
                path: 'viewAdminReport',
                element: <AdminReports />
            },
            {
                path: 'parishAdminReport',
                element: <ParishAdminReports />
            },
            {
                path: 'areaAdminReport',
                element: <AreaAdminReports />
            },
            {
                path: 'zoneAdminReport',
                element: <ZoneAdminReports />
            },
            {
                path: 'provinceAdminReport',
                element: <ProvinceAdminReports />
            },
            {
                path: 'regionAdminReport',
                element: <RegionAdminReports />
            },
            {
                path: 'subContinentAdminReport',
                element: <SubContinentAdminReports />
            },
            {
                path: 'continentAdminReport',
                element: <ContinentAdminReports />
            },
            {
                path: 'showAdminReport',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    // {
                    //     path: 'test',
                    //     element: <AdminReports />
                    // }
                    {
                        path: ':year/:month/:parishCode',
                        element: <ShowAdminReport />
                    }
                ]
            },
            {
                path: 'progressReport',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    // {
                    //     path: 'test',
                    //     element: <AdminReports />
                    // }

                    {
                        path: ':type/:typeCode/:month/:year/view',
                        element: <ShowAdminReport />
                    }
                ]
            },
            {
                path: 'performanceReport',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    // {
                    //     path: 'test',
                    //     element: <AdminReports />
                    // }
                    {
                        path: '/main/:type/:typeCode/:month/:year/view',
                        element: <ParishPerformanceReportMain />
                    }
                ]
            },
            {
                path: 'reportDetails',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    // {
                    //     path: 'test',
                    //     element: <AdminReports />
                    // }
                    {
                        path: ':year/:month/:parishCode',
                        element: <ViewAdminReportDetails />
                    }
                ]
            },
            {
                path: 'newAdminSourceDoc',
                element: <NewAdminSourceDoc />
            },
            {
                path: 'uploadAdminSourceDoc',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':year/:month/:parishCode/:reportId',
                        element: <UploadAdminSourceDoc />
                    }
                ]
            },
            {
                path: 'viewAdminSourceDoc',
                element: <AdminSourceDocs />
            },
            {
                path: 'showAdminSourceDoc',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    // {
                    //     path: 'test',
                    //     element: <AdminSourceDocs />
                    // }
                    {
                        path: ':year/:month/:parishCode',
                        element: <ShowAdminSourceDoc />
                    }
                ]
            },
            {
                path: 'newVirtualReport',
                element: <NewVirtualReport />
            },
            {
                path: 'viewVirtualReport',
                element: <VirtualReports />
            },
            {
                path: 'showVirtualReport',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    // {
                    //     path: 'test',
                    //     element: <VirtualReports />
                    // }
                    {
                        path: ':year/:month/:parishCode',
                        element: <ShowVirtualReport />
                    }
                ]
            },
            {
                path: 'newHgsReport',
                element: <NewHgsReport />
            },
            {
                path: 'viewHgsReport',
                element: <HgsReports />
            },
            {
                path: 'showHgsReport',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    // {
                    //     path: 'test',
                    //     element: <HgsReports />
                    // }
                    {
                        path: ':year/:month/:parishCode',
                        element: <ShowHgsReport />
                    }
                ]
            },
            {
                path: 'viewLetsGoFishingReport',
                element: <LetsGoFishingReports />
            },
            {
                path: 'csrCategories',
                element: <CsrCategories />
            },
            {
                path: 'subCsrCategories',
                element: <SubCsrCategories />
            },
            {
                path: 'newCsrReport',
                element: <NewCsrReport />
            },
            {
                path: 'viewCsrReport',
                element: <CsrReports />
            },
            {
                path: 'showCsrReport',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    // {
                    //     path: 'test',
                    //     element: <CsrReports />
                    // }
                    {
                        path: 'parish/:year/:month/:parishCode/:reportId/view',
                        element: <ShowCsrReport />
                    }
                ]
            },
            {
                path: 'newCsrImage',
                element: <NewCsrImage />
            },
            {
                path: 'uploadCsrImage',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: 'parish/:year/:month/:parishCode/:reportId/upload',
                        element: <UploadCsrImage />
                    }
                ]
            },
            {
                path: 'viewCsrImage',
                element: <CsrImages />
            },
            {
                path: 'showCsrImage',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    // {
                    //     path: 'test',
                    //     element: <CsrImages />
                    // }
                    {
                        path: 'parish/:year/:month/:parishCode',
                        element: <ShowCsrImage />
                    }
                ]
            },
            {
                path: 'products',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="list" replace />
                    },
                    {
                        path: 'list',
                        element: <ShowAdminReport />
                    },
                    {
                        path: 'create',
                        element: <NewAdminReport />
                    },
                    {
                        path: 'single',
                        children: [
                            {
                                path: '/',
                                element: <Navigate to="1" replace />
                            },
                            {
                                path: ':reportId',
                                element: <ViewAdminReport />
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        path: 'app-icons',
        element: <NewAppIcon />
    },
    {
        path: 'version',
        element: <AppVersion />
    }
];

export default adminReports;
