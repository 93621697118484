import { getAreaRoles, getContinentRoles, getProvinceRoles, getRegionRoles, getSubContinentRoles, getZoneRoles } from "./Helper";

export const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];

export const years = ["2000", "2001", "2002", "2003", "2004", "2005", "2006", "2007", "2008", "2009", "2010", "2011", "2012", "2013", "2014", "2015", "2016", "2017", "2018", "2019", "2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032", "2033", "2034", "2035", "2036", "2037", "2038", "2039", "2040", "2041", "2042", "2043", "2044", "2045", "2046", "2047", "2048", "2049", "2050" ];

export  const exemptionTypes = [
    {alias: 'amount-less-than', selectedId: 1, text: 'Exempt if AMOUNT is less than X'},
    {alias: 'exempt-parishes-in-a-church-type-if-amount-less-than', selectedId: 2, text: 'Exempt parishes in a particular church if AMOUNT is less than X'},
    {alias: 'exempt-church-type', selectedId: 3, text: 'Exempt a Province'},
    {alias: 'exempt-selected-church-types', selectedId: 4, text: 'Exempt selected parishes'},
]

export const bankAccountTypes = [{name: 'savings', value: 'Savings'}, {name: 'current', value: 'Current'}]

export const booleanValues = [{name: 'True', value: 'true'}, {name: 'False', value: 'false'}];

export const churchTypes = [{name: 'continent', value: 'Continent'},{name: 'subContinent', value: 'SubContinent'},{name: 'region', value: 'Region'},{name: 'province', value: 'Province'},{name: 'zone', value: 'Zone'},{name: 'area', value: 'Area'},{name: 'parish', value: 'Parish'}];

export const churchTypesWithDept = [{name: 'continent', value: 'Continent'},{name: 'subContinent', value: 'SubContinent'},{name: 'region', value: 'Region'},{name: 'province', value: 'Province'},{name: 'zone', value: 'Zone'},{name: 'area', value: 'Area'},{name: 'parish', value: 'Parish'}, {name: 'department', value:'Department'}];

export const getChurchTypes = (userRole = null, canIncludeDepts = false) => {
    const deptType = {name: 'department', value:'Department'};
    let types = [];

    if(userRole !== null){
        if(getContinentRoles().includes(userRole)){
            types = churchTypes.slice(0);
        }
    
        else if(getSubContinentRoles().includes(userRole)){
            types = churchTypes.slice(1);
        }
    
        else if(getRegionRoles().includes(userRole)){
            types = churchTypes.slice(2);
        }
    
        else if(getProvinceRoles().includes(userRole)){
            types = churchTypes.slice(3);
        }
    
        else if(getZoneRoles().includes(userRole)){
            types = churchTypes.slice(4);
        }
    
        else if(getAreaRoles().includes(userRole)){
            // console.log('area role', churchTypes, churchTypes.slice(5))
            types = churchTypes.slice(5);
        }
    }else {
        types = churchTypes;
    }

    if(canIncludeDepts){
        types = types.concat([deptType]);
    }

    return types;

}

export const getChurchTypesWithRoles = (userRoles = null, canIncludeDepts = false, withHighestRole = true) => {
    userRoles = userRoles && !Array.isArray(userRoles) ? JSON.parse(userRoles) : userRoles;
        
    const deptType = {name: 'department', value:'Department'};
    let types = [];
    let sliceNumber = withHighestRole ? 0 : 1;

    if(Array.isArray(userRoles) && userRoles.length > 0){
        if(getContinentRoles().some(r=> userRoles.includes(r))){
            types = churchTypes.slice(sliceNumber + 0);
        }
    
        else if(getSubContinentRoles().some(r=> userRoles.includes(r))){
            // // console.log('found sub', sliceNumber);
            types = churchTypes.slice(sliceNumber + 1);
        }
    
        else if(getRegionRoles().some(r=> userRoles.includes(r))){
            types = churchTypes.slice(sliceNumber + 2);
        }
    
        else if(getProvinceRoles().some(r=> userRoles.includes(r))){
            types = churchTypes.slice(sliceNumber + 3);
        }
    
        else if(getZoneRoles().some(r=> userRoles.includes(r))){
            types = churchTypes.slice(sliceNumber + 4);
        }
    
        else if(getAreaRoles().some(r=> userRoles.includes(r))){
            // console.log('area role found', churchTypes, churchTypes.slice(5))
            types = churchTypes.slice(sliceNumber + 6);
        }
    }else {
        types = churchTypes;
    }

    if(canIncludeDepts){
        types = types.concat([deptType]);
    }

    return types;
}

export const USER_LEVEL = {
    PARISH: 1,
    AREA: 2,
    ZONE: 3,
    PROVINCE: 4,
    REGION: 5,
    SUB_CONTINENT: 6,
    CONTINENT: 7
}

export const CHURCH_HIERARCHIES = [
    {
        "id": 7,
        "name": "Continent",
        "code": "1000",
        "description": "Continent"
    },
    {
        "id": 6,
        "name": "SubContinent",
        "code": "1001",
        "description": "SubContinent"
    },
    {
        "id": 5,
        "name": "Region",
        "code": "1003",
        "description": "Region"
    },
    {
        "id": 4,
        "name": "Province",
        "code": "1004",
        "description": "Province"
    },
    {
        "id": 3,
        "name": "Zone",
        "code": "1005",
        "description": "Zone"
    },
    {
        "id": 2,
        "name": "Area",
        "code": "1006",
        "description": "Area"
    },
    {
        "id": 1,
        "name": "Parish",
        "code": "1007",
        "description": "Parish"
    }
]