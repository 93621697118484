import { createSlice } from '@reduxjs/toolkit';
import { parseISO } from 'date-fns';
import ax from 'src/utils/axios';
import axios from 'axios';
import objectArray from 'src/utils/objectArray';

const initialState = {
    mailbox: {
        byId: {},
        allIds: []
    },
    announcementsComment: {
        byId: {},
        allIds: []
    },

    mails: {
        byId: {},
        allIds: []
    },
    tags: [],
    sidebarOpen: false
};

const slice = createSlice({
    name: 'mail',
    initialState,
    reducers: {
        getTags(state, action) {
            const { tags } = action.payload;

            state.tags = tags;
        },

        getMailbox(state, action) {
            const { mailbox } = action.payload;
            state.mailbox.byId = objectArray(mailbox);
            state.mailbox.allIds = Object.keys(state.mailbox.byId);
        },
        getAnnouncementsComment(state, action) {
            const { announcementsComment } = action.payload;

            state.announcementsComment.byId = objectArray(announcementsComment);
            state.announcementsComment.allIds = Object.keys(
                state.announcementsComment.byId
            );
        },
        getMail(state, action) {
            let mailbox = action.payload;

            state.mails.byId[mailbox.id] = mailbox;

            if (!state.mails.allIds.includes(mailbox.id)) {
                state.mails.allIds.push(mailbox.id);
            }
        },
        deleteMail(state, action) {
            let mailbox = action.payload;

            delete state.byId[mailbox.id];
            state.allIds = state.allIds.filter((id) => id !== mailbox.id);
        },
        openSidebar(state) {
            state.sidebarOpen = true;
        },
        closeSidebar(state) {
            state.sidebarOpen = false;
        }
    }
});

export const reducer = slice.reducer;

export const getTags = () => async (dispatch) => {
    const response = await ax.get('/api/mailbox/tags');
    dispatch(slice.actions.getTags(response.data));
};

export const getMails = (params) => async (dispatch) => {
    const response = await ax.get('/api/mailbox/mails', {
        params
    });

    dispatch(slice.actions.getMails(response.data));
};

export const getMailbox = () => async (dispatch) => {
    const BASEURL = process.env.REACT_APP_MESSAGING_ENDPOINT_BASE_URL;
    try {
        const { data } = await axios.get(
            `${BASEURL}/crud/portal_announcements/`
        );

        const tableDataRows = data?.map((element) => ({
            id: `${element.id}`,
            category: element.category,
            opened: false,
            tagIds: ['1'],
            subject: element.subject,
            summary: element.message,
            content: element.message,
            attachments: element.attachments,
            sender: element.sender,
            recipients: element.recipients,
            recipientsRoles: element.recipientsRoles,
            mustRead: element.mustRead,
            priority: element.priority,
            allowComment: element.allowComment,
            extraNote: element.extraNote,
            hierachy: element.hierachy,
            emailNotification: element.emailNotification,
            smsNotification: element.smsNotification,
            flag: element.flag,
            archived: element.archived,
            unread: element.unread,
            viewsCount: element.viewsCount,
            createdBy: element.createdBy,
            type: element.type,
            typeId: element.typeId,
            platform: element.platform,

            status: element.status,
            trash: element.trash,
            timeSent: element.timeSent,
            timeRead: element.timeRead,
            createdAt: element.createdAt,
            updatedAt: element.updatedAt,
            date: parseISO(element.createdAt)
        }));
        console.log('table rows', tableDataRows);

        dispatch(slice.actions.getMailbox({ mailbox: tableDataRows }));
    } catch (err) {
        console.log('Something went wrong here ', err);
    }
    // const response = await axios.get('/api/mailbox/mails', {
    //   params
    // });
};
export const getAnnouncementsComment = () => async (dispatch) => {
    const BASEURL = process.env.REACT_APP_MESSAGING_ENDPOINT_BASE_URL;
    try {
        const { data } = await axios.get(
            `${BASEURL}/crud/portal_announcements_comments/`
        );

        const tableDataRows = data?.map((element) => ({
            announcementId: `${element.announcementId}`,
            comment: `${element.comment}`,
            commenterName: `${element.commenterName}`,
            commenterId: `${element.commenterId}`,
            timePosted: element.timePosted,
            hide: element.hide,
            private: element.private,
            allowReply: element.allowReply,
            flag: element.flag,
            archived: element.archived,
            status: element.status,
            portal_announcements: element.portal_announcement,
            createdAt: element.createdAt,
            updatedAt: element.updatedAt
        }));

        dispatch(
            slice.actions.getAnnouncementsComment({
                announcementsComment: tableDataRows
            })
        );
    } catch (err) {
        console.log('Something went wrong here ', err);
    }
};

export const getMail = (mailboxCategory) => async (dispatch) => {
    try {
        const BASEURL = process.env.REACT_APP_MESSAGING_ENDPOINT_BASE_URL;

        const { data } = await axios.get(
            `${BASEURL}/crud/portal_announcements/${mailboxCategory}`
        );

        dispatch(slice.actions.getMail(data));
    } catch (error) {
        console.error('Error fetching mail:', error);
    }
};
export const deleteMail = (mailboxCategory) => async (dispatch) => {
    try {
        const BASEURL = process.env.REACT_APP_MESSAGING_ENDPOINT_BASE_URL;

        const { data } = await axios.delete(
            `${BASEURL}/crud/portal_announcements/${mailboxCategory}`
        );

        dispatch(slice.actions.deleteMail(data));
    } catch (error) {
        console.error('Error fetching mail:', error);
    }
};

export const openSidebar = () => async (dispatch) => {
    dispatch(slice.actions.openSidebar());
};

export const closeSidebar = () => async (dispatch) => {
    dispatch(slice.actions.closeSidebar());
};

export default slice;
