import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
    (
        <Suspense fallback={<SuspenseLoader />}>
            <Component {...props} />
        </Suspense>
    );

// Member Accounts
const Members = Loader(lazy(() => import('src/content/common/rpms/Members')));
const SingleMember = Loader(
    lazy(() => import('src/content/common/rpms/Members/single'))
    // lazy(() => import('src/content/common/rpms/Members/view'))
);
const Children = Loader(lazy(() => import('src/content/common/rpms/Children')));
const Departments = Loader(
    lazy(() => import('src/content/common/rpms/Departments'))
);
const Events = Loader(lazy(() => import('src/content/common/rpms/Events')));
const FollowUpTeam = Loader(
    lazy(() => import('src/content/common/rpms/FollowUp/Team'))
);
const FollowUpMembers = Loader(
    lazy(() => import('src/content/common/rpms/FollowUp/Members'))
);
const FollowUpRpmsMembers = Loader(
    lazy(() => import('src/content/common/rpms/FollowUp/RPMS-Members'))
);
const FirstTimers = Loader(
    lazy(() => import('src/content/common/rpms/FirstTimers/Members'))
);
const HouseFellowshipCentres = Loader(
    lazy(() => import('src/content/common/rpms/HouseFellowship/Centres'))
);
const HouseFellowshipCentresForMember = Loader(
    lazy(() => import('src/content/common/rpms/HouseFellowship/Centres-Member'))
);
const HouseFellowshipCentresMap = Loader(
    lazy(() => import('src/content/common/rpms/HouseFellowship/MapCentres'))
);
const HouseFellowshipCentreMembers = Loader(
    lazy(() => import('src/content/common/rpms/HouseFellowship/CentreMembers'))
);
const HouseFellowshipAttendance = Loader(
    lazy(() => import('src/content/common/rpms/HouseFellowship/Attendance'))
);
const HouseFellowshipAttendanceForMember = Loader(
    lazy(() =>
        import('src/content/common/rpms/HouseFellowship/Attendance-Members')
    )
);
const HouseFellowshipVisitations = Loader(
    lazy(() => import('src/content/common/rpms/HouseFellowship/Visitations'))
);
const Payments = Loader(lazy(() => import('src/content/common/rpms/Payments')));

const Sermons = Loader(lazy(() => import('src/content/common/rpms/Sermons')));
const ViewSermon = Loader(
    lazy(() => import('src/content/common/rpms/Sermons/view'))
);

const Testimonies = Loader(
    lazy(() => import('src/content/common/rpms/Testimonies'))
);

const ViewTestimony = Loader(
    lazy(() => import('src/content/common/rpms/Testimonies/view'))
);

const TitheBook = Loader(
    lazy(() => import('src/content/common/rpms/TitheBook'))
);
const Income = Loader(lazy(() => import('src/content/common/rpms/Income')));
const Expenditure = Loader(
    lazy(() => import('src/content/common/rpms/Expenditure'))
);
const Birthday = Loader(lazy(() => import('src/content/common/rpms/Birthday')));
const BirthdayPastors = Loader(
    lazy(() => import('src/content/common/rpms/BirthdayPastors'))
);
const Anniversary = Loader(
    lazy(() => import('src/content/common/rpms/Anniversary'))
);
const Workers = Loader(lazy(() => import('src/content/common/rpms/Workers')));
const Titles = Loader(lazy(() => import('src/content/common/rpms/Titles')));
const Designations = Loader(
    lazy(() => import('src/content/common/rpms/Designations'))
);
// SMS
const SMS = Loader(lazy(() => import('src/content/common/rpms/Messaging/SMS')));
const ViewSMS = Loader(
    lazy(() => import('src/content/common/rpms/Messaging/SMS/create'))
);
const SendSingleSMS = Loader(
    lazy(() => import('src/content/common/rpms/Messaging/SMS/create'))
);
const SendSMS = Loader(
    lazy(() => import('src/content/common/rpms/Messaging/SMS/create'))
);
const SendBulkSMS = Loader(
    lazy(() => import('src/content/common/rpms/Messaging/SMS/create'))
);
const SendAnniversarySMS = Loader(
    lazy(() => import('src/content/common/rpms/Messaging/SMS/create'))
);
const SendBirthdaySMS = Loader(
    lazy(() => import('src/content/common/rpms/Messaging/SMS/create'))
);
const SendEventSMS = Loader(
    lazy(() => import('src/content/common/rpms/Messaging/SMS/create'))
);

const EventsV2 = Loader(
    lazy(() => import('src/content/common/rpms/Events_v2'))
);

const TrialBalance = Loader(
    lazy(() => import('src/content/common/rpms/TrialBalance'))
);

const Approval = Loader(lazy(() => import('src/content/common/rpms/Approval')));

const MissionayAssistant = Loader(
    lazy(() => import('src/content/common/rpms/MissionayAssistant'))
);

const rpmsRoutes = [
    {
        path: '/',
        element: <Navigate to="members" replace />
    },
    {
        path: 'members',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <Members />
            },
            {
                path: 'viewMember',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':memberId',
                        element: <SingleMember />
                    }
                ]
            }
        ]
    },
    {
        path: 'workers',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <Workers />
            },
            {
                path: 'viewWorker',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':workerId',
                        element: <SingleMember />
                    }
                ]
            }
        ]
    },
    {
        path: 'children',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <Children />
            },
            {
                path: 'viewChild',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':childId',
                        element: <SingleMember />
                    }
                ]
            }
        ]
    },
    {
        path: 'houseFellowship',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'centres',
                element: <HouseFellowshipCentres />
            },
            {
                path: 'membersCentres',
                element: <HouseFellowshipCentresForMember />
            },
            {
                path: 'attendance',
                element: <HouseFellowshipAttendance />
            },
            {
                path: 'membersAttendance',
                element: <HouseFellowshipAttendanceForMember />
            },
            {
                path: 'visitations',
                element: <HouseFellowshipVisitations />
            },
            {
                path: 'centreMembers',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':centreCode',
                        element: <HouseFellowshipCentreMembers />
                    }
                ]
            },
            {
                path: 'centresMaps',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="view" replace />
                    },
                    {
                        path: ':parishCode',
                        element: <HouseFellowshipCentresMap />
                    }
                ]
            },
            {
                path: 'viewCentre',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':memberId',
                        element: <SingleMember />
                    }
                ]
            }
        ]
    },
    {
        path: 'followUp',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'listFollowUpTeams',
                element: <FollowUpTeam />
            },
            {
                path: 'listFollowUpMembers',
                element: <FollowUpMembers />
            },
            {
                path: 'listFollowUpRpmsMembers',
                element: <FollowUpRpmsMembers />
            },
            {
                path: 'viewMember',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':memberId',
                        element: <SingleMember />
                    }
                ]
            }
        ]
    },
    {
        path: 'firsttimers',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <FirstTimers />
            },
            {
                path: 'viewMember',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':memberId',
                        element: <SingleMember />
                    }
                ]
            }
        ]
    },
    {
        path: 'events',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <Events />
            },
            {
                path: 'viewEvent',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':memberId',
                        element: <SingleMember />
                    }
                ]
            }
        ]
    },
    {
        path: 'eventsV2',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <EventsV2 />
            },
            {
                path: 'viewEvent',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':memberId',
                        element: <SingleMember />
                    }
                ]
            }
        ]
    },
    {
        path: 'birthdays',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <Birthday />
            },
            {
                path: 'viewEvent',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':memberId',
                        element: <SingleMember />
                    }
                ]
            }
        ]
    },
    {
        path: 'pastorsBirthdays',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <BirthdayPastors />
            },
            {
                path: 'viewEvent',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':memberId',
                        element: <SingleMember />
                    }
                ]
            }
        ]
    },
    {
        path: 'anniversaries',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <Anniversary />
            },
            {
                path: 'viewEvent',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':memberId',
                        element: <SingleMember />
                    }
                ]
            }
        ]
    },
    {
        path: 'departments',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <Departments />
            },
            {
                path: 'approval',
                element: <Approval />
            },
            {
                path: 'viewMember',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':memberId',
                        element: <SingleMember />
                    }
                ]
            }
        ]
    },
    // {
    //     path: 'members',
    //     children: [
    //         {
    //             path: '/',
    //             element: <Navigate to="list" replace />
    //         },
    //         {
    //             path: 'list',
    //             element: <Members />
    //         },
    //         {
    //             path: 'viewMember',
    //             children: [
    //                 {
    //                     path: '/',
    //                     element: <Navigate to="1" replace />
    //                 },
    //                 {
    //                     path: ':memberId',
    //                     element: <SingleMember />
    //                 }
    //             ]
    //         }
    //     ]
    // },
    {
        path: 'payments',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <Payments />
            },
            {
                path: 'viewMember',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':memberId',
                        element: <SingleMember />
                    }
                ]
            }
        ]
    },
    {
        path: 'sermons',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <Sermons />
            },
            {
                path: 'viewSermon',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':year/:month/:parishCode/:id',
                        element: <ViewSermon />
                    }
                ]
            }
        ]
    },
    {
        path: 'testimonies',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <Testimonies />
            },
            {
                path: 'viewTestimony',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':parishCode/:id',
                        element: <ViewTestimony />
                    }
                ]
            }
        ]
    },
    {
        path: 'expenditure',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <Expenditure />
            },
            {
                path: 'viewMember',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':memberId',
                        element: <SingleMember />
                    }
                ]
            }
        ]
    },
    {
        path: 'income',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <Income />
            },
            {
                path: 'viewMember',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':memberId',
                        element: <SingleMember />
                    }
                ]
            }
        ]
    },
    {
        path: 'titheBook',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <TitheBook />
            },
            {
                path: 'viewMember',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':memberId',
                        element: <SingleMember />
                    }
                ]
            }
        ]
    },
    {
        path: 'titles',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <Titles />
            },
            {
                path: 'viewMember',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':memberId',
                        element: <SingleMember />
                    }
                ]
            }
        ]
    },
    {
        path: 'designations',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <Designations />
            },
            {
                path: 'viewMember',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':memberId',
                        element: <SingleMember />
                    }
                ]
            }
        ]
    },
    {
        path: 'trialBalance',
        element: <TrialBalance />
    },
    {
        path: 'approval',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <Approval />
            },
            {
                path: 'viewMember',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':memberId',
                        element: <SingleMember />
                    }
                ]
            }
        ]
    },
    {
        path: 'messaging',
        children: [
            {
                path: '/',
                element: <Navigate to="sendSMS" replace />
            },
            {
                path: 'list_disabled',
                element: <SMS />
            },
            {
                path: 'sendSMS',
                element: <SendSMS />
            },
            {
                path: 'sendBulkSMS',
                element: <SendBulkSMS />
            },
            {
                path: 'sendAnniversarySMS',
                element: <SendAnniversarySMS />
            },
            {
                path: 'sendBirthdaySMS',
                element: <SendBirthdaySMS />
            },
            {
                path: 'sendEventSMS',
                element: <SendEventSMS />
            },
            {
                path: 'viewSMS',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':smsId',
                        element: <ViewSMS />
                    }
                ]
            },
            {
                path: 'sendSingleSMS',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':userCode::parishCode/:rccgCode',
                        element: <SendSingleSMS />
                    }
                ]
            }
        ]
    },
    {
        path: 'missionayAssistant',
        children: [
            {
                path: '/',
                element: <Navigate to="chatbot" replace />
            },
            {
                path: 'chatbot',
                element: <MissionayAssistant />
            }
        ]
    }
];

export default rpmsRoutes;
