import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
    (
        <Suspense fallback={<SuspenseLoader />}>
            <Component {...props} />
        </Suspense>
    );

// Admin Progress Reports
const AdminReports = Loader(
    lazy(() => import('src/content/common/admin/AdminReport'))
);

const NewAdminReport = Loader(
    lazy(() => import('src/content/common/admin/AdminReport/create'))
);
const ViewAdminReport = Loader(
    lazy(() => import('src/content/common/admin/AdminReport/view'))
);
const ShowAdminReport = Loader(
    lazy(() => import('src/content/common/admin/AdminReport/view'))
);

// Admin Reports Source Documents
const AdminSourceDocs = Loader(
    lazy(() => import('src/content/common/admin/AdminSourceDocs'))
);
const NewAdminSourceDoc = Loader(
    lazy(() => import('src/content/common/admin/AdminSourceDocs/create'))
);
const ShowAdminSourceDoc = Loader(
    lazy(() => import('src/content/common/admin/AdminSourceDocs/show'))
);

// Virtual Reports
const VirtualReports = Loader(
    lazy(() => import('src/content/common/admin/VirtualReport'))
);
const NewVirtualReport = Loader(
    lazy(() => import('src/content/common/admin/VirtualReport/create'))
);
const ShowVirtualReport = Loader(
    lazy(() => import('src/content/common/admin/VirtualReport/show'))
);

// Hgs Reports
const HgsReports = Loader(
    lazy(() => import('src/content/common/admin/HgsReport'))
);
const NewHgsReport = Loader(
    lazy(() => import('src/content/common/admin/HgsReport/create'))
);
const ShowHgsReport = Loader(
    lazy(() => import('src/content/common/admin/HgsReport/show'))
);

// LetsGoFishing Reports
const LetsGoFishingReports = Loader(
    lazy(() => import('src/content/common/admin/LetsGoFishingReport'))
);

// Csr Reports
const CsrReports = Loader(
    lazy(() => import('src/content/common/admin/CsrReport'))
);
const NewCsrReport = Loader(
    lazy(() => import('src/content/common/admin/CsrReport/create'))
);
const ShowCsrReport = Loader(
    lazy(() => import('src/content/common/admin/CsrReport/show'))
);

// Admin Settings
const AdminReportSettings = Loader(
    lazy(() => import('src/content/common/admin/AdminReportSettings'))
);
const ServiceDays = Loader(
    lazy(() => import('src/content/common/admin/ServiceDays'))
);
const ReasonsForNonRemittance = Loader(
    lazy(() => import('src/content/common/admin/ReasonsForNonRemittance'))
);
const CsrCategories = Loader(
    lazy(() => import('src/content/common/admin/CsrCategories'))
);
const SubCsrCategories = Loader(
    lazy(() => import('src/content/common/admin/SubCsrCategories'))
);

// App Icons
const NewAppIcon = Loader(
    lazy(() => import('src/content/common/admin/AppIcon/create'))
);

// Sample tables
const SampleDataTable = Loader(
    lazy(() => import('src/content/common/z_SampleDataFolder/DataTable-01'))
);

const SampleDataTable1 = Loader(
    lazy(() => import('src/content/common/z_SampleDataFolder/DataTable-01'))
);
const SampleDataTable2 = Loader(
    lazy(() => import('src/content/common/z_SampleDataFolder/DataTable-02'))
);
const SampleDataTable3 = Loader(
    lazy(() => import('src/content/common/z_SampleDataFolder/DataTable-03'))
);
const SampleDataTable4 = Loader(
    lazy(() => import('src/content/common/z_SampleDataFolder/DataTable-04'))
);
const SampleDataTable6 = Loader(
    lazy(() => import('src/content/common/z_SampleDataFolder/DataTable-06'))
);
const SampleDataTable7 = Loader(
    lazy(() => import('src/content/common/z_SampleDataFolder/DataTable-07'))
);

const SampleReactTable = Loader(
    lazy(() => import('src/content/common/z_SampleDataFolder/ReactTable-01'))
);

// Demo
// const ViewAdminReportTableDemo = Loader(
//     lazy(() => import('src/content/common/admin/AdminReportMuiTableDemo'))
// );

const adminReports = [
    {
        path: '/',
        element: <Navigate to="adminReports" replace />
    },
    {
        path: 'adminReports',
        children: [
            {
                path: '/',
                element: <Navigate to="newAdminReport" replace />
            },
            {
                path: 'SampleDataTable',
                element: <SampleDataTable />
            },
            {
                path: 'SampleDataTable1',
                element: <SampleDataTable1 />
            },
            {
                path: 'SampleDataTable2',
                element: <SampleDataTable2 />
            },
            {
                path: 'SampleDataTable3',
                element: <SampleDataTable3 />
            },
            {
                path: 'SampleDataTable4',
                element: <SampleDataTable4 />
            },
            {
                path: 'SampleDataTable6',
                element: <SampleDataTable6 />
            },
            {
                path: 'SampleDataTable7',
                element: <SampleDataTable7 />
            },
            {
                path: 'SampleReactTable',
                element: <SampleReactTable />
            },
            {
                path: 'adminReportSettings',
                element: <AdminReportSettings />
            },
            {
                path: 'serviceDays',
                element: <ServiceDays />
            },
            {
                path: 'reasonsForNonRemittance',
                element: <ReasonsForNonRemittance />
            },
            {
                path: 'newAdminReport',
                element: <NewAdminReport />
            },
            {
                path: 'viewAdminReport',
                element: <AdminReports />
            },
            {
                path: 'showAdminReport',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    // {
                    //     path: 'test',
                    //     element: <AdminReports />
                    // }
                    {
                        path: ':year/:month/:parishCode',
                        element: <ShowAdminReport />
                    }
                ]
            },
            {
                path: 'newAdminSourceDoc',
                element: <NewAdminSourceDoc />
            },
            {
                path: 'viewAdminSourceDoc',
                element: <AdminSourceDocs />
            },
            {
                path: 'showAdminSourceDoc',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    // {
                    //     path: 'test',
                    //     element: <AdminSourceDocs />
                    // }
                    {
                        path: ':year/:month/:parishCode',
                        element: <ShowAdminSourceDoc />
                    }
                ]
            },
            {
                path: 'newVirtualReport',
                element: <NewVirtualReport />
            },
            {
                path: 'viewVirtualReport',
                element: <VirtualReports />
            },
            {
                path: 'showVirtualReport',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    // {
                    //     path: 'test',
                    //     element: <VirtualReports />
                    // }
                    {
                        path: ':year/:month/:parishCode',
                        element: <ShowVirtualReport />
                    }
                ]
            },
            {
                path: 'newHgsReport',
                element: <NewHgsReport />
            },
            {
                path: 'viewHgsReport',
                element: <HgsReports />
            },
            {
                path: 'showHgsReport',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    // {
                    //     path: 'test',
                    //     element: <HgsReports />
                    // }
                    {
                        path: ':year/:month/:parishCode',
                        element: <ShowHgsReport />
                    }
                ]
            },
            {
                path: 'viewLetsGoFishingReport',
                element: <LetsGoFishingReports />
            },
            {
                path: 'csrCategories',
                element: <CsrCategories />
            },
            {
                path: 'subCsrCategories',
                element: <SubCsrCategories />
            },
            {
                path: 'newCsrReport',
                element: <NewCsrReport />
            },
            {
                path: 'viewCsrReport',
                element: <CsrReports />
            },
            {
                path: 'showCsrReport',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    // {
                    //     path: 'test',
                    //     element: <CsrReports />
                    // }
                    {
                        path: ':year/:month/:parishCode',
                        element: <ShowCsrReport />
                    }
                ]
            },
            {
                path: 'products',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="list" replace />
                    },
                    {
                        path: 'list',
                        element: <ShowAdminReport />
                    },
                    {
                        path: 'create',
                        element: <NewAdminReport />
                    },
                    {
                        path: 'single',
                        children: [
                            {
                                path: '/',
                                element: <Navigate to="1" replace />
                            },
                            {
                                path: ':reportId',
                                element: <ViewAdminReport />
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        path: 'app-icons',
        element: <NewAppIcon />
    }
];

export default adminReports;
