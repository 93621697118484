const aeJSON = {
    Blueprints: 'المخططات',
    'Extended Sidebar': 'ممتد الشريط الجانبي',
    'Accent Sidebar': 'لهجة الشريط الجانبي',
    'Accent Header': 'رأس تشكيل',
    'Boxed Sidebar': 'شريط جانبي محاصر',
    'Collapsed Sidebar': 'شريط جانبي مطوي',
    'Bottom Navigation': 'التنقل السفلي',
    'Top Navigation': 'أعلى التنقل',
    'Lead Developer': 'المطور الرئيسي',
    'Mega menu': 'قائمة ميجا',
    Doctors: 'الأطباء',
    Reports: 'التقارير',
    'Custom dashboard built using the included components':
        'لوحة عدادات مخصصة مبنية باستخدام المكونات المضمنة',
    Hospital: 'مستشفى',
    Export: 'يصدر',
    'Last year': 'العام الماضي',
    Expenses: 'نفقات',
    Products: 'منتجات',
    Statistics: 'إحصائيات',
    General: 'عام',
    Automation: 'التشغيل الآلي',
    'Data Display': 'عرض البيانات',
    Calendar: 'التقويم',
    Mailbox: 'صندوق بريد',
    Dashboards: 'لوحات القيادة',
    Analytics: 'تحليلات',
    Banking: 'الخدمات المصرفية',
    Commerce: 'تجارة',
    Crypto: 'تشفير',
    Finance: 'تمويل',
    Fitness: 'اللياقة البدنية',
    Healthcare: 'الرعاية الصحية',
    'Doctors Page': 'صفحة الأطباء',
    'Hospital Overview': 'نظرة عامة على المستشفى',
    Helpdesk: 'مكتب المساعدة',
    Learning: 'التعلم',
    Monitoring: 'يراقب',
    Tasks: 'مهام',
    Applications: 'التطبيقات',
    'File Manager': 'مدير الملفات',
    'Jobs Platform': 'منصة الوظائف',
    Messenger: 'رسول',
    'Projects Board': 'مجلس المشاريع',
    Management: 'إدارة',
    Users: 'المستخدمون',
    'List All': 'قائمة جميع',
    'User Profile': 'ملف تعريفي للمستخدم',
    Projects: 'المشاريع',
    Shop: 'محل',
    'Products List': 'قائمة المنتجات',
    'View Product': 'عرض المنتج',
    'Create Product': 'إنشاء منتج',
    Invoices: 'الفواتير',
    'View Single': 'مشاهدة ملف',
    'Auth Pages': 'صفحات المؤلف',
    Login: 'تسجيل الدخول',
    Basic: 'أساسي',
    Cover: 'غطاء',
    Register: 'يسجل',
    Wizard: 'ساحر',
    'Recover Password': 'إستعادة كلمة المرور',
    Status: 'حالة',
    Foundation: 'مؤسسة',
    'Extra Pages': 'صفحات اضافية',
    'Error 404': 'خطأ 404',
    'Error 500': 'خطأ 500',
    'Coming Soon': 'قريبا',
    Maintenance: 'صيانة',
    Overview: 'ملخص',
    'Layout Starters': 'مبتدئين تخطيط',
    'Starter Kit 1': 'مجموعة المبتدئين 1',
    'Starter Kit 2': 'مجموعة المبتدئين 2',
    Documentation: 'توثيق',
    Welcome: 'مرحبا',
    Help: 'يساعد',
    'Features tour': 'جولة الميزات',
    'Getting started guide': 'دليل البداية',
    'Contact support': 'اتصل بالدعم',
    Version: 'إصدار',
    Search: 'بحث',
    Notifications: 'إشعارات',
    Settings: 'إعدادات',
    'Language Switcher': 'محوّل اللغة',
    'Sign out': 'خروج',
    'Change Theme': 'غير الخلفية',
    'View all notifications': 'عرض جميع الإخطارات',
    'Tokyo NextJS Typescript Admin Dashboard': 'لوحة تحكم إدارة Tokyo React',
    'International Reporting, Remittance and Church Process Automation System by RCCG':
        'قالب React عالي الأداء المصمم بالكثير من مكونات واجهة المستخدم المادية القوية عبر منافذ منتجات متعددة لعمليات تطوير تطبيقات سريعة ومثالية',
    'Browse Live Preview': 'تصفح المعاينة المباشرة',
    'Live Preview': 'المعاينة الحية',
    'Key Features': 'دلائل الميزات',
    dashboards: 'لوحات القيادة',
    applications: 'التطبيقات',
    'management sections': 'أقسام الإدارة',
    'dark/light themes': 'موضوعات داكنة / فاتحة',
    components: 'عناصر',
    'Some of the features that make Tokyo one of the best admin templates available today':
        'بعض الميزات التي تجعل طوكيو أحد أفضل قوالب الإدارة المتوفرة اليوم',
    'Design Source Files': 'ملفات مصدر التصميم',
    'Start working on your project directly from one of the included starter kits or use the Figma/Sketch files to create a prototype first':
        'ابدأ العمل في مشروعك مباشرةً من إحدى مجموعات البداية المضمنة أو استخدم ملفات Figma / Sketch لإنشاء نموذج أولي أولاً',
    'We only translated a small part of the template, for demonstration purposes':
        'قمنا فقط بترجمة جزء صغير من النموذج لأغراض التوضيح'
};

export default aeJSON;
