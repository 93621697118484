import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
    (
        <Suspense fallback={<SuspenseLoader />}>
            <Component {...props} />
        </Suspense>
    );

const Menu = Loader(
    lazy(() => import('src/content/common/vision2032/Vision2032'))
);
const ManageTiers = Loader(
    lazy(() => import('src/content/common/vision2032/ManageTiers'))
);
const Benchmarks = Loader(
    lazy(() => import('src/content/common/vision2032/Benchmarks'))
);
const ViewBenchmarks = Loader(
    lazy(() => import('src/content/common/vision2032/ViewBenchmarks'))
);
const ListBenchmarks = Loader(
    lazy(() =>
        import('src/content/common/vision2032/Benchmarks/ListBenchmarks')
    )
);
const AnnualReport = Loader(
    lazy(() => import('src/content/common/vision2032/AnnualReport'))
);
const MonthlyReport = Loader(
    lazy(() => import('src/content/common/vision2032/MonthlyReport'))
);
const ViewReport = Loader(
    lazy(() => import('src/content/common/vision2032/AnnualReport/Single'))
);
const SetBenchmarks = Loader(
    lazy(() => import('src/content/common/vision2032/Benchmarks/SetBenchmarks'))
);
const YearlyReport = Loader(
    lazy(() => import('src/content/common/vision2032/Benchmarks/YearlyReport'))
);
const ListedReport = Loader(
    lazy(() => import('src/content/common/vision2032/AvgService'))
);
const ViewSubContinent = Loader(
    lazy(() => import('src/content/common/vision2032/AvgService/subContinent'))
);
const ViewRegService = Loader(
    lazy(() => import('src/content/common/vision2032/AvgService/report'))
);
const ViewProvService = Loader(
    lazy(() => import('src/content/common/vision2032/AvgService/zone'))
);

const ViewZoneService = Loader(
    lazy(() => import('src/content/common/vision2032/AvgService/area'))
);
const ViewAreaService = Loader(
    lazy(() => import('src/content/common/vision2032/AvgService/region'))
);
const ViewParishService = Loader(
    lazy(() => import('src/content/common/vision2032/AvgService/parish'))
);
const SingleTier = Loader(
    lazy(() =>
        import('src/content/common/vision2032/Benchmarks/ListBenchmarks/single')
    )
);
const CreateBenchmark = Loader(
    lazy(() =>
        import('src/content/common/vision2032/Benchmarks/CreateBenchmark')
    )
);
const ViewBenchmark = Loader(
    lazy(() => import('src/content/common/vision2032/Benchmarks/Create'))
);
// Main Report Components
const MainReport = Loader(
    lazy(() => import('src/content/common/vision2032/NationalPerformance'))
);
const SubMainReport = Loader(
    lazy(() =>
        import('src/content/common/vision2032/NationalPerformance/SubContinent')
    )
);
const RegionMainReport = Loader(
    lazy(() =>
        import('src/content/common/vision2032/NationalPerformance/Region')
    )
);

const ProvinceMainReport = Loader(
    lazy(() =>
        import('src/content/common/vision2032/NationalPerformance/Province')
    )
);
const ZoneMainReport = Loader(
    lazy(() => import('src/content/common/vision2032/NationalPerformance/Zone'))
);
const AreaMainReport = Loader(
    lazy(() => import('src/content/common/vision2032/NationalPerformance/Area'))
);
const ParishMainReport = Loader(
    lazy(() =>
        import('src/content/common/vision2032/NationalPerformance/Parish')
    )
);
const vision2032Routes = [
    {
        path: '/',
        element: <Navigate to="vision2032" replace />
    },
    {
        path: 'report',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <Menu />
            }
        ]
    },
    {
        path: 'tiers',
        children: [
            {
                path: '/',
                element: <Navigate to="manage-tiers" replace />
            },
            {
                path: 'manage-tiers',
                element: <ManageTiers />
            }
        ]
    },
    {
        path: 'benchmarks',
        children: [
            {
                path: '/',
                element: <Navigate to="benchmarks" replace />
            },
            {
                path: 'manage-benchmarks',
                element: <Benchmarks />
            },
            {
                path: 'view',
                element: <ViewBenchmarks />
            },
            {
                path: 'setBenchmarks',
                element: <SetBenchmarks />
            },
            {
                path: 'setBenchmarks',
                children: [
                    {
                        path: '/',
                        element: <SetBenchmarks />
                    },
                    {
                        path: ':churchHierarchyCode/:tierId',
                        element: <ViewBenchmark />
                    }
                ]
            },
            // /target-page/:churchHierarchyCode/:tierId
            {
                path: 'list',
                element: <ListBenchmarks />
            },
            {
                path: 'tierView',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':tierId',
                        element: <SingleTier />
                    }
                ]
            },

            {
                path: 'create',
                element: <CreateBenchmark />
            },
            {
                path: 'viewBenchmark',
                element: <ViewBenchmark />
            }
        ]
    },
    {
        path: 'reports',
        children: [
            {
                path: '/',
                element: <Navigate to="reports" replace />
            },
            {
                path: 'annual-report',
                element: <AnnualReport />
            },
            {
                path: 'monthly-report',
                element: <MonthlyReport />
            },
            {
                path: 'yearlyReport',
                element: <YearlyReport />
            },
            {
                path: 'view',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':directoryId',
                        element: <ViewReport />
                    }
                ]
            }
        ]
    },
    // Vision 2032 Listed Reports
    {
        path: '/performance',
        children: [
            {
                path: '/',
                element: <Navigate to="listed" replace />
            },
            {
                path: 'listed',
                element: <ListedReport />
            },
            {
                path: 'subcontinent',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="list" replace />
                    },
                    {
                        path: 'list',
                        element: <ViewSubContinent />
                    },

                    {
                        path: ':parishId',
                        element: <ViewSubContinent />
                    }
                ]
            },
            {
                path: 'region',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="list" replace />
                    },
                    {
                        path: 'list',
                        element: <ViewRegService />
                    },

                    {
                        path: ':parishId',
                        element: <ViewRegService />
                    }
                ]
            },
            {
                path: 'province',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="province" replace />
                    },
                    {
                        path: 'province',
                        element: <ViewProvService />
                    },

                    {
                        path: ':parishId',
                        element: <ViewProvService />
                    }
                ]
            },
            {
                path: 'zone',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="zone" replace />
                    },
                    {
                        path: 'zone',
                        element: <ViewZoneService />
                    },

                    {
                        path: ':parishId',
                        element: <ViewZoneService />
                    }
                ]
            },
            {
                path: 'area',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="area" replace />
                    },
                    {
                        path: 'area',
                        element: <ViewAreaService />
                    },

                    {
                        path: ':parishId',
                        element: <ViewAreaService />
                    }
                ]
            },
            {
                path: 'parish',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="parish" replace />
                    },
                    {
                        path: 'parish',
                        element: <ViewParishService />
                    },

                    {
                        path: ':parishId',
                        element: <ViewParishService />
                    }
                ]
            }
        ]
    },
    // Vision 2032 Main Reports
    {
        path: '/main',
        children: [
            {
                path: '/',
                element: <Navigate to="continent" replace />
            },
            {
                path: 'continent',
                element: <MainReport />
            },
            {
                path: 'subContinent',
                element: <SubMainReport />
            },
            {
                path: 'region',
                element: <RegionMainReport />
            },
            {
                path: 'province',
                element: <ProvinceMainReport />
            },
            {
                path: 'zone',
                element: <ZoneMainReport />
            },
            {
                path: 'area',
                element: <AreaMainReport />
            },
            {
                path: 'parish',
                element: <ParishMainReport />
            }
        ]
    },
    // Listed per region,province etc
    {
        path: '/listed',
        children: [
            {
                path: '/',
                element: <Navigate to="continent" replace />
            },
            {
                path: 'continent',
                element: <ListedReport />
            },
            {
                path: 'subContinent',
                element: <ViewSubContinent />
            },
            {
                path: 'region',
                element: <ViewRegService />
            },
            {
                path: 'province',
                element: <ViewProvService />
            },
            {
                path: 'zone',
                element: <ViewZoneService />
            },
            {
                path: 'area',
                element: <ViewAreaService />
            },
            {
                path: 'parish',
                element: <ParishMainReport />
            }
        ]
    }
];

export default vision2032Routes;
