const deJSON = {
    Blueprints: 'Blaupausen',
    'Extended Sidebar': 'Erweiterte Seitenleiste',
    'Accent Sidebar': 'Akzent-Sidebar',
    'Accent Header': 'Akzentkopfzeile',
    'Boxed Sidebar': 'Verschachtelte Seitenleiste',
    'Collapsed Sidebar': 'Zugeklappte Seitenleiste',
    'Bottom Navigation': 'Untere Navigation',
    'Top Navigation': 'Top-Navigation',
    'Lead Developer': 'Leitender Entwickler',
    'Mega menu': 'Mega-Menü',
    Doctors: 'Ärzte',
    Reports: 'Berichte',
    'Custom dashboard built using the included components':
        'Benutzerdefiniertes Dashboard erstellt mit den enthaltenen Komponenten',
    Hospital: 'Krankenhaus',
    Export: 'Export',
    'Last year': 'Letztes Jahr',
    Expenses: 'Ausgaben',
    Products: 'Produkte',
    Statistics: 'Statistiken',
    General: 'Allgemein',
    Automation: 'Automatisierung',
    'Data Display': 'Datenanzeige',
    Calendar: 'Kalender',
    Mailbox: 'Briefkasten',
    Dashboards: 'Dashboards',
    Analytics: 'Analytik',
    Banking: 'Bankwesen',
    Commerce: 'Handel',
    Crypto: 'Krypto',
    Finance: 'Finanzen',
    Fitness: 'Fitness',
    Healthcare: 'Gesundheitspflege',
    'Doctors Page': 'Ärzte Seite',
    'Hospital Overview': 'Krankenhausübersicht',
    Helpdesk: 'Beratungsstelle',
    Learning: 'Lernen',
    Monitoring: 'Überwachung',
    Tasks: 'Aufgaben',
    Applications: 'Anwendungen',
    'File Manager': 'Dateimanager',
    'Jobs Platform': 'Jobs Plattform',
    Messenger: 'Bote',
    'Projects Board': 'Projektvorstand',
    Management: 'Management',
    Users: 'Benutzer',
    'List All': 'Liste alle auf',
    'User Profile': 'Benutzerprofil',
    Projects: 'Projekte',
    Shop: 'Geschäft',
    'Products List': 'Produktliste',
    'View Product': 'Produkt anzeigen',
    'Create Product': 'Produkt erstellen',
    Invoices: 'Rechnungen',
    'View Single': 'Single anzeigen',
    'Auth Pages': 'Auth-Seiten',
    Login: 'Anmeldung',
    Basic: 'Basic',
    Cover: 'Startseite',
    Register: 'Registrieren',
    Wizard: 'Magier',
    'Recover Password': 'Passwort wiederherstellen',
    Status: 'Status',
    Foundation: 'Stiftung',
    'Extra Pages': 'Zusätzliche Seiten',
    'Error 404': 'Fehler 404',
    'Error 500': 'Fehler 500',
    'Coming Soon': 'Kommt bald',
    Maintenance: 'Instandhaltung',
    Overview: 'Überblick',
    'Layout Starters': 'Layout-Starter',
    'Starter Kit 1': 'Starter Kit 1',
    'Starter Kit 2': 'Starter Kit 2',
    Documentation: 'Dokumentation',
    Welcome: 'Herzlich willkommen',
    Help: 'Hilfe',
    'Features tour': 'Features Tour',
    'Getting started guide': 'Erste Schritte Anleitung',
    'Contact support': 'Kontaktieren Sie Support',
    Version: 'Ausführung',
    Search: 'Suche',
    Notifications: 'Benachrichtigun',
    Settings: 'Einstellungen',
    'Language Switcher': 'Sprach Wechsler',
    'Sign out': 'Ausloggen',
    'Change Theme': 'Thema ändern',
    'View all notifications': 'Alle Benachrichtigungen anzeigen',
    'Tokyo NextJS Typescript Admin Dashboard':
        'Tokyo NextJS Typescript Admin Dashboard',
    'International Reporting, Remittance and Church Process Automation System by RCCG':
        'Hochleistungs-React-Vorlage mit vielen leistungsstarken Material-UI-Komponenten in mehreren Produktnischen für schnelle und perfekte Entwicklungsprozesse für Apps',
    'Browse Live Preview': 'Durchsuchen Sie die Live-Vorschau',
    'Live Preview': 'Live Vorschau',
    'Key Features': 'Hauptmerkmale',
    dashboards: 'dashboards',
    applications: 'anwendungen',
    'management sections': 'management-bereiche',
    'dark/light themes': 'dunkle / helle themen',
    components: 'komponenten',
    'Some of the features that make Tokyo one of the best admin templates available today':
        'Einige der Funktionen, die Tokio zu einer der besten heute verfügbaren Admin-Vorlagen machen',
    'Design Source Files': 'Quelldateien entwerfen',
    'Start working on your project directly from one of the included starter kits or use the Figma/Sketch files to create a prototype first':
        'Beginnen Sie direkt mit einem der mitgelieferten Starter-Kits mit der Arbeit an Ihrem Projekt oder verwenden Sie zuerst die Figma / Sketch-Dateien, um einen Prototyp zu erstellen',
    'We only translated a small part of the template, for demonstration purposes':
        'Wir haben nur einen kleinen Teil der Vorlage zu Demonstrationszwecken übersetzt'
};

export default deJSON;
