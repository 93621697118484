import { createContext, useEffect, useReducer } from 'react';
import axios from 'axios';
import axiosMock from 'src/utils/axios';
import {
    isAuthenticated,
    JWT_SECRET,
    COOKIE_SECRET,
    COOKIE_SECRET_V2
} from 'src/utils/jwt';
import PropTypes from 'prop-types';
import { EncryptStorage } from 'encrypt-storage';

const { v4: uuidv4 } = require('uuid');
const sessionstorage = require('sessionstorage');

export const encryptStorage = new EncryptStorage(COOKIE_SECRET, {
    storageType: 'sessionStorage'
});
export const encryptStorage2 = new EncryptStorage(COOKIE_SECRET, {
    storageType: 'localStorage'
});

export const encryptStorageLocal = new EncryptStorage(COOKIE_SECRET, {
    storageType: 'localStorage'
});

const { Encryptor } = require('node-laravel-encryptor');

let scrambleStorage = new Encryptor({
    key: COOKIE_SECRET_V2
});

const initialAuthState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null
};

const setSession = (accessToken) => {
    if (accessToken) {
        let accessTokenV2 = scrambleStorage.encryptSync(accessToken);
        encryptStorage.setItem('accessToken', accessToken);
        sessionstorage.setItem('sessionID', uuidv4());
        sessionstorage.setItem('accessTokenV2', accessTokenV2);

        encryptStorageLocal.setItem('accessToken', accessToken);
        encryptStorageLocal.setItem('sessionID', uuidv4());
        encryptStorageLocal.setItem('accessTokenV2', accessTokenV2);

        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        axios.defaults.headers.common.AuthToken = accessTokenV2;
        axiosMock.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
        encryptStorage.removeItem('accessToken');
        sessionstorage.removeItem('accessTokenV2');
        sessionstorage.removeItem('sessionID');

        encryptStorageLocal.removeItem('accessToken');
        encryptStorageLocal.removeItem('accessTokenV2');
        encryptStorageLocal.removeItem('sessionID');

        delete axios.defaults.headers.common.Authorization;
        delete axios.defaults.headers.common.AuthToken;
        delete axiosMock.defaults.headers.common.Authorization;
    }
};

const handlers = {
    INITIALIZE: (state, action) => {
        const { isAuthenticated, user } = action.payload;

        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user
        };
    },
    LOGIN: (state, action) => {
        const { user } = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user
        };
    },
    LOGOUT: (state) => ({
        ...state,
        isAuthenticated: false,
        user: null
    }),
    REGISTER: (state, action) => {
        const { user } = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user
        };
    }
};

const reducer = (state, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
    ...initialAuthState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    register: () => Promise.resolve()
});

export const AuthProvider = (props) => {
    const { children } = props;
    const [state, dispatch] = useReducer(reducer, initialAuthState);

    useEffect(() => {
        const initialize = async () => {
            try {
                const accessToken = encryptStorage.getItem('accessToken');
                if (accessToken && isAuthenticated(accessToken, JWT_SECRET)) {
                    setSession(accessToken);

                    const response = await axiosMock.get(
                        '/api/account/personal'
                    );
                    const { user } = response.data;

                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: true,
                            user
                        }
                    });
                } else {
                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: false,
                            user: null
                        }
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: 'INITIALIZE',
                    payload: {
                        isAuthenticated: false,
                        user: null
                    }
                });
            }
        };

        initialize();
    }, []);

    const login = async (email, password) => {
        const response = await axiosMock.post('/api/account/login', {
            email,
            password
        });
        const { accessToken, user } = response.data;

        setSession(accessToken);
        dispatch({
            type: 'LOGIN',
            payload: {
                user
            }
        });
    };

    const loginApi = async (username, password) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_AUTH_AND_ENDPOINT_BASE_URL}/auth/login`,
                {
                    username,
                    password
                }
            );

            const { accessToken, user } = response.data;

            if (user) {
                setSession(accessToken);
                encryptStorage2.removeItem('allParishes');
                encryptStorage2.removeItem('userParish');
                // encryptStorage2.removeItem('allParishesMini');
                encryptStorage.removeItem('allDepartments');
                encryptStorage.removeItem('userRole');
                encryptStorage.removeItem('currencies');
                encryptStorage.removeItem('churchHierarchies');
                encryptStorage.removeItem('financeApprovalAdmin');

                encryptStorage.removeItem('user'); // newly added
                encryptStorage.setItem('user', user); // newly added

                encryptStorageLocal.removeItem('userRole');
                encryptStorageLocal.removeItem('allParishes');
                encryptStorageLocal.removeItem('userParish');
                encryptStorageLocal.removeItem('financeApprovalAdmin');
                encryptStorageLocal.removeItem('financeConfig');

                await storeCurrencies();
                await storeUserRole(user);
                await storeAppIcons();
                await storeAllParishesInRegion(user);
                await storeDepartments();
                await storeTierId();
                // await storeAllParishesMini();
                await storeChurchHierarchies();
                await storeUserCountry(user.country);
                await storeFinanceBasicDetails(user.country);

                dispatch({
                    type: 'LOGIN',
                    payload: {
                        user
                    }
                });
                // eslint-disable-next-line
                window.location.href = window.location.href;
            } else {
                // eslint-disable-next-line
                alert(message);
            }
        } catch (err) {
            console.error(err);
            const message = err?.response?.data?.message || err.message;
            // eslint-disable-next-line
            alert(message);
        }
    };

    const logout = async () => {
        setSession(null);
        encryptStorage2.removeItem('allParishes');
        encryptStorage2.removeItem('userParish');
        encryptStorage2.removeItem('allParishesMini');
        encryptStorage.removeItem('allParishesMini');
        encryptStorage.removeItem('allDepartments');
        encryptStorage.removeItem('userRole');
        encryptStorage.removeItem('currencies');
        encryptStorage.removeItem('appIcons');
        encryptStorage.removeItem('userCountry');
        encryptStorage.removeItem('rhqData');
        encryptStorage.removeItem('churchHierarchies');
        encryptStorage.removeItem('financeApprovalAdmin');

        encryptStorageLocal.removeItem('userRole');
        encryptStorageLocal.removeItem('allParishes');
        encryptStorageLocal.removeItem('userParish');
        encryptStorageLocal.removeItem('financeApprovalAdmin');
        encryptStorageLocal.removeItem('financeConfig');

        encryptStorageLocal.removeItem('tierId');

        dispatch({ type: 'LOGOUT' });
    };

    const register = async (email, name, password) => {
        const response = await axiosMock.post('/api/account/register', {
            email,
            name,
            password
        });
        const { accessToken, user } = response.data;

        window.localStorage.setItem('accessToken', accessToken);
        dispatch({
            type: 'REGISTER',
            payload: {
                user
            }
        });
    };

    const storeCurrencies = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_FINANCE_ENDPOINT_BASE_URL}/currency`
            );

            const { error, data } = res.data;

            if (!error && Array.isArray(data)) {
                encryptStorage.setItem('currencies', JSON.stringify(data));
            }
        } catch (err) {
            console.error(err);
        }
    };

    const storeFinanceBasicDetails = async (countryName = '') => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_FINANCE_ENDPOINT_BASE_URL}/config`
            );
            const { error, data } = res.data;

            if (!error && typeof data === 'object') {
                // encryptStorage.setItem('financeConfig', data);
                encryptStorageLocal.setItem('financeConfig', data);
                if (data.currencies && Array.isArray(data.currencies)) {
                    // later remove this and use it in financeConfig object

                    // Use filter to get unique 'code' values
                    const uniqueCurrencies = data.currencies.filter(
                        (item, index, self) =>
                            index ===
                            self.findIndex((t) => t.code === item.code)
                    );

                    encryptStorage.setItem(
                        'currencies',
                        JSON.stringify(uniqueCurrencies)
                    );
                    // encryptStorage.setItem('currencies', JSON.stringify(data.currencies));

                    const userCountry = data.currencies.filter(
                        (currency) =>
                            currency.alpha2.toLowerCase() ===
                            countryName.toLowerCase()
                    )[0];

                    if (userCountry && userCountry?.id) {
                        encryptStorage.removeItem('userCountry');
                        encryptStorage.setItem(
                            'userCountry',
                            JSON.stringify(userCountry)
                        );
                        encryptStorageLocal.removeItem('userCountry');
                        encryptStorageLocal.setItem(
                            'userCountry',
                            JSON.stringify(userCountry)
                        );
                    }
                }

                if (data.approvalAdmin && Array.isArray(data.approvalAdmin)) {
                    // encryptStorage.setItem('financeApprovalAdmin', data.approvalAdmin);
                    encryptStorageLocal.setItem(
                        'financeApprovalAdmin',
                        data.approvalAdmin
                    );
                    // monthlyReportLock // bankAccount

                    const bankAccountApprovalAdmins = data.approvalAdmin.filter(
                        (item) => item.name === 'bankAccount'
                    )[0]?.roles;
                    const bankAccountApprovalAdminRoles =
                        bankAccountApprovalAdmins.map((admin) => {
                            return admin.name;
                        });
                    encryptStorage.setItem(
                        'financeBankAccountApprovalAdminRoles',
                        bankAccountApprovalAdminRoles
                    );
                }
            }
        } catch (err) {
            console.error(err);
        }
    };

    const storeAllParishesInRegion = async (user) => {
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_AUTH_AND_ENDPOINT_BASE_URL}/v1/parishDirectory/search?pageNo=1&pageSize=1000000`,
                {
                    orAnd: 'and',
                    params: [
                        {
                            columnName: 'continentCode',
                            // columnName: 'regionCode',
                            columnLogic: 'LIKE',
                            columnValue: user.continent
                            // columnValue: user.region
                        }
                    ]
                }
            );
            const { records } = res.data;

            if (Array.isArray(records) && records.length > 0) {
                encryptStorage2.setItem('allParishes', JSON.stringify(records));
                const userParish = records.filter(
                    (item) => item.parishCode === user.parish
                )[0]; // we also need to save the logged in user parish
                encryptStorage2.setItem(
                    'userParish',
                    JSON.stringify(userParish)
                );
                // console.log('up:', userParish)
            }
        } catch (err) {
            console.error(err);
        }
    };

    const storeChurchHierarchies = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_FINANCE_ENDPOINT_BASE_URL}/churchHierarchy`
            );
            const { error, data } = res.data;

            if (!error && Array.isArray(data)) {
                encryptStorage.setItem('churchHierarchies', data);
            }
        } catch (err) {
            console.error(err);
        }
    };

    // const storeAllParishesMini = async () => {
    //     try {
    //         const res = await axios.post(
    //             `${process.env.REACT_APP_AUTH_AND_ENDPOINT_BASE_URL}/v1/parishDirectory/searchAndFilter?pageNo=1&pageSize=5000&requestedFields=parishCode parishName areaCode areaName zoneCode zoneName provinceCode provinceName regionCode regionName subContinentCode subContinentName continentCode ahq zhq phq rhq schq chq status parish`,
    //             {
    //                 orAnd: 'and',
    //                 params: [
    //                     {
    //                         columnName: 'rhq',
    //                         columnLogic: 'LIKE',
    //                         columnValue: '1'
    //                     }
    //                 ]
    //             }
    //         );
    //         const { records } = res.data;
    //         // console.log(res);

    //         if (Array.isArray(records) && records.length > 0) {
    //             encryptStorage2.setItem(
    //                 'allParishesMini',
    //                 JSON.stringify(records)
    //             );
    //             // console.log('rhq:', userParish)
    //         }
    //     } catch (err) {
    //         console.error(err);
    //     }
    // };

    const storeDepartments = async () => {
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_AUTH_AND_ENDPOINT_BASE_URL}/v1/parishDirectory/search?pageNo=1&pageSize=5000`,
                {
                    orAnd: 'and',
                    params: [
                        {
                            columnName: 'parishType',
                            columnLogic: 'LIKE',
                            columnValue: 'DEPARTMENT'
                        },
                        {
                            columnName: 'status',
                            columnLogic: 'LIKE',
                            columnValue: '1'
                        }
                    ]
                }
            );

            const { records } = res.data;

            if (Array.isArray(records) && records.length > 0) {
                encryptStorage.setItem(
                    'allDepartments',
                    JSON.stringify(records)
                );
            }
        } catch (err) {
            console.error('err: ', err);
        }
    };

    const storeUserCountry = async (countryName) => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_FINANCE_ENDPOINT_BASE_URL}/country`
            );
            const { error, data } = res.data;

            if (!error && Array.isArray(data) && data.length > 0) {
                const userCountry = data.filter(
                    (country) => country.name === countryName
                )[0];
                if (userCountry && userCountry?.id) {
                    encryptStorage.removeItem('userCountry');
                    encryptStorage.setItem(
                        'userCountry',
                        JSON.stringify(userCountry)
                    );
                }
            }
        } catch (err) {
            console.error('err: ', err);
        }
    };

    const storeUserRole = async (user) => {
        try {
            const params = [];

            JSON.parse(user.roles).map((role) => {
                params.push({
                    columnName: 'slug',
                    columnLogic: 'LIKE',
                    columnValue: role
                });
                return role;
            });

            const res = await axios.post(
                `${process.env.REACT_APP_AUTH_AND_DIRECTORY_ENDPOINT_BASE_URL}/v1/roles/search`,
                {
                    orAnd: 'or',
                    params
                }
            );
            const { records } = res.data;

            if (records && Array.isArray(records) && records.length > 0) {
                if (records.length === 1) {
                    encryptStorage.setItem('userRole', records[0]);
                    encryptStorageLocal.setItem('userRole', records[0]);
                } else {
                    let userHighestRole = {};
                    let highestWeight = records[0].weight;

                    for (let i = 0; i < records.length; i++) {
                        if (records[i].weight > highestWeight) {
                            userHighestRole = records[i];
                            highestWeight = records[i].weight;
                        }
                    }
                    encryptStorage.setItem('userRole', userHighestRole);
                    encryptStorageLocal.setItem('userRole', userHighestRole);
                }
            }
        } catch (err) {
            console.error('err: ', err);
        }
    };

    const storeAppIcons = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_ADMIN_REPORT_ENDPOINT_BASE_URL}/app-icons`
            );

            const { status, data } = res.data;

            if (
                status === 'success' &&
                Array.isArray(data) &&
                data.length > 0
            ) {
                encryptStorage.setItem('appIcons', data);
            }
        } catch (err) {
            console.error('err: ', err);
        }
    };

    const storeTierId = async () => {
        try {
            const tierResponse = await axios.get(
                `${process.env.REACT_APP_ADMIN_REPORT_ENDPOINT_BASE_URL}/vision2032/tier/linked-church-hierarchies`
            );

            const tier = tierResponse.data.data;
            encryptStorage.setItem('tierId', tier);
        } catch (err) {
            console.error('err: ', err);
        }
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                loginApi,
                logout,
                register
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default AuthContext;
