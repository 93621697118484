import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { encryptStorage } from 'src/contexts/JWTAuthContext';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
    (
        <Suspense fallback={<SuspenseLoader />}>
            <Component {...props} />
        </Suspense>
    );

// Get user role from storage
const userRole = encryptStorage.getItem('userRole');

// Allowed roles for accessing the 'compose' route
const allowedRolesForCompose = [
    'co',
    'cont-accountant',
    'cont-admin',
    'sco',
    'sub-cont-accountant',
    'sub-cont-admin'
];

// Route Guard Component
const ProtectedRoute = ({
    allowedRoles,
    redirectPath = '/not-authorized',
    children
}) => {
    return allowedRoles.includes(userRole?.slug) ? (
        children
    ) : (
        <Navigate to={redirectPath} replace />
    );
};
// Emailbox

const Emailbox = Loader(
    lazy(() => import('src/content/common/messaging/Mailbox'))
);
// const Single = Loader(
//     lazy(() => import('src/content/common/messaging/Single'))
// );

const directoryRoutes = [
    {
        path: '/',
        element: <Navigate to="emailbox" replace />
    },
    {
        path: 'emailbox',
        children: [
            {
                path: '/',
                element: <Navigate to="inbox" replace />
            },

            {
                path: 'tag/:labelTag',
                element: <Emailbox />
            },
            {
                path: 'tag/:labelTag/:mailboxCategory',
                element: <Emailbox />
            },
            {
                path: ':categoryTag',
                element: <Emailbox />
            },
            {
                path: ':categoryTag/:mailboxCategory',
                element: <Emailbox />
            },
            {
                path: 'compose',
                element: (
                    <ProtectedRoute allowedRoles={allowedRolesForCompose}>
                        <Emailbox />
                    </ProtectedRoute>
                )
            }
        ]
    }
];

export default directoryRoutes;
