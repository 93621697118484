import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { parse } from 'date-fns';

import axios from 'axios';

const initialState = {
    events: [],
    isDrawerOpen: false,
    selectedEventId: null,
    selectedRange: null
};

const slice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        getEvents(state, action) {
            const { events } = action.payload;

            state.events = events;
        },
        createEvent(state, action) {
            const { event } = action.payload;

            state.events = [...state.events, event];
        },
        selectEvent(state, action) {
            const { eventId = null } = action.payload;

            state.isDrawerOpen = true;
            state.selectedEventId = eventId;
        },
        updateEvent(state, action) {
            const { event } = action.payload;
            

            state.events = _.map(state.events, (_event) => {
                if (_event.id === event.id) {
                    return event;
                }

                return _event;
            });
        },
        deleteEvent(state, action) {
            const { eventId } = action.payload;

            state.events = _.reject(state.events, { id: eventId });
        },
        selectRange(state, action) {
            const { start, end } = action.payload;

            state.isDrawerOpen = true;
            state.selectedRange = {
                start,
                end
            };
        },
        openDrawerPanel(state) {
            state.isDrawerOpen = true;
        },
        closeDrawerPanel(state) {
            state.isDrawerOpen = false;
            state.selectedEventId = null;
            state.selectedRange = null;
        }
    }
});

export const reducer = slice.reducer;

export const getEvents = () => async (dispatch) => {
    const BASEURL = process.env.REACT_APP_RPMS_ENDPOINT_BASE_URL_VERSIONED;

    try {
        const { data } = await axios.get(`${BASEURL}/events`);

        const tableDataRows = data.data.map((element) => ({
            id: `${element.id}`,
            allDay: false,
            color: '#1975FF',
            description: '',
            start_date: element.start_date,
            end_date: element.end_date,
            start_time: element.start_time,
            end_time: element.end_time,

            occurrence: element.occurrence,
            notification_date: element.notification_date,
            sms: element.sms,
            event_flyer: element.event_flyer,
            event_flyer_path: element.event_flyer_path,
            event_video: element.event_video,
            event_video_path: element.event_video_path,
            event_audio: element.event_audio,
            event_audio_path: element.event_audio_path,

            end: parse(
                `${element.end_date} : ${element.end_time}`,
                'yyyy-MM-dd : h:m a',
                new Date()
            ),
            start: parse(
                `${element.start_date} : ${element.start_time}`,
                'yyyy-MM-dd : h:m a',
                new Date()
            ),
            title: element.name
        }));
        console.log('Events', tableDataRows);

        dispatch(slice.actions.getEvents({ events: tableDataRows }));
    } catch (err) {
        console.log('Something went wrong', err);
    }
};

export const createEvent = (data) => async (dispatch) => {
    const response = await axios.post('/api/calendar/meetings/create', data);

    dispatch(slice.actions.createEvent(response.data));
};

export const selectEvent = (eventId) => async (dispatch) => {
    dispatch(slice.actions.selectEvent({ eventId }));
};

export const updateEvent = (eventId, update) => async (dispatch) => {
    const response = await axios.post('/api/calendar/meetings/update', {
        eventId,
        update
    });

    dispatch(slice.actions.updateEvent({updateEvent:response.data}));
};

export const deleteEvent = (id) => async (dispatch) => {
    const BASEURL = process.env.REACT_APP_RPMS_ENDPOINT_BASE_URL_VERSIONED;
    try {
         await axios.post(`${BASEURL}/events/delete`, {
             id
         });

         dispatch(slice.actions.deleteEvent({ eventId:id }));
        
    } catch (error) {
        console.log('Something went wrong', error);
    }
   
};

export const selectRange = (start, end) => (dispatch) => {
    dispatch(
        slice.actions.selectRange({
            start: start.getTime(),
            end: end.getTime()
        })
    );
};

export const openDrawerPanel = () => (dispatch) => {
    dispatch(slice.actions.openDrawerPanel());
};

export const closeDrawerPanel = () => (dispatch) => {
    dispatch(slice.actions.closeDrawerPanel());
};

export default slice;
