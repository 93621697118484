const esJSON = {
    Blueprints: 'Planos',
    'Extended Sidebar': 'Barra lateral extendida',
    'Accent Sidebar': 'Barra lateral de acento',
    'Accent Header': 'Encabezado de acento',
    'Boxed Sidebar': 'Barra lateral en caja',
    'Collapsed Sidebar': 'Barra lateral contraída',
    'Bottom Navigation': 'Navegación inferior',
    'Top Navigation': 'Navegación superior',
    'Lead Developer': 'Desarrollador principal',
    'Mega menu': 'Mega menú',
    Doctors: 'Doctores',
    Reports: 'Informes',
    'Custom dashboard built using the included components':
        'Panel de control personalizado construido con los componentes incluidos',
    Hospital: 'Hospital',
    Export: 'Exportar',
    'Last year': 'El año pasado',
    Expenses: 'Gastos',
    Products: 'Productos',
    Statistics: 'Estadísticas',
    General: 'General',
    Automation: 'Automatización',
    'Data Display': 'Visualización de datos',
    Calendar: 'Calendario',
    Mailbox: 'Buzón',
    Dashboards: 'Cuadros de mando',
    Analytics: 'Analítica',
    Banking: 'Bancario',
    Commerce: 'Comercio',
    Crypto: 'Cripto',
    Finance: 'Finanzas',
    Fitness: 'Aptitud física',
    Healthcare: 'Cuidado de la salud',
    'Doctors Page': 'Página de médicos',
    'Hospital Overview': 'Descripción del hospital',
    Helpdesk: 'Mesa de ayuda',
    Learning: 'Aprendiendo',
    Monitoring: 'Supervisión',
    Tasks: 'Tareas',
    Applications: 'Aplicaciones',
    'File Manager': 'Administrador de archivos',
    'Jobs Platform': 'Plataforma de trabajos',
    Messenger: 'Mensajero',
    'Projects Board': 'Junta de proyectos',
    Management: 'Administración',
    Users: 'Usuarios',
    'List All': 'Listar todo',
    'User Profile': 'Perfil del usuario',
    Projects: 'Proyectos',
    Shop: 'Tienda',
    'Products List': 'Lista de productos',
    'View Product': 'Ver el producto',
    'Create Product': 'Crear producto',
    Invoices: 'Facturas',
    'View Single': 'Ver individual',
    'Auth Pages': 'Autenticación',
    Login: 'Acceso',
    Basic: 'Básico',
    Cover: 'Cubrir',
    Register: 'Registrarse',
    Wizard: 'Mago',
    'Recover Password': 'Recuperar contraseña',
    Status: 'Estado',
    Foundation: 'Fundación',
    'Extra Pages': 'Páginas extra',
    'Error 404': 'Error 404',
    'Error 500': 'Error 500',
    'Coming Soon': 'Próximamente',
    Maintenance: 'Mantenimiento',
    Overview: 'Visión general',
    'Layout Starters': 'Arrancadores de diseño',
    'Starter Kit 1': 'Kit de inicio 1',
    'Starter Kit 2': 'Kit de inicio 2',
    Documentation: 'Documentación',
    Welcome: 'Bienvenida',
    Help: 'Ayuda',
    'Features tour': 'Tour de características',
    'Getting started guide': 'Guía de inicio',
    'Contact support': 'Soporte de contacto',
    Version: 'Versión',
    Search: 'Buscar',
    Notifications: 'Notificaciones',
    Settings: 'Ajustes',
    'Language Switcher': 'Selector de idioma',
    'Sign out': 'Desconectar',
    'Change Theme': 'Cambiar de tema',
    'View all notifications': 'Ver todas las notificaciones',
    'Tokyo NextJS Typescript Admin Dashboard':
        'Panel de administración de Tokyo React',
    'International Reporting, Remittance and Church Process Automation System by RCCG':
        'Plantilla React de alto rendimiento construida con una gran cantidad de poderosos componentes Material-UI en múltiples nichos de productos para procesos de desarrollo de aplicaciones rápidos y perfectos',
    'Browse Live Preview': 'Explorar vista previa en vivo',
    'Live Preview': 'Vista previa en vivo',
    'Key Features': 'Características clave',
    dashboards: 'cuadros de mando',
    applications: 'aplicaciones',
    'management sections': 'secciones de gestión',
    'dark/light themes': 'temas oscuros / claros',
    components: 'componentes',
    'Some of the features that make Tokyo one of the best admin templates available today':
        'Algunas de las características que hacen de Tokio una de las mejores plantillas de administración disponibles en la actualidad',
    'Design Source Files': 'Diseñar archivos fuente',
    'Start working on your project directly from one of the included starter kits or use the Figma/Sketch files to create a prototype first':
        'Comience a trabajar en su proyecto directamente desde uno de los kits de inicio incluidos o use los archivos Figma / Sketch para crear un prototipo primero',
    'We only translated a small part of the template, for demonstration purposes':
        'Solo traducimos una pequeña parte de la plantilla, con fines de demostración'
};

export default esJSON;
