import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
    (
        <Suspense fallback={<SuspenseLoader />}>
            <Component {...props} />
        </Suspense>
    );

// Parish Directory
const Parish = Loader(
    lazy(() => import('src/content/common/directory/Parish'))
);
const MyProvinceParish = Loader(
    lazy(() => import('src/content/common/directory/MyProvinceParish'))
);
const ParishByProvince = Loader(
    lazy(() => import('src/content/common/directory/ParishByProvince'))
);
const MainRCCGDirectory = Loader(
    lazy(() =>
        import('src/content/common/z_SampleDataFolder/MuiReactTableDemo29')
    )
);
const ViewParish = Loader(
    lazy(() => import('src/content/common/directory/Parish/view'))
);

// Area Directory
const Area = Loader(lazy(() => import('src/content/common/directory/Area')));
const ViewArea = Loader(
    lazy(() => import('src/content/common/directory/Area/view'))
);

// Zone Directory
const Zone = Loader(lazy(() => import('src/content/common/directory/Zone')));
const ViewZone = Loader(
    lazy(() => import('src/content/common/directory/Zone/view'))
);

// Province Directory
const Province = Loader(
    lazy(() => import('src/content/common/directory/Province'))
);
const ViewProvince = Loader(
    lazy(() => import('src/content/common/directory/Province/view'))
);

// Region Directory
const Region = Loader(
    lazy(() => import('src/content/common/directory/Region'))
);
const ViewRegion = Loader(
    lazy(() => import('src/content/common/directory/Region/view'))
);

// SubContinent Directory
const SubContinent = Loader(
    lazy(() => import('src/content/common/directory/SubContinent'))
);
const ViewSubContinent = Loader(
    lazy(() => import('src/content/common/directory/SubContinent/view'))
);

// Continent Directory
const Continent = Loader(
    lazy(() => import('src/content/common/directory/Continent'))
);
const ViewContinent = Loader(
    lazy(() => import('src/content/common/directory/Continent/view'))
);
// Directory sub menu
const DirectorySubMenu = Loader(
    lazy(() =>
        import(
            'src/content/common/dashboards/MainMenu/directory/ProvinceDirectory'
        )
    )
);
// Principal Officers
const PrincipalSubMenu = Loader(
    lazy(() =>
        import(
            'src/content/common/dashboards/MainMenu/directory/PrincipalOfficers'
        )
    )
);

const directoryRoutes = [
    {
        path: '/',
        element: <Navigate to="directory" replace />
    },
    {
        path: 'parishDirectory',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <Parish />
            },
            {
                path: 'view',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':directoryId',
                        element: <ViewParish />
                    }
                ]
            }
        ]
    },
    {
        path: 'myProvinceDirectory',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <MyProvinceParish />
            },
            {
                path: 'view',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':directoryId',
                        element: <ViewParish />
                    }
                ]
            }
        ]
    },

    {
        path: 'ParishByProvince',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: ':provinceCode',
                element: <ParishByProvince />
            },
            {
                path: 'view',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':directoryId',
                        element: <ViewParish />
                    }
                ]
            }
        ]
    },
    {
        path: 'areaDirectory',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <Area />
            },
            {
                path: 'view',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':directoryId',
                        element: <ViewArea />
                    }
                ]
            }
        ]
    },
    {
        path: 'zoneDirectory',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <Zone />
            },
            {
                path: 'view',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':directoryId',
                        element: <ViewZone />
                    }
                ]
            }
        ]
    },
    {
        path: 'provinceDirectory',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <Province />
            },
            {
                path: 'main',
                element: <MyProvinceParish />
            },
            {
                path: 'view',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':directoryId',
                        element: <ViewProvince />
                    }
                ]
            }
        ]
    },
    {
        path: 'regionDirectory',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <Region />
            },
            {
                path: 'view',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':directoryId',
                        element: <ViewRegion />
                    }
                ]
            }
        ]
    },
    {
        path: 'subContinentDirectory',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <SubContinent />
            },
            {
                path: 'view',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':directoryId',
                        element: <ViewSubContinent />
                    }
                ]
            }
        ]
    },
    {
        path: 'continentDirectory',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <Continent />
            },
            {
                path: 'view',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':directoryId',
                        element: <ViewContinent />
                    }
                ]
            }
        ]
    },
    {
        path: 'mainRCCGDirectory',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <MainRCCGDirectory />
            },
            {
                path: 'view',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="1" replace />
                    },
                    {
                        path: ':directoryId',
                        element: <ViewContinent />
                    }
                ]
            }
        ]
    },
    {
        path: 'mainDirectory',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <DirectorySubMenu />
            }
        ]
    },
    {
        path: 'principalOfficers',
        children: [
            {
                path: '/',
                element: <Navigate to="list" replace />
            },
            {
                path: 'list',
                element: <PrincipalSubMenu />
            }
        ]
    }
];

export default directoryRoutes;
