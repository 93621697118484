import { Suspense, lazy } from 'react';
import { encryptStorageLocal } from 'src/contexts/JWTAuthContext';
import SuspenseLoader from 'src/components/SuspenseLoader';
import { Navigate } from 'react-router-dom';
import { 
    arrayInArray, 
    user, 
    continentRoles, 
    subContinentRoles, 
    regionRoles, 
    provinceRoles,
    financeBankAccountApprovalAdminRoles,
    allRolesObj,
    parishRoles,
    areaRoles,
    zoneRoles
} from 'src/content/common/finance/Helper';

const financeConfig = encryptStorageLocal.getItem('financeConfig');

const { 
    parishAccountant, 
    picParish, 
    areaAccountant, 
    picArea,
    picZoneAccountant, 
    picZone,
    provAsstAccountant, 
    provAccountant, 
    picp, 
    regAsstAccountant, 
    regAccountant, 
    regAdmin, 
    picr, 
    subContAccountant, 
    subContAdmin, 
    sco,
    contAccountant,
    co
} = allRolesObj;

// // console.log('user', user, arrayInArray([...provinceRoles, ...regionRoles, ...subContinentRoles, ...continentRoles], user?.role), user.role, arrayInArray([...provinceRoles, ...regionRoles, ...subContinentRoles, ...continentRoles], user?.role));

const Loader = (Component) => (props) =>
    (
        <Suspense fallback={<SuspenseLoader />}>
            <Component {...props} /> 
        </Suspense>
    ); 

const ComplianceReport = Loader(lazy(() => import('src/content/common/finance/FinancialReport/ComplianceReport/ComplianceReport')));
const NoRemittance = Loader(lazy(() => import('src/content/common/finance/FinancialReport/ComplianceReport/NoRemittance')));
const NoRemittanceWithReasons = Loader(lazy(() => import('src/content/common/finance/FinancialReport/ComplianceReport/NoRemittanceWithReasons')));
const CummulativeReport = Loader(lazy(() => import('src/content/common/finance/FinancialReport/CummulativeReport/CummulativeReport')));

const DepartmentReport = Loader(lazy(() => import('src/content/common/finance/FinancialReport/DepartmentReport')));

const ReportDistribution = Loader(lazy(() => import('src/content/common/finance/FinancialReport/ReportDistribution')));
const MonthlyReportApproval = Loader(lazy(() => import('src/content/common/finance/MonthlyReportApproval')));
const DebtManagementIndex = Loader(lazy(() => import('src/content/common/finance/DebtManagement/index')));
const DebtLog = Loader(lazy(() => import('src/content/common/finance/DebtManagement/DebtLog')));
const PaymentTypes = Loader(lazy(() => import('src/content/common/finance/PaymentType/PaymentTypes')));
const PaymentItemConfiguration = Loader( lazy(() => import('src/content/common/finance/PaymentItemConfiguration/PaymentItemConfiguration')));
const PaymentItemConfigurationListed = Loader( lazy(() => import('src/content/common/finance/PaymentItemConfiguration/Listed/PaymentItemConfiguration')));
const PaymentItemConfigurationListedMenus = Loader( lazy(() => import('src/content/common/finance/PaymentItemConfiguration/Menus')));
const CreatePaymentItemConfiguration = Loader(lazy(() => import('src/content/common/finance/PaymentItemConfiguration/AddPaymentItemConfiguration')));
const Exemption = Loader(lazy(() => import('src/content/common/finance/exemption/Exemption')));
const PaymentItemsMenu = Loader(lazy(() => import('src/content/common/finance/PaymentItems/PaymentItemsMenus')));
const PaymentIssuesMenu = Loader(lazy(() => import('src/content/common/finance/PaymentIssues/PaymentIssuesMenus')));
const CorrectPaymentStatus = Loader(lazy(() =>import('src/content/common/finance/PaymentIssues/CorrectPaymentStatus'))); 

const WemMenus = Loader(
    lazy(() => import('src/content/common/finance/PaymentItems/WemMenus'))
);

const PaymentItemsConfigMenus = Loader(lazy(() => import('src/content/common/finance/PaymentItems/PaymentItemsConfigMenus')));
const PaymentItem = Loader(lazy(() => import('src/content/common/finance/PaymentItems/PaymentItems')));

const AdditionalPaymentItemIndex = Loader(
    lazy(() =>
        import('src/content/common/finance/additionalPaymentItems')
    )
);
// const RemittanceCategorizationMenu = Loader(lazy(() => import('src/content/common/finance/Remittance/categorizationMenu')));
const AdditionalPaymentItems = Loader(lazy(() => import('src/content/common/finance/additionalPaymentItems/PaymentItems')));
const AdditonalPaymentItemConfiguration = Loader(lazy(() =>import('src/content/common/finance/additionalPaymentItems/PaymentItemConfiguration/PaymentItemConfiguration')));
const AddAdditionalPaymentItemConfiguration = Loader(lazy(() => import('src/content/common/finance/additionalPaymentItems/PaymentItemConfiguration/AddPaymentItemConfiguration')));
const ParishToNationalIndex = Loader(lazy(() => import('src/content/common/finance/ParishToNational')));

const UserRolesAndPermissionsIndex = Loader(
    lazy(() =>
        import(
            'src/content/common/finance/UserRolesAndPermissions'
        )
    )
);
const UserRoles = Loader(
    lazy(() => import('src/content/common/finance/Role/UserRoles'))
);
const UserPermissions = Loader(
    lazy(() =>
        import(
            'src/content/common/finance/Permission/UserPermissions'
        )
    )
);
const RolePermissions = Loader(
    lazy(() =>
        import(
            'src/content/common/finance/RolePermission/RolePermissions'
        )
    )
);

const RemittanceIndex = Loader(lazy(() => import('src/content/common/finance/Remittance')));
const RemittanceCategory = Loader(lazy(() => import('src/content/common/finance/Remittance/RemittanceIncomeGroup/RemittanceIncomeGroup')));
const RemittanceCategoryCriteria = Loader(lazy(() => import('src/content/common/finance/Remittance/RemittanceCategoryCriteria/RemittanceCategoryCriteria')));
const RemittanceCategory1 = Loader(lazy(() => import('src/content/common/finance/Remittance/RemittanceCategory/RemittanceCategory')));
const RemittanceSpecialCategory = Loader(lazy(() => import('src/content/common/finance/Remittance/RemittanceSpecialCategory/RemittanceSpecialCategory')));
const RemittanceChurchCategory = Loader(lazy(() => import('src/content/common/finance/Remittance/RemittanceChurchCategory/RemittanceChurchCategory')));
const RemittanceSpecialCategoryMU = Loader(lazy(() => import('src/content/common/finance/Remittance/RemittanceSpecialCategoryMU/RemittanceSpecialCategoryMU')));
const RemittanceLockSettings = Loader(lazy(() => import('src/content/common/finance/Remittance/RemittanceLockSettings/RemittanceLockSettings')));
const SpecialUnlock = Loader(lazy(() => import('src/content/common/finance/Remittance/SpecialUnlock/SpecialUnlock')) );

const WemPaymentItems = Loader(lazy(() => import('src/content/common/finance/PaymentItemConfiguration/wem/WemPaymentItems')));
const WemPaymentItemConfiguration = Loader(lazy(() => import('src/content/common/finance/PaymentItemConfiguration/wem/WemPaymentItemConfiguration')));

const Banks = Loader(lazy(() => import('src/content/common/finance/Banks')));
const BankAccounts = Loader(lazy(() => import('src/content/common/finance/BankAccounts/BankAccounts')));
const ItemBankAccounts = Loader(lazy(() => import('src/content/common/finance/BankAccounts/ItemBankAccounts/ItemBankAccounts')));

const DeptBankAccounts = Loader(lazy(() => import('src/content/common/finance/BankAccounts/DeptBankAccounts/DeptBankAccounts')));
const Payment = Loader(lazy(() => import('src/content/common/finance/Payment/index')));

const BankAccountApproval = Loader(lazy(() => import('src/content/common/finance/BankAccountApproval/BankAccountApproval')));
const QuarterlyReport = Loader(lazy(() => import('src/content/common/finance/FinancialReport/QuarterlyReport')));
const YearlyReport = Loader(lazy(() => import('src/content/common/finance/FinancialReport/YearlyReport')));
const ApprovalRolesIndex = Loader(lazy(() => import('src/content/common/finance/ApprovalRoles/index')));
const ApprovalAdminsIndex = Loader(lazy(() => import('src/content/common/finance/ApprovalAdministrators/index')));
const AreaRemittanceSetup = Loader(lazy(() => import('src/content/common/finance/AreaRemittanceSetup/index')));
const EpaymentSubContSetup = Loader(lazy(() => import('src/content/common/finance/EpaymentSubContSetup/index')));
const GridTable = Loader(lazy(() => import('src/content/common/finance/demo/GridTable')));
const FinancialReportIndex = Loader(lazy(() => import('src/content/common/finance/FinancialReport/index')));
const AdditionalItemsFinancialReportIndex = Loader(lazy(() => import('src/content/common/finance/FinancialReport/AdditionalItemsReports/index')));
const ForexFinancialReportIndex = Loader(lazy(() => import('src/content/common/finance/FinancialReport/ForexReport/index')));
const AddForexReport = Loader(lazy(() => import('src/content/common/finance/FinancialReport/ForexReport/AddForexReport')));
const AddFinancialReport = Loader(lazy(() => import('src/content/common/finance/FinancialReport/AddFinancialReport'))); 
const AddAdditionalItemFinancialReport = Loader(lazy(() =>import('src/content/common/finance/FinancialReport/AdditionalItemsReports/AddAdditionalItemFinancialReport'))); 
const MonthRangePerformanceOld = Loader(lazy(() => import('src/content/common/finance/FinancialReport/MonthRangePerformance_old')));
const MonthRangePerformance = Loader(lazy(() => import('src/content/common/finance/FinancialReport/MonthRangePerformance')));
const ForexReport = Loader(lazy(() => import('src/content/common/finance/Forex/ForexReport')));
const AddSourceDocs = Loader(lazy(() => import('src/content/common/finance/FinancialReport/SourceDoc/AddSourceDocs')));
const SingleFinancialReport = Loader(lazy(() => import('src/content/common/finance/FinancialReport/single/index')));
const PaymentSummary = Loader(lazy(() => import('src/content/common/finance/FinancialReport/PaymentSummary/index')));
const PaymentSummaryDistribution = Loader(lazy(() =>import('src/content/common/finance/FinancialReport/PaymentSummary/PaymentDistribution')));
const Receipt = Loader(lazy(() => import('src/content/common/finance/FinancialReport/PaymentSummary/Receipt')));
const BankInvoice = Loader(lazy(() => import('src/content/common/finance/FinancialReport/BankInvoice/index'))); 
const TopPerformance = Loader(lazy(() =>import('src/content/common/finance/FinancialReport/TopPerformance'))); 
const FinanceMainPerformance = Loader(lazy(() =>import('src/content/common/finance/FinancialReport/FinanceMainPerformance'))); 
const PaymentToDepartment = Loader(lazy(() =>import('src/content/common/finance/FinancialReport/PaymentToDepartment'))); 
const PaymentReconciliation = Loader(lazy(() =>import('src/content/common/finance/FinancialReport/PaymentReconciliation'))); 
const BiggestToSmallest = Loader(lazy(() =>import('src/content/common/finance/FinancialReport/BiggestToSmallest'))); 
const IncomeTo = Loader(lazy(() => import('src/content/common/finance/FinancialReport/IncomeTo')));
const EduFund = Loader(lazy(() => import('src/content/common/finance/FinancialReport/EduFund')));
const OpenHeavenReport = Loader(lazy(() => import('src/content/common/finance/FinancialReport/OpenHeavenReport')));
const AnnualThanksgivingReport = Loader(lazy(() => import('src/content/common/finance/FinancialReport/AnnualThanksgivingReport')));
const MonthToMonth = Loader(lazy(() => import('src/content/common/finance/FinancialReport/MonthToMonth')));
const MainIncomeReport = Loader(lazy(() => import('src/content/common/finance/FinancialReport/MainIncomeReport')));
const MainIncomeReportByHierarchy = Loader(lazy(() => import('src/content/common/finance/FinancialReport/MainIncomeReportByHierarchy')));
const MainIncomeReportByUnits = Loader(lazy(() => import('src/content/common/finance/FinancialReport/MainIncomeReportByUnits')));
const NonSubmissionReasons = Loader(lazy(() =>import('src/content/common/finance/nonSubmission/NonSubmissionReasons'))); 
const UnAuthorrizedIndex = Loader(lazy(() => import('src/content/common/finance/UnAuthorrized/index')));
const Notice = Loader(lazy(() => import('src/content/common/finance/SuccessNotice')));
const GrantApprovals = Loader(lazy(() => import('src/content/common/finance/grantApprovals')));
const GrantDebtRequest = Loader(lazy(() => import('src/content/common/finance/grantApprovals/GrantDebtRequest')));
const IncomeReportMenu = Loader(lazy(() => import('src/content/common/finance/menus/incomeReport')));
const PerformanceFinancial = Loader(lazy(() => import('src/content/common/finance/menus/performanceFinancial')));
const GrantApproval = Loader(lazy(() => import('src/content/common/finance/menus/grantApproval')));
const PaymentManager = Loader(lazy(() => import('src/content/common/finance/menus/paymentManager')));
const MonthlyRemittance = Loader(lazy(() => import('src/content/common/finance/menus/monthlyRemittance')));
const AproveDebt = Loader(lazy(() => import('src/content/common/finance/grantApprovals/Approve')));

const financeRoutes = [
    {
        path: '/',
        element: <Navigate to="../" replace />
    },
    {
        path: 'access-denied',
        element: <UnAuthorrizedIndex />
    },
    {
        path: 'grid-table-demo',
        element: <GridTable /> 
    },                              
    {
        path: 'income-report-menu',
        element: <IncomeReportMenu />
    },  
    {
        path: 'performance-financial-menu',
        element: <PerformanceFinancial />
    },   
    {
        path: 'finance-approval-menu',
        element: <GrantApproval />
    },   
    {
        path: 'payment-manager-menu',
        element: <PaymentManager />
    },   
    {
        path: 'monthly-remittance-menu',
        element: <MonthlyRemittance />
    },    
    {
        path: 'payment-item-setup',
        children: user && arrayInArray([...continentRoles, ...subContinentRoles], user?.role)        
        ?        
            [
                {
                    path: '/',
                    element: <PaymentItemsMenu />
                }, 
                {
                    path: 'config',
                    children: [
                        {
                            path: '',
                            element: <PaymentItemsConfigMenus />
                        },
                        {
                            path: 'payment-items',
                            element: <PaymentItemConfiguration />
                        },
                        {
                            path: 'setup-listed-menu',
                            children: [
                                {
                                    path: '',
                                    element: <PaymentItemConfigurationListedMenus />
                                },
                                {
                                    path: 'listed',
                                    element: <PaymentItemConfigurationListed />
                                },
                                {
                                    path: 'category',
                                    element: <RemittanceCategory />
                                },
                                {
                                    path: 'church-category',
                                    element: <RemittanceChurchCategory />
                                },
                                {
                                    path: 'special-category-mu',
                                    element: <RemittanceSpecialCategoryMU />
                                },
                                {
                                    path: 'category1',
                                    element: <RemittanceCategory1 />
                                },
                                {
                                    path: 'special-category',
                                    element: <RemittanceSpecialCategory />
                                },
                            ]
                        }, 
                        {
                            path: 'wem',
                            children: [
                                {
                                    path: '',
                                    element: <WemMenus />
                                },
                                {
                                    path: 'payment-items',
                                    element: <WemPaymentItems />
                                },
                                {
                                    path: 'conditions',
                                    element: <WemPaymentItemConfiguration />
                                },
                            ]
                        },
                        {
                            path: 'configuration/add',
                            element: <CreatePaymentItemConfiguration />
                        },
                    ]
                },
                {
                    path: 'items',
                    element: <PaymentItem />
                },
                {
                    path: 'type',
                    element: <PaymentTypes />
                },
                {
                    path: 'exempt',
                    element: <Exemption />
                }
            ]
        : [
            {
                path: '/',
                element: <Navigate to="../access-denied" replace />
            },
        ]
    },
    {
        path: 'payment-issues',
        children: user && arrayInArray([...continentRoles, ...subContinentRoles], user?.role)        
        ?        
            [
                {
                    path: '/',
                    element: <PaymentIssuesMenu />
                },
                {
                    path: 'correct-payment-status',
                    element: <CorrectPaymentStatus />
                },
            ]
        : [
            {
                path: '/',
                element: <Navigate to="../access-denied" replace />
            },
        ]
    },
    {
        path: 'banks',
        element: user && arrayInArray([...continentRoles, ...subContinentRoles], user?.role) 
        ? <Banks /> 
        : <Navigate to="../access-denied" replace />
    },
    {
        path: 'bank-account',
        element: <BankAccounts />
    },
    {
        path: 'item-bank-account',
        element: <ItemBankAccounts />
    },
    // {
    //     path: 'dept-bank-account',
    //     element: user && arrayInArray([...continentRoles, ...subContinentRoles], user?.role) 
    //     ? <DeptBankAccounts /> 
    //     : <Navigate to="../access-denied" replace />
    // },    
    {
        path: 'dept-bank-account',
        element: user && arrayInArray([co, contAccountant, sco, subContAccountant, picr, regAccountant, picp, provAccountant, picZone, picZoneAccountant, picArea, areaAccountant, picParish, parishAccountant], user?.role) 
        ? <DeptBankAccounts /> 
        : <Navigate to="../access-denied" replace />
    },    
    {
        path: 'bank-account-approval',
        element: user && arrayInArray(financeBankAccountApprovalAdminRoles, user?.role) 
        ? <BankAccountApproval /> 
        : <Navigate to="../access-denied" replace />
    },
    {
        path: 'parish-to-national',        
        element: user && arrayInArray([...continentRoles, ...subContinentRoles], user?.role) 
        ? <ParishToNationalIndex /> 
        : <Navigate to="../access-denied" replace />
    },
    {
        path: 'remittance',
        children: user && arrayInArray([...continentRoles, ...subContinentRoles], user?.role)        
        ?        
        [
            {
                path: '/',
                element: <RemittanceIndex />
            },
            {
                path: 'categorization', 
                children:         
                [
                    {
                        path: '/',
                        element: <RemittanceCategoryCriteria />
                    },
                    {
                        path: 'category-criteria',
                        element: <RemittanceCategoryCriteria />
                    },        
                ]
            },
            {
                path: 'lock-settings',
                element: <RemittanceLockSettings />
            },
            {
                path: 'unlock',
                element: <SpecialUnlock />
            },
            {
                path: 'manage-additional-items', 
                children: user && arrayInArray([...continentRoles, ...subContinentRoles], user?.role)        
                ?        
                [
                    {
                        path: '/',
                        element: <AdditionalPaymentItemIndex />
                    },
                    {
                        path: 'view',
                        element: <AdditionalPaymentItems />
                    },
                    {
                        path: 'configuration',
                        element: <AdditonalPaymentItemConfiguration />
                    },
                    {
                        path: 'configuration/add',
                        element: <AddAdditionalPaymentItemConfiguration />
                    },
                ]
                : [
                    {
                        path: '/',
                        element: <Navigate to="../access-denied" replace />
                    },
                ]
            },
        ]
        : [
            {
                path: '/',
                element: <Navigate to="../access-denied" replace />
            },
        ]
    },
    { 
        path: 'grant-approvals',
        children: user && arrayInArray([...continentRoles, ...subContinentRoles, ...regionRoles, ...provinceRoles], user?.role)
        ?        
        [
            {
                path: '/',
                element: <GrantApprovals />
            },
            {
                path: 'debt',
                children: [
                    {
                        path: '/',
                        element: <GrantDebtRequest />
                    },
                    {
                        path: '/grant',
                        element: <AproveDebt />
                    },
                ]
            },
        ]        
        : 
        [
            {
                path: '/',
                element: <Navigate to="../access-denied" replace />
            },
        ]
    },
    { 
        path: 'roles-and-permissions',
        children: user && arrayInArray([...continentRoles, ...subContinentRoles], user?.role)        
        ?        
        [
            {
                path: '/',
                element: <UserRolesAndPermissionsIndex />
            },
            {
                path: 'roles',
                element: <UserRoles />
            },
            {
                path: 'permissions',
                element: <UserPermissions />
            },
            {
                path: 'role-permission',
                element: <RolePermissions />
            }
        ]        
        : 
        [
            {
                path: '/',
                element: <Navigate to="../access-denied" replace />
            },
        ]
    },
    {
        path: 'main-performance',
        // element: user && arrayInArray([...provinceRoles, ...regionRoles, ...subContinentRoles, ...continentRoles], user?.role) 
        element: user && arrayInArray([...parishRoles, ...areaRoles, ...zoneRoles, ...provinceRoles, ...regionRoles, ...subContinentRoles, ...continentRoles], user?.role) 
        ? <FinanceMainPerformance /> 
        : <Navigate to="../access-denied" replace />
    },
    {
        path: 'payment-to-dept',
        element: user && arrayInArray([...provinceRoles, ...regionRoles, ...subContinentRoles, ...continentRoles], user?.role) 
        ? <PaymentToDepartment /> 
        : <Navigate to="../access-denied" replace />
    },
    {
        path: 'payment-reconciliation',
        element: user && arrayInArray([...provinceRoles, ...regionRoles, ...subContinentRoles, ...continentRoles], user?.role) 
        ? <PaymentReconciliation /> 
        : <Navigate to="../access-denied" replace />
    },
    {
        path: 'edu-fund',
        element: user && arrayInArray([...provinceRoles, ...regionRoles, ...subContinentRoles, ...continentRoles], user?.role) 
        ? <EduFund /> 
        : <Navigate to="../access-denied" replace />
    },
    {
        path: 'open-heaven',
        element: user && arrayInArray([...provinceRoles, ...regionRoles, ...subContinentRoles, ...continentRoles], user?.role) 
        ? <OpenHeavenReport /> 
        : <Navigate to="../access-denied" replace />
    },
    {
        path: 'annual-thanksgiving',
        element: user && arrayInArray([...provinceRoles, ...regionRoles, ...subContinentRoles, ...continentRoles], user?.role) 
        ? <AnnualThanksgivingReport /> 
        : <Navigate to="../access-denied" replace />
    },
    {
        path: 'reports',
        children: [
            {
                path: '/',
                element: <FinancialReportIndex />
            },        
            {
                path: 'monthly-performance/single/:id',
                element: <SingleFinancialReport />
            },
            {
                path: 'payment-summary',
                element: <PaymentSummary />
            },
            {
                path: 'payment-summary-distribution',
                element: <PaymentSummaryDistribution />
            },
            {
                path: 'payment-summary-receipt',
                element: <Receipt />
            },
            {
                path: 'invoice',
                element: <BankInvoice />
            },            
            {
                path: 'add',
                element: user && arrayInArray([parishAccountant, picParish, areaAccountant, picArea], user?.role) 
                ? <AddFinancialReport /> 
                : <Navigate to="../access-denied" replace />
            },
            {
                path: 'forex-report',
                children: [
                    {
                        path: '/',
                        element: <ForexFinancialReportIndex /> 
                    },
                    {
                        path: 'add',
                        element: <AddForexReport />
                    }
                ]
            },
            {
                path: 'additional-items-report',
                // element: user && arrayInArray([provAsstAccountant, provAccountant, picp, regAsstAccountant, regAccountant, picr], user?.role) 
                element: user && arrayInArray([provAsstAccountant, provAccountant, picp, regAsstAccountant, regAccountant, picr, ...subContinentRoles, ...continentRoles], user?.role) 
                ? <AdditionalItemsFinancialReportIndex /> 
                : <Navigate to="../access-denied" replace />
            },
            {
                path: 'add-additional-items-report',
                element: user && arrayInArray([provAsstAccountant, provAccountant, picp, regAsstAccountant, regAccountant, picr], user?.role) 
                ? <AddAdditionalItemFinancialReport /> 
                : <Navigate to="../access-denied" replace />
            },
            {
                path: 'range',
                element: <MonthRangePerformance />
            },
            {
                path: 'range_old',
                element: <MonthRangePerformanceOld />
            },
            {
                path: 'ranking',
                element: user && arrayInArray([...provinceRoles, ...regionRoles, ...subContinentRoles, ...continentRoles], user?.role) 
                ? <TopPerformance /> 
                : <Navigate to="../access-denied" replace />
            },
            {
                path: 'biggest-to-smallest',
                element: user && arrayInArray([...provinceRoles, ...regionRoles, ...subContinentRoles, ...continentRoles], user?.role) 
                ? <BiggestToSmallest /> 
                : <Navigate to="../access-denied" replace />
            },
            {
                path: 'month-to-month',
                element: user && arrayInArray([...provinceRoles, ...regionRoles, ...subContinentRoles, ...continentRoles], user?.role) 
                ? <MonthToMonth /> 
                : <Navigate to="../../../access-denied" replace />
            },
            {
                path: 'income-to',
                element: user && arrayInArray([...provinceRoles, ...regionRoles, ...subContinentRoles, ...continentRoles], user?.role) 
                ? <IncomeTo /> 
                : <Navigate to="../access-denied" replace />
            },                            
            {
                path: 'main-income-report',
                children: [
                    {
                        path: '/',
                        element: user && arrayInArray([...provinceRoles, ...regionRoles, ...subContinentRoles, ...continentRoles], user?.role) 
                        ? <MainIncomeReport /> 
                        : <Navigate to="../access-denied" replace />
                    },        
                    {
                        path: 'by-hierarchy',
                        element: <MainIncomeReportByHierarchy />
                    },
                    {
                        path: 'by-units',
                        element: <MainIncomeReportByUnits />
                    },
                ]
            },                                   
            {
                path: 'upload-source-docs',
                element: user && arrayInArray([parishAccountant, picParish, areaAccountant, picArea], user?.role) 
                ? <AddSourceDocs /> 
                : <Navigate to="../access-denied" replace />
            }
        ]
    },
    {
        path: 'monthly-reports-approval',
        element: user && arrayInArray([...provinceRoles, ...regionRoles], user?.role) 
        ? <MonthlyReportApproval /> 
        : <Navigate to="../access-denied" replace />
    },
    {
        path: 'non-submission',
        element: <NonSubmissionReasons />
    },
    {
        path: 'reports/distribution',
        element: <ReportDistribution />
    },
    {
        path: 'compliance-report',
        element: user && arrayInArray([...provinceRoles, ...regionRoles, ...subContinentRoles, ...continentRoles], user?.role) 
        ? <ComplianceReport /> 
        : <Navigate to="../access-denied" replace />
    },
    {
        path: 'compliance-non-submission',
        element: user && arrayInArray([...provinceRoles, ...regionRoles, ...subContinentRoles, ...continentRoles], user?.role) 
        ? <NoRemittance /> 
        : <Navigate to="../access-denied" replace />
    },
    {
        path: 'compliance-non-submission-with-reasons',
        element: user && arrayInArray([...provinceRoles, ...regionRoles, ...subContinentRoles, ...continentRoles], user?.role) 
        ? <NoRemittanceWithReasons /> 
        : <Navigate to="../access-denied" replace />
    },
    {
        path: 'cummulative-report',
        element: user && arrayInArray([...zoneRoles,  ...provinceRoles, ...regionRoles, ...subContinentRoles, ...continentRoles], user?.role) 
        ? <CummulativeReport /> 
        : <Navigate to="../access-denied" replace />
    },
    // {
    //     path: 'add-forex-report',
    //     element: <AddForexReport />
    // },
    // {
    //     path: 'forex-report-old',
    //     element: <ForexReport />
    // },
    {
        path: 'forex-report',
        element: <ForexReport />
    },
    {
        path: 'department-report',
        element: user && arrayInArray([...provinceRoles, ...regionRoles, ...subContinentRoles, ...continentRoles], user?.role) 
        ? <DepartmentReport /> 
        : <Navigate to="../access-denied" replace />
    },
    {
        path: 'debt-management',
        element: <DebtManagementIndex />
    }, 
    {
        path: 'debt-log',
        element: <DebtLog />
    },
    { 
        path: 'quarterly-report',
        element: <QuarterlyReport />
    },    
    {
        path: 'yearly-report',
        element: <YearlyReport />
    },
    {
        path: 'approval-roles',
        element: user && arrayInArray([...continentRoles, ...subContinentRoles], user?.role) 
            ? <ApprovalRolesIndex /> 
            : <Navigate to="../access-denied" replace />
    },    
    {
        path: 'approval-admins',
        element: user && arrayInArray([...continentRoles, ...subContinentRoles], user?.role) 
            ? <ApprovalAdminsIndex /> 
            : <Navigate to="../access-denied" replace />
    },    
    {
        path: 'payment',
        element:financeConfig?.isOfflinePayment && arrayInArray([...parishRoles, ...areaRoles, ...zoneRoles, ...provinceRoles, ...regionRoles], user?.role) 
        ? <Payment /> 
        : <Navigate to="../access-denied" replace />
    },    
    {
        path: 'area-remittance-setup',
        element: arrayInArray([regAdmin, regAccountant, picr], user?.role) 
            ? <AreaRemittanceSetup /> 
            : <Navigate to="../access-denied" replace />
    },
    {
        path: 'sub-cont-epayment-setup',
        element: arrayInArray([subContAccountant, subContAdmin, sco], user?.role) 
            ? <EpaymentSubContSetup /> 
            : <Navigate to="../access-denied" replace />
    },
    {
        path: 'notice',
        // element: arrayInArray([subContAccountant, subContAdmin, sco], user?.role) 
        //     ? <Notice /> 
        //     : <Navigate to="../access-denied" replace />
        element: <Notice /> 
    }
];

export default financeRoutes;