import { Suspense, lazy } from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
    (
        <Suspense fallback={<SuspenseLoader />}>
            <Component {...props} />
        </Suspense>
    );

const Index = Loader(
    lazy(() => import('src/content/common/finance/PaymentItems'))
);
const PaymentTypes = Loader(
    lazy(() =>
        import('src/content/common/finance/PaymentType/PaymentTypes')
    )
);
const AddPaymentType = Loader(
    lazy(() =>
        import(
            'src/content/common/finance/PaymentItems/AddPaymentItem'
        )
    )
);
const PaymentItems = Loader(
    lazy(() =>
        import('src/content/common/finance/PaymentItems/PaymentItems')
    )
);
const AddPaymentItem = Loader(
    lazy(() =>
        import(
            'src/content/common/finance/PaymentItems/AddPaymentItem'
        )
    )
);

const paymentItemRoutes = [
    {
        path: '/',
        // element: <Navigate to="payment-types" replace />
        element: <Index />
    },
    {
        path: 'payment-types',
        element: <PaymentTypes />
    },
    {
        path: 'add-payment-type',
        element: <AddPaymentType />
    },
    {
        path: 'payment-items',
        element: <PaymentItems />
    },
    {
        path: 'add-payment-item',
        element: <AddPaymentItem />
    },
];

export default paymentItemRoutes;
