
import { encryptStorage, encryptStorageLocal } from 'src/contexts/JWTAuthContext';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import jwtDecode from 'jwt-decode';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { Zoom } from '@mui/material';
import axios from 'axios';
import { months } from './Enum';

const allParishes = encryptStorageLocal.getItem('allParishes') || [];
// export const user = encryptStorage.getItem('accessToken') ? jwtDecode(encryptStorage.getItem('accessToken'))?.user : '';
export const user = encryptStorageLocal.getItem('accessToken') ? jwtDecode(encryptStorageLocal.getItem('accessToken'))?.user : '';
// export const financeConfig = encryptStorage.getItem('financeConfig') || {};
export const financeConfig = encryptStorageLocal.getItem('financeConfig') || {};

export const grantAccess = (module, type) => {
    const userFinancialRoles = encryptStorage.getItem('financeRoles'); // ufr = User Finance Roles
    return userFinancialRoles.includes(`${type}:${module}`);
}

export const getCurrentMonth = () => {
    return months[new Date().getMonth()];
}

export const convertNumericMonthToString = (monthNum) => {
    let month = '';

    if(monthNum === '01')
        month = months[0];
    else if(monthNum === '02')
        month = months[1];
    else if(monthNum === '03')
        return months[2];
    else if(monthNum === '04')
        return months[3];
    else if(monthNum === '05')
        return months[4];
    else if(monthNum === '06')
        return months[5];
    else if(monthNum === '07')
        return months[6];
    else if(monthNum === '08')
        return months[7];
    else if(monthNum === '09')
        return months[8];
    else if(monthNum === '10')
        return months[9];
    else if(monthNum === '11')
        return months[10];
    else if(monthNum === '12')
        return months[11];

    return month;
}

export const convertStringMonthToNumber = (monthName) => {
    if(monthName === months[0] || monthName === months[0].substring(0,3))
        return 1;
    else if(monthName === months[1] || monthName === months[1].substring(0,3))
        return 2;

    else if(monthName === months[2] || monthName === months[2].substring(0,3))
        return 3;

    else if(monthName === months[3] || monthName === months[3].substring(0,3))
        return 4;

    else if(monthName === months[4] || monthName === months[4].substring(0,3))
        return 5;

    else if(monthName === months[5] || monthName === months[5].substring(0,3))
        return 6;

    else if(monthName === months[6] || monthName === months[6].substring(0,3))
        return 7;

    else if(monthName === months[7] || monthName === months[7].substring(0,3))
        return 8;

    else if(monthName === months[8] || monthName === months[8].substring(0,3))
        return 9;

    else if(monthName === months[9] || monthName === months[9].substring(0,3))
        return 10;

    else if(monthName === months[10] || monthName === months[10].substring(0,3))
        return 11;

    else if(monthName === months[11] || monthName === months[11].substring(0,3))
        return 12;

    return 0;
}

export const getCurrentYear = () => {
    return new Date().getFullYear();
}

export const getMonths = () => {
    return [
        {name: 'Jan', label: 'January'},
        {name: 'Feb', label: 'February'},
        {name: 'Mar', label: 'March'},
        {name: 'Apr', label: 'April'},
        {name: 'May', label: 'May'},
        {name: 'Jun', label: 'June'},
        {name: 'Jul', label: 'July'},
        {name: 'Aug', label: 'August'},
        {name: 'Sep', label: 'September'},
        {name: 'Oct', label: 'October'},
        {name: 'Nov', label: 'November'},
        {name: 'Dec', label: 'December'},
    ]
}

export const getUserHighestLevel = (userRoles) => { 
    userRoles = userRoles ? JSON.parse(userRoles) : [];
    let userHighestLevel = 1; // 1=parish, 2=area, 3=zone, 4=province, 5=region, 6=subcontinent, 7=continent

    const {
        parishAdmin,
        parishAccountant,
        picParish,
        areaAdmin,
        areaAccountant,
        picArea,    
        picZoneAccountant,
        picZone,
        provAsstAdmin,
        provAsstAccountant,
        provAdmin,
        provAccountant,
        apicpAdmin,
        apicpCSR,
        picp, 
        regAsstAdmin,
        regAsstAccountant,
        regAdmin,
        regAccountant,
        apicr,
        picr,
        subContAccountant,
        subContAdmin, 
        sco,
        contAdmin,
        contAccountant,
        co,
        // rpmsAdmin,
        // rpmsAccountant
    } = allRolesObj;

    if(userRoles.includes(co, contAccountant, contAdmin)){
        userHighestLevel = 7;
    }
    else if(userRoles.includes(sco, subContAccountant, subContAdmin,)){
        userHighestLevel = 6;
    }
    else if(userRoles.includes(picr, regAccountant, regAdmin, apicr, regAsstAccountant, regAsstAdmin)){
        userHighestLevel = 5;
    }
    else if(userRoles.includes(picp, provAccountant, provAdmin, provAsstAccountant, provAsstAdmin, apicpAdmin, apicpCSR)){
        userHighestLevel = 4;
    }
    else if(userRoles.includes(picZone, picZoneAccountant)){
        userHighestLevel = 3;
    }
    else if(userRoles.includes(picArea, areaAccountant, areaAdmin)){
        userHighestLevel = 2;
    }
    else if(userRoles.includes(picParish, parishAccountant, parishAdmin)){
        userHighestLevel = 1;
    }else{
        userHighestLevel = 0;
    }

    return userHighestLevel;
}

export const getUserHighestRole = (userRoles) => { 
    userRoles = userRoles ? JSON.parse(userRoles) : [];
    let userHighestRole = 'pic-parish'; 

    const {
        parishAdmin,
        parishAccountant,
        picParish,
        areaAdmin,
        areaAccountant,
        picArea,    
        picZoneAccountant,
        picZone,
        provAsstAdmin,
        provAsstAccountant,
        provAdmin,
        provAccountant,
        apicpAdmin,
        apicpCSR,
        picp, 
        regAsstAdmin,
        regAsstAccountant,
        regAdmin,
        regAccountant,
        apicr,
        picr,
        subContAccountant,
        subContAdmin, 
        sco,
        contAdmin,
        contAccountant,
        co,
        // rpmsAdmin,
        // rpmsAccountant
    } = allRolesObj;

    if(userRoles.includes(co, contAccountant, contAdmin)){
        userHighestRole = co;
    }
    else if(userRoles.includes(sco, subContAccountant, subContAdmin,)){
        userHighestRole = sco;
    }
    else if(userRoles.includes(picr, regAccountant, regAdmin, apicr, regAsstAccountant, regAsstAdmin)){
        userHighestRole = picr;
    }
    else if(userRoles.includes(picp, provAccountant, provAdmin, provAsstAccountant, provAsstAdmin, apicpAdmin, apicpCSR)){
        userHighestRole = picp;
    }
    else if(userRoles.includes(picZone, picZoneAccountant)){
        userHighestRole = picZone;
    }
    else if(userRoles.includes(picArea, areaAccountant, areaAdmin)){
        userHighestRole = picArea;
    }
    else if(userRoles.includes(picParish, parishAccountant, parishAdmin)){
        userHighestRole = picParish;
    }else{
        userHighestRole = '';
    }
    return userHighestRole;
}

export const getChurchName = (type, typeCode) => {
    type = type?.toLowerCase();    
    const church = getParishByAnyCode(typeCode, type);
  
    if(typeof church !== 'object'){
      return 'N/A';
    }  
    
    let churchName = '';
    if(type === 'continent')
      churchName = church.continentName;
      
    else if(type === 'subcontinent')
      churchName = church.subContinentName;
  
    else if(type === 'region')
      churchName = church.regionName;
  
    else if(type === 'province')
      churchName = church.provinceName;
  
    else if(type === 'zone')
      churchName = church.zoneName;
  
    else if(type === 'area')
      churchName = church.areaName;
  
    else if(type === 'parish' || type === 'department')
      churchName = church.parishName;
  
    return churchName;
}

export const getChurchNameFromChurch = (church, type)=>{    
    if(typeof church !== 'object'){
        return 'N/A';
      }

      type = type?.toLowerCase();
      
      let churchName = '';
      if(type === 'continent')
        churchName = church.continentName;
        
      else if(type === 'subcontinent')
        churchName = church.subContinentName;
    
      else if(type === 'region')
        churchName = church.regionName;
    
      else if(type === 'province')
        churchName = church.provinceName;
    
      else if(type === 'zone')
        churchName = church.zoneName;
    
      else if(type === 'area')
        churchName = church.areaName;
    
      else if(type === 'parish' || type === 'department')
        churchName = church.parishName;
    
      return churchName;
}

export const toTitleCase = (str)=>{
    return str?.replace(
      /\w\S*/g,
      (txt)=>{
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  export const convertDateFromISOToYearMonthDay = (ISODate) => {
    let year = ISODate.getFullYear();
    let month = ISODate.getMonth()+1;
    let dt = ISODate.getDate();

    if (dt < 10) {
        dt = `0${dt}`
    }
    if (month < 10) {
        month = `0${month}`;
    }

    return `${year}-${month}-${dt}`;
}

export const getTypeCode = (church, type) => {
    let typeCode;

    if(type === 'continent' || type === 'Continent'){
        typeCode = church.continentCode;
     }else if(type === 'subContinent' || type === 'subcontinent' || type === 'SubContinent'){
        typeCode = church.subContinentCode;
     }else if(type === 'region' || type === 'Region' ){
        typeCode = church.regionCode;
     }else if(type === 'province' || type === 'Province' ){
        typeCode = church.provinceCode;
     }else if(type === 'zone' || type === 'Zone' ){
        typeCode = church.zoneCode;
     }else if(type === 'area' || type === 'Area' ){
        typeCode = church.areaCode;
     }else if(type === 'parish' || type === 'Parish' || type === 'department' || type === 'Department'){
        typeCode = church.parishCode;
     }else{
        // // console.log('el');
     }

    return typeCode;
  }

  export const calCulateNumberOfMonths = ({startYear, endYear, startMonth, endMonth, canIncludeEndMonth = true}) => {
    let num;
    num= (endYear - startYear) * 12; // = 12
    num = canIncludeEndMonth ? num+1 : num;
    num-= startMonth + 1; // 1
    num+= endMonth +1; // 12
    return num <= 0 ? 0 : num;
 }

 export const getMonthsAndYearsInMonthRange = ({startYear, endYear, startMonth, endMonth}) => {
    const numOfMonths = calCulateNumberOfMonths({startYear, endYear, startMonth, endMonth})
    const monthNames = months;
    let monthsAndYears = [];

    for(let i=0, x = startMonth; i<numOfMonths; i++, x++){
        if(x > 12){
            x = 1;
            startYear++
        }

        monthsAndYears.push({
            id: i,
            year: startYear,
            month: monthNames[x-1]
        })
    }

    return monthsAndYears.length < 0 ? [] : monthsAndYears;
 }

 export const getNextMonth = ({currentMonthName, nextMonthCount=1}) => { // return next month e.g Jan, 1 => Feb, Jan, 2 => March
    const yearlyMonths = getMonths();

    // const currentMonth = yearlyMonths.filter((month)=> month.label === currentMonthName);
    const currentMonthIndex = yearlyMonths.findIndex((month)=>{
        return month.label === currentMonthName
    });

    return yearlyMonths[currentMonthIndex+nextMonthCount];
 }
    
export const getPreviousYears = (startYear = 1980) => {
    let currentYear = new Date().getFullYear();
    let years = [];
    while ( startYear <= currentYear ) {
        years.push(startYear++);
    }  
    return years; 
}

export const roles = () => {
    const roles = [
        {
            "_id": "636e636b9d625d561ffd2771",
            "name": "BASIC USER",
            "description": "BASIC USER",
            "slug": "basic-user",
            "level_type": "basic-user",
            "level_scope": "none",
            "weight": 1,
            "permissions": "[]",
            "removable": 0,
            "id": "636e636b9d625d561ffd2771"
        },
        {
            "_id": "636e636b9d625d561ffd2772",
            "name": "RPMS MEMBER",
            "description": "RPMS MEMBER",
            "slug": "rpms-member",
            "level_type": "rpms",
            "level_scope": "rpms",
            "weight": 26,
            "permissions": "[]",
            "removable": 0,
            "id": "636e636b9d625d561ffd2772"
        },
        {
            "_id": "636e636b9d625d561ffd2773",
            "name": "RPMS ACCOUNTANT",
            "description": "RPMS ACCOUNTANT",
            "slug": "rpms-accountant",
            "level_type": "rpms",
            "level_scope": "rpms",
            "weight": 25,
            "permissions": "[]",
            "removable": 0,
            "id": "636e636b9d625d561ffd2773"
        },
        {
            "_id": "636e636b9d625d561ffd2774",
            "name": "RPMS ADMIN",
            "description": "RPMS ADMIN",
            "slug": "rpms-admin",
            "level_type": "rpms",
            "level_scope": "rpms",
            "weight": 30,
            "permissions": "[]",
            "removable": 0,
            "id": "636e636b9d625d561ffd2774"
        },
        {
            "_id": "636e636b9d625d561ffd2775",
            "name": "PARISH ADMIN",
            "description": "PARISH ADMIN",
            "slug": "parish-admin",
            "level_type": "parish",
            "level_scope": "admin",
            "weight": 85,
            "permissions": "[]",
            "removable": 0,
            "id": "636e636b9d625d561ffd2775"
        },
        {
            "_id": "636e636b9d625d561ffd2776",
            "name": "PARISH ACCOUNTANT",
            "description": "PARISH ACCOUNTANT",
            "slug": "parish-accountant",
            "level_type": "parish",
            "level_scope": "accountant",
            "weight": 86,
            "permissions": "[]",
            "removable": 0,
            "id": "636e636b9d625d561ffd2776"
        },
        {
            "_id": "636e636b9d625d561ffd2777",
            "name": "PIC PARISH",
            "description": "PIC PARISH",
            "slug": "pic-parish",
            "level_type": "parish",
            "level_scope": "pastor",
            "weight": 100,
            "permissions": "[]",
            "removable": 0,
            "id": "636e636b9d625d561ffd2777"
        },
        {
            "_id": "636e636b9d625d561ffd2778",
            "name": "AREA ADMIN",
            "description": "AREA ADMIN",
            "slug": "area-admin",
            "level_type": "area",
            "level_scope": "admin",
            "weight": 185,
            "permissions": "[]",
            "removable": 0,
            "id": "636e636b9d625d561ffd2778"
        },
        {
            "_id": "636e636b9d625d561ffd2779",
            "name": "AREA ACCOUNTANT",
            "description": "AREA ACCOUNTANT",
            "slug": "area-accountant",
            "level_type": "area",
            "level_scope": "accountant",
            "weight": 186,
            "permissions": "[]",
            "removable": 0,
            "id": "636e636b9d625d561ffd2779"
        },
        {
            "_id": "636e636b9d625d561ffd277a",
            "name": "PIC AREA",
            "description": "PIC AREA",
            "slug": "pic-area",
            "level_type": "area",
            "level_scope": "pastor",
            "weight": 200,
            "permissions": "[]",
            "removable": 0,
            "id": "636e636b9d625d561ffd277a"
        },
        {
            "_id": "636e636b9d625d561ffd277b",
            "name": "PIC ZONE",
            "description": "PIC ZONE",
            "slug": "pic-zone",
            "level_type": "zone",
            "level_scope": "pastor",
            "weight": 300,
            "permissions": "[]",
            "removable": 0,
            "id": "636e636b9d625d561ffd277b"
        },
        {
            "_id": "636e636b9d625d561ffd277c",
            "name": "PROVINCE ASSISTANT ADMIN",
            "description": "PROVINCE ASSISTANT ADMIN",
            "slug": "prov-asst-admin",
            "level_type": "province",
            "level_scope": "admin",
            "weight": 335,
            "permissions": "[]",
            "removable": 0,
            "id": "636e636b9d625d561ffd277c"
        },
        {
            "_id": "636e636b9d625d561ffd277d",
            "name": "PROVINCE ASSISTANT ACCOUNTANT",
            "description": "PROVINCE ASSISTANT ACCOUNTANT",
            "slug": "prov-asst-accountant",
            "level_type": "province",
            "level_scope": "accountant",
            "weight": 336,
            "permissions": "[]",
            "removable": 0,
            "id": "636e636b9d625d561ffd277d"
        },
        {
            "_id": "636e636b9d625d561ffd277e",
            "name": "PROVINCE ADMIN",
            "description": "PROVINCE ADMIN",
            "slug": "prov-admin",
            "level_type": "province",
            "level_scope": "admin",
            "weight": 385,
            "permissions": "[]",
            "removable": 0,
            "id": "636e636b9d625d561ffd277e"
        },
        {
            "_id": "636e636b9d625d561ffd277f",
            "name": "PROVINCE ACCOUNTANT",
            "description": "PROVINCE ACCOUNTANT",
            "slug": "prov-accountant",
            "level_type": "province",
            "level_scope": "accountant",
            "weight": 386,
            "permissions": "[]",
            "removable": 0,
            "id": "636e636b9d625d561ffd277f"
        },
        {
            "_id": "636e636b9d625d561ffd2780",
            "name": "APICP CSR",
            "description": "APICP CSR",
            "slug": "apicp-csr",
            "level_type": "province",
            "level_scope": "admin",
            "weight": 390,
            "permissions": "[]",
            "removable": 0,
            "id": "636e636b9d625d561ffd2780"
        },
        {
            "_id": "636e636b9d625d561ffd2781",
            "name": "APICP ADMIN",
            "description": "APICP ADMIN",
            "slug": "apicp-admin",
            "level_type": "province",
            "level_scope": "admin",
            "weight": 395,
            "permissions": "[]",
            "removable": 0,
            "id": "636e636b9d625d561ffd2781"
        },
        {
            "_id": "636e636b9d625d561ffd2782",
            "name": "PIC PROVINCE",
            "description": "PIC PROVINCE",
            "slug": "picp",
            "level_type": "province",
            "level_scope": "pastor",
            "weight": 400,
            "permissions": "[]",
            "removable": 0,
            "id": "636e636b9d625d561ffd2782"
        },
        {
            "_id": "636e636b9d625d561ffd2783",
            "name": "REGIONAL ASSISTANT ADMIN",
            "description": "REGIONAL ASSISTANT ADMIN",
            "slug": "reg-asst-admin",
            "level_type": "region",
            "level_scope": "admin",
            "weight": 435,
            "permissions": "[]",
            "removable": 0,
            "id": "636e636b9d625d561ffd2783"
        },
        {
            "_id": "636e636b9d625d561ffd2784",
            "name": "REGIONAL ASSISTANT ACCOUNTANT",
            "description": "REGIONAL ASSISTANT ACCOUNTANT",
            "slug": "reg-asst-accountant",
            "level_type": "region",
            "level_scope": "accountant",
            "weight": 436,
            "permissions": "[]",
            "removable": 0,
            "id": "636e636b9d625d561ffd2784"
        },
        {
            "_id": "636e636b9d625d561ffd2785",
            "name": "REGIONAL ADMIN",
            "description": "REGIONAL ADMIN",
            "slug": "reg-admin",
            "level_type": "region",
            "level_scope": "admin",
            "weight": 485,
            "permissions": "[]",
            "removable": 0,
            "id": "636e636b9d625d561ffd2785"
        },
        {
            "_id": "636e636b9d625d561ffd2786",
            "name": "REGIONAL ACCOUNTANT",
            "description": "REGIONAL ACCOUNTANT",
            "slug": "reg-accountant",
            "level_type": "region",
            "level_scope": "accountant",
            "weight": 486,
            "permissions": "[]",
            "removable": 0,
            "id": "636e636b9d625d561ffd2786"
        },
        {
            "_id": "636e636b9d625d561ffd2787",
            "name": "ASSISTANT PIC REGION",
            "description": "ASSISTANT PIC REGION",
            "slug": "apicr",
            "level_type": "region",
            "level_scope": "pastor",
            "weight": 495,
            "permissions": "[]",
            "removable": 0,
            "id": "636e636b9d625d561ffd2787"
        },
        {
            "_id": "636e636b9d625d561ffd2788",
            "name": "PIC REGION",
            "description": "PIC REGION",
            "slug": "picr",
            "level_type": "region",
            "level_scope": "pastor",
            "weight": 500,
            "permissions": "[]",
            "removable": 0,
            "id": "636e636b9d625d561ffd2788"
        },
        {
            "_id": "636e636b9d625d561ffd2789",
            "name": "SUB CONTINENT ADMIN",
            "description": "SUB CONTINENT ADMIN",
            "slug": "sub-cont-admin",
            "level_type": "sub-continent",
            "level_scope": "admin",
            "weight": 585,
            "permissions": "[]",
            "removable": 0,
            "id": "636e636b9d625d561ffd2789"
        },
        {
            "_id": "636e636b9d625d561ffd278a",
            "name": "SUB CONTINENT ACCOUNTANT",
            "description": "SUB CONTINENT ACCOUNTANT",
            "slug": "sub-cont-accountant",
            "level_type": "sub-continent",
            "level_scope": "accountant",
            "weight": 586,
            "permissions": "[]",
            "removable": 0,
            "id": "636e636b9d625d561ffd278a"
        },
        {
            "_id": "636e636b9d625d561ffd278b",
            "name": "ASSISTANT SUB CONTINENTAL OVERSEER",
            "description": "ASSISTANT SUB CONTINENTAL OVERSEER",
            "slug": "asco",
            "level_type": "sub-continent",
            "level_scope": "pastor",
            "weight": 595,
            "permissions": "[]",
            "removable": 0,
            "id": "636e636b9d625d561ffd278b"
        },
        {
            "_id": "636e636b9d625d561ffd278c",
            "name": "SUB CONTINENTAL OVERSEER",
            "description": "SUB CONTINENTAL OVERSEER",
            "slug": "sco",
            "level_type": "sub-continent",
            "level_scope": "pastor",
            "weight": 600,
            "permissions": "[]",
            "removable": 0,
            "id": "636e636b9d625d561ffd278c"
        },
        {
            "_id": "636e636b9d625d561ffd278d",
            "name": "CONTINENT ADMIN",
            "description": "CONTINENT ADMIN",
            "slug": "cont-admin",
            "level_type": "continent",
            "level_scope": "admin",
            "weight": 685,
            "permissions": "[]",
            "removable": 0,
            "id": "636e636b9d625d561ffd278d"
        },
        {
            "_id": "636e636b9d625d561ffd278e",
            "name": "CONTINENT ACCOUNTANT",
            "description": "CONTINENT ACCOUNTANT",
            "slug": "cont-accountant",
            "level_type": "continent",
            "level_scope": "accountant",
            "weight": 686,
            "permissions": "[]",
            "removable": 0,
            "id": "636e636b9d625d561ffd278e"
        }
    ]

    return roles;
}

export const roleSlugs = () => {
    const roles = [
    "basic-user",
    "rpms-member",
    "rpms-accountant",
    "rpms-admin",
    "parish-admin",
    "parish-accountant",
    "pic-parish",
    "area-admin",
    "area-accountant",
    "pic-area",
    "pic-zone",
    "prov-asst-admin",
    "prov-asst-accountant",
    "prov-admin",
    "prov-accountant",
    "apicp-csr",
    "apicp-admin",
    "picp",
    "reg-asst-admin",
    "reg-asst-accountant",
    "reg-admin",
    "reg-accountant",
    "apicr",
    "picr",
    "sub-cont-admin",
    "sub-cont-accountant",
    "asco",
    "sco",
    "cont-admin",
    "cont-accountant",
    "co", 
    ]

    return roles;
}

export const getBasicUserRoles = () => {
    return [
        "basic-user",
        "rpms-member",
        "rpms-accountant",
        "rpms-admin",
    ];
}
export const getParishRoles = () => {
    return [
        "parish-admin",
        "parish-accountant",
        "pic-parish"
    ];
}

export const getAreaRoles = () => {
    return [
        "area-admin",
        "area-accountant",
        "pic-area"
    ];
}
export const getZoneRoles = () => {
    return [
        "pic-zone"
    ];
}
export const getProvinceRoles = () => {
    return [
        "prov-asst-admin",
        "prov-asst-accountant",
        "prov-admin",
        "prov-accountant",
        "apicp-csr",
        "apicp-admin",
        "picp"
    ];
}
export const getRegionRoles = () => {
    return [
        "reg-asst-admin",
        "reg-asst-accountant",
        "reg-admin",
        "reg-accountant",
        "apicr",
        "picr"
    ];
}
export const getSubContinentRoles = () => {
    return [
        "sub-cont-admin",
        "sub-cont-accountant",
        "asco",
        "sco"
    ];
}
export const getContinentRoles = () => {
    return [
        "cont-admin",
        "cont-accountant",
        "co"
    ];
}

export const weekCount = (year, monthNumber) => { // Weeks start on Sunday This ought to work even when February doesn't start on Sunday.
    // monthNumber is in the range 1..12
    const firstOfMonth = new Date(year, monthNumber-1, 1);
    const lastOfMonth = new Date(year, monthNumber, 0);

    const used = firstOfMonth.getDay() + lastOfMonth.getDate();

    return Math.ceil( used / 7);
}

export const weekCountStartMonday = (year, monthNumber) => { // Weeks start on Monday
    // monthNumber is in the range 1..12
    const firstOfMonth = new Date(year, monthNumber-1, 1);
    const lastOfMonth = new Date(year, monthNumber, 0);
    const used = firstOfMonth.getDay() + 6 + lastOfMonth.getDate();
    return Math.ceil( used / 7);
}

export const weekCountStartAnotherDay = (year, monthNumber, startDayOfWeek) => { // Weeks start another day
  // monthNumber is in the range 1..12
  // Get the first day of week week day (0: Sunday, 1: Monday, ...)
  const firstDayOfWeek = startDayOfWeek || 0;
  const firstOfMonth = new Date(year, monthNumber-1, 1);
  const lastOfMonth = new Date(year, monthNumber, 0);
  const numberOfDaysInMonth = lastOfMonth.getDate();
  const firstWeekDay = (firstOfMonth.getDay() - firstDayOfWeek + 7) % 7;

  const used = firstWeekDay + numberOfDaysInMonth;

  return Math.ceil( used / 7);
}

export const getNumberOfSundays = (year, month) => {
    let day; 
    let counter;
    let date;

    day = 1;
    counter = 0;
    date = new Date(year, month, day);
    while (date.getMonth() === month) {
        if (date.getDay() === 0) { // Sun=0, Mon=1, Tue=2, etc.
            counter += 1;
        }
        day += 1;
        date = new Date(year, month, day);
    }
    return counter;
}

export const getHQs = ({type, inType, inTypeCode}) => {
    let hqColumn = '';

    type = type.toLowerCase();
    inType = inType.toLowerCase();

    if(type === 'continent'){
        hqColumn = 'chq';
    }
    else if(type === 'subcontinent'){
        hqColumn = 'schq';
    }
    else if(type === 'region'){
        hqColumn = 'rhq';
    }
    else if(type === 'province'){
        hqColumn = 'phq';
    }
    else if(type === 'zone'){
        hqColumn = 'zhq';
    }
    else if(type === 'area'){
        hqColumn = 'ahq';
    }

    let results = [];

    if(type === 'parish'){
        results = allParishes?.filter((parish) => parish[`${inType === 'subcontinent' ? 'subContinent' : inType}Code`] === inTypeCode);
    }else{
        results = allParishes?.filter((parish) => parish[`${inType === 'subcontinent' ? 'subContinent' : inType}Code`] === inTypeCode && parish[`${hqColumn}`] === '1');
    }

    return results;
};

export const getAllContinentHQs = () => {
    return allParishes?.filter((parish) => parish.chq === '1');
};

export const getAllSubContinentHQs = ({ continentCode }) => {
    return allParishes?.filter((record) => record.continentCode === continentCode && record.schq === '1');
};

export const getAllRegionHQs = ({ subContinentCode, contCode }) => {
    let result = [];

    if(subContinentCode){
        result = allParishes?.filter(
            (parish) => parish.subContinentCode === subContinentCode && parish.rhq === '1'
        );
    }
    else if(contCode){
        result = allParishes?.filter(
            (parish) => parish.continentCode === contCode && parish.rhq === '1'
        );
    }

    return result;
};

export const getAllProvinceHQs = ({ regionCode, subContCode, contCode }) => {
    let result = [];

    if(regionCode){
        result = allParishes?.filter(
            (parish) => parish.regionCode === regionCode && parish.phq === '1'
        );
    }
    else if(subContCode){
        result = allParishes?.filter(
            (parish) => parish.subContinentCode === subContCode && parish.phq === '1'
        );
    }
    else if(contCode){
        result = allParishes?.filter(
            (parish) => parish.continentCode === contCode && parish.phq === '1'
        );
    }

    return result;
};

export const getAllZoneHQs = ({ provinceCode, regionCode, subContCode, contCode }) => {
    let result = [];
    
    if(provinceCode){
        result = allParishes?.filter(
            (parish) => parish.provinceCode === provinceCode && parish.zhq === '1'
        );
    }
    else if(regionCode){
        result = allParishes?.filter(
            (parish) => parish.regionCode === regionCode && parish.zhq === '1'
        );
    }
    else if(subContCode){
        result = allParishes?.filter(
            (parish) => parish.subContinentCode === subContCode && parish.zhq === '1'
        );
    }
    else if(contCode){
        result = allParishes?.filter(
            (parish) => parish.continentCode === contCode && parish.zhq === '1'
        );
    }

    return result;
};

export const getAllAreaHQs = ({ zoneCode, provCode, regionCode, subContCode, contCode }) => {
    let result = [];

    if(zoneCode){
        result = allParishes?.filter(
            (parish) => parish.zoneCode === zoneCode && parish.ahq === '1'
        );
    }
    else if(provCode){
        result = allParishes?.filter(
            (parish) => parish.provinceCode === provCode && parish.ahq === '1'
        );
    }
    else if(regionCode){
        result = allParishes?.filter(
            (parish) => parish.regionCode === regionCode && parish.ahq === '1'
        );
    }
    else if(subContCode){
        result = allParishes?.filter(
            (parish) => parish.subContinentCode === subContCode && parish.ahq === '1'
        );
    }
    else if(contCode){
        result = allParishes?.filter(
            (parish) => parish.continentCode === contCode && parish.ahq === '1'
        );
    }

    return result;
};

export const getAllParishes = ({ areaCode }) => {
    return allParishes?.filter((parish) => parish.areaCode === areaCode);
};

export const getParishesInHierarchy = (hierarchy, hierarchyCode ) => {
    hierarchy = hierarchy.toLowerCase();

    let parishes = [];

    if(hierarchy === 'continent'){
        parishes = allParishes?.filter((parish) => parish.continentCode === hierarchyCode);
    }
    else if(hierarchy === 'subcontinent'){
        parishes = allParishes?.filter((parish) => parish.subContinentCode === hierarchyCode);
    }
    else if(hierarchy === 'region'){
        parishes = allParishes?.filter((parish) => parish.regionCode === hierarchyCode);
    }
    else if(hierarchy === 'province'){
        parishes = allParishes?.filter((parish) => parish.provinceCode === hierarchyCode);
    }
    else if(hierarchy === 'zone'){
        parishes = allParishes?.filter((parish) => parish.zoneCode === hierarchyCode);
    }
    else if(hierarchy === 'area'){
        parishes = allParishes?.filter((parish) => parish.areaCode === hierarchyCode);
    }
    return parishes;
};

export const getAllDepartments = () => {
    return encryptStorage.getItem('allDepartments') || [];
};

export const getOneDepartment = (deptCode) => {
    return encryptStorage
        .getItem('allDepartments')
        ?.filter((department) => department.parishCode === deptCode)[0];
};

export const getParishDetails = (parishCode) =>
    allParishes?.filter((parish) => parish.parishCode === parishCode)[0];

export const getParishInfo = (parishCode) =>
    allParishes?.find((parish) => parish.parishCode === parishCode);

export const getParishByAnyCode = (code, type) => {
    type = type?.toLowerCase();
 
    if (type === 'continent')
        return allParishes?.filter(
            (parish) => parish.continentCode === code
        )[0];
    else if (type === 'subcontinent')
        return allParishes?.filter(
            (parish) => parish.subContinentCode === code
        )[0];
    else if (type === 'region')
        return allParishes?.filter((parish) => parish.regionCode === code)[0];
    else if (type === 'province')
        return allParishes?.filter((parish) => parish.provinceCode === code)[0];
    else if (type === 'zone')
        return allParishes?.filter((parish) => parish.zoneCode === code)[0];
    else if (type === 'area')
        return allParishes?.filter((parish) => parish.areaCode === code)[0];
    else if (type === 'parish')
        return allParishes?.filter((parish) => parish.parishCode === code)[0];
    else if (type === 'department')
        return getAllDepartments()?.filter((parish) => parish.parishCode === code)[0];

    return null;
};

export const getParishDetailsById = (parishId) =>
    allParishes?.filter((parish) => parish.id === parishId)[0];

export const getParishName = (parishCode) =>
    allParishes?.find((parish) => parish.parishCode === parishCode)?.parishName;

export const getAreaName = ({parishCode = null, areaCode = null}) =>  allParishes?.find((parish) => parish.parishCode === parishCode || parish.areaCode === areaCode)?.areaName;

export const getZoneName = ({parishCode = null, areaCode = null, zoneCode = null}) =>
    allParishes?.find((parish) => parish.parishCode === parishCode || parish.areaCode === areaCode || parish.zoneCode === zoneCode)?.zoneName;

export const getProvinceName = ({parishCode = null, areaCode = null, zoneCode = null, provinceCode = null}) =>
    allParishes?.find((parish) => parish.parishCode === parishCode || parish.areaCode === areaCode || parish.zoneCode === zoneCode || parish.provinceCode === provinceCode )?.provinceName;

export const getRegionName = ({parishCode = null, areaCode = null, zoneCode = null, provinceCode = null, regionCode = null}) =>
    allParishes?.find((parish) => parish.parishCode === parishCode || parish.areaCode === areaCode || parish.zoneCode === zoneCode || parish.provinceCode === provinceCode || parish.regionCode === regionCode )?.regionName;

export const getSubContinentName = ({parishCode = null, areaCode = null, zoneCode = null, provinceCode = null, regionCode = null, subContinentCode = null}) =>
    allParishes?.find((parish) => parish.parishCode === parishCode || parish.areaCode === areaCode || parish.zoneCode === zoneCode || parish.provinceCode === provinceCode || parish.regionCode === regionCode || parish.subContinentCode === subContinentCode )?.subContinentName;

export const getContinentName = ({parishCode = null, areaCode = null, zoneCode = null, provinceCode = null, regionCode = null, subContinentCode = null, continentCode = null}) =>
    allParishes?.find((parish) => parish.parishCode === parishCode || parish.areaCode === areaCode || parish.zoneCode === zoneCode || parish.provinceCode === provinceCode || parish.regionCode === regionCode || parish.subContinentCode === subContinentCode || parish.continentCode === continentCode)?.continentName;

export const loggedInParish = user ? allParishes?.find(
    (parish) => parish?.parishCode === user.parish
) : null;

// export const getUserCountry = () => {
//     return encryptStorageLocal.getItem('userCountry') || {};
// };

export const userCountry = encryptStorageLocal.getItem('userCountry') || '';

export const getChurchHierarchiesByLevel = (userLevel = 7, isLevelInclusive = false) => {
    let hierarchies = [];
    const churchHierarchies = financeConfig?.churchHierarchies || [];
 
    if(isLevelInclusive){
        if(userLevel === 2){
            hierarchies = churchHierarchies.filter((item)=> item.name === 'Parish' || item.name === 'Area');
        }
        else if(userLevel === 3){
            hierarchies = churchHierarchies.filter((item)=> item.name === 'Parish' || item.name === 'Area' || item.name === 'Area' || item.name === 'Zone');
        }
        else if(userLevel === 4){
            hierarchies = churchHierarchies.filter((item)=> item.name === 'Parish' || item.name === 'Area' || item.name === 'Area' || item.name === 'Zone' || item.name === 'Province');
        }
        
        else if(userLevel === 5){
            hierarchies = churchHierarchies.filter((item)=> item.name === 'Parish' || item.name === 'Area' || item.name === 'Area' || item.name === 'Zone' || item.name === 'Province' || item.name === 'Region');
        }
        else if(userLevel === 6){
            hierarchies = churchHierarchies.filter((item)=> item.name === 'Parish' || item.name === 'Area' || item.name === 'Area' || item.name === 'Zone' || item.name === 'Province' || item.name === 'Region' || item.name === 'SubContinent');
        }
        else if(userLevel === 7){
            hierarchies = churchHierarchies.filter((item)=> item.name === 'Parish' || item.name === 'Area' || item.name === 'Area' || item.name === 'Zone' || item.name === 'Province' || item.name === 'Region' || item.name === 'SubContinent' || item.name === 'Continent');
        }
    }
    else{
        hierarchies = [];
        if(userLevel === 2){
            hierarchies = churchHierarchies.filter((item)=> item.name === 'Parish');
        }
        else if(userLevel === 3){
            hierarchies = churchHierarchies.filter((item)=> item.name === 'Parish' || item.name === 'Area');
        }
        else if(userLevel === 4){
            hierarchies = churchHierarchies.filter((item)=> item.name === 'Parish' || item.name === 'Area' || item.name === 'Area' || item.name === 'Zone');
        }
        else if(userLevel === 5){
            hierarchies = churchHierarchies.filter((item)=> item.name === 'Parish' || item.name === 'Area' || item.name === 'Area' || item.name === 'Zone' || item.name === 'Province');
        }
        else if(userLevel === 6){
            hierarchies = churchHierarchies.filter((item)=> item.name === 'Parish' || item.name === 'Area' || item.name === 'Area' || item.name === 'Zone' || item.name === 'Province' || item.name === 'Region');
        }
        else if(userLevel === 7){
            hierarchies = churchHierarchies.filter((item)=> item.name === 'Parish' || item.name === 'Area' || item.name === 'Area' || item.name === 'Zone' || item.name === 'Province' || item.name === 'Region' || item.name === 'SubContinent');
        }
    }

    return hierarchies;
}

export const arrayInArray = (arr1 = [], arr2 = []) => {
    return arr1.some(r => arr2.includes(r))
}

export const getTypeName = (role) => {
    let typeName;
    if(arrayInArray(getParishRoles(), role)){
        typeName = 'parish';
    }
    else if(arrayInArray(getAreaRoles(), role)){
        typeName = 'area';
    }
    else if(arrayInArray(getZoneRoles(), role)){
        typeName = 'zone';
    }
    else if(arrayInArray(getProvinceRoles(), role)){
        typeName = 'province';
    }
    else if(arrayInArray(getRegionRoles(), role)){
        typeName = 'region';
    }
    else if(arrayInArray(getSubContinentRoles(), role)){
        typeName = 'subContinent';
    }
    else if(arrayInArray(getContinentRoles(), role)){
        typeName = 'continent';
    }

    return typeName;
}

export const getTypeCodeFromUser = (role, user) => {
    let typeCode;

    if(arrayInArray(getParishRoles(), role)){
        typeCode = user.parish;
    }
    else if(arrayInArray(getAreaRoles(), role)){
        typeCode = user.area;
    }
    else if(arrayInArray(getZoneRoles(), role)){
        typeCode = user.zone;
    }
    else if(arrayInArray(getProvinceRoles(), role)){
        typeCode = user.province;
    }
    else if(arrayInArray(getRegionRoles(), role)){
        typeCode = user.region;
    }
    else if(arrayInArray(getSubContinentRoles(), role)){
        typeCode = user.subContinent;
    }
    else if(arrayInArray(getContinentRoles(), role)){
        typeCode = user.continent;
    }

    return typeCode;
}

export const getTypeNameWithUserLevel = (level) => {
    level = level < 1 ? 1 : level;
    let typeName;
    if(level === 1){
        typeName = 'parish';
    }
    else if(level === 2){
        typeName = 'area';
    }
    else if(level === 3){
        typeName = 'zone';
    }
    else if(level === 4){
        typeName = 'province';
    }
    else if(level === 5){
        typeName = 'region';
    }
    else if(level === 6){
        typeName = 'subContinent';
    }
    else if(level === 7){
        typeName = 'continent';
    }

    return typeName;
}

export const getTypeCodeFromUserLevel = (level, user) => {
    let typeCode;

    if(level === 1){
        typeCode = user.parish;
    }
    else if(level === 2){
        typeCode = user.area;
    }
    else if(level === 3){
        typeCode = user.zone;
    }
    else if(level === 4){
        typeCode = user.province;
    }
    else if(level === 5){
        typeCode = user.region;
    }
    else if(level === 6){
        typeCode = user.subContinent;
    }
    else if(level === 7){
        typeCode = user.continent;
    }

    return typeCode;
}

export const getRoleNameByAlias = (roleAlias) => {
    let roleName;

    if(roleAlias === 'co')
        roleName = 'Continent';
    else if(roleAlias === 'cont-accountant')
        roleName = 'Continent Accountant';
    else if(roleAlias === 'cont-admin')
        roleName = 'Continent Admin';
    else if(roleAlias === 'sco')
        roleName = 'Sub Continent';
    else if(roleAlias === 'sub-cont-accountant')
        roleName = 'Sub Continent Accountant';
    else if(roleAlias === 'sub-cont-admin')
        roleName = 'Sub Continent Admin';
    else if(roleAlias === 'reg-asst-admin')
        roleName = 'Region Assistant Admin';
    else if(roleAlias === 'reg-asst-accountant')
        roleName = 'Region Assistant Accountant';
    else if(roleAlias === 'reg-admin')
        roleName = 'Region Admin';    
    else if(roleAlias === 'reg-accountant')
        roleName = 'Region Accountant';
    else if(roleAlias === 'apicr')
        roleName = 'Assistant Pastor In Charge of Region';    
    else if(roleAlias === 'picr')
        roleName = 'Pastor In Charge of Region';    
    else if(roleAlias === 'prov-asst-admin')
        roleName = 'Province Assistant Admin';
    else if(roleAlias === 'prov-asst-accountant')
        roleName = 'Province Assistant Accountant';
    else if(roleAlias === 'prov-admin')
        roleName = 'Province Admin';    
    else if(roleAlias === 'prov-accountant')
        roleName = 'Province Accountant';
    else if(roleAlias === 'apicp-admin')
        roleName = 'Assistant Pastor In Charge of Province Admin';    
    else if(roleAlias === 'picp')
        roleName = 'Pastor In Charge of Province';    
    else if(roleAlias === 'apicp-csr')
        roleName = 'Assistant Pastor In Charge of Province CSR';    
    else if(roleAlias === 'pic-zone')
        roleName = 'Pastor In Charge of Zone';    
    else if(roleAlias === 'area-admin')
        roleName = 'Area Administrator';    
    else if(roleAlias === 'area-accountant')
        roleName = 'Area Accountant';    
    else if(roleAlias === 'pic-area')
        roleName = 'Pastor In Charge of Area';    
    else if(roleAlias === 'parish-admin')
        roleName = 'Parish Administrator';    
    else if(roleAlias === 'parish-accountant')
        roleName = 'Parish Accountant';    
    else if(roleAlias === 'pic-parish')
        roleName = 'Pastor In Charge of Parish';    
    else if(roleAlias === 'rpms-accountant')
        roleName = 'RPMS Accountant';    
    else if(roleAlias === 'rpms-admin')
        roleName = 'RPMS Administrator';    

    return roleName;        
}

export const allRolesObj = {
    parishAdmin: "parish-admin",
    parishAccountant: "parish-accountant",
    picParish: "pic-parish",
    areaAdmin: "area-admin",
    areaAccountant: "area-accountant",
    picArea: "pic-area",    
    picZoneAccountant: "zone-accountant",
    picZone: "pic-zone",
    provAsstAdmin: "prov-asst-admin",
    provAsstAccountant: "prov-asst-accountant",
    provAdmin: "prov-admin",
    provAccountant: "prov-accountant",
    apicpAdmin: "apicp-admin",
    apicpCSR: "apicp-csr",
    picp: "picp", 
    regAsstAdmin: "reg-asst-admin",
    regAsstAccountant: "reg-asst-accountant",
    regAdmin: "reg-admin",
    regAccountant: "reg-accountant",
    apicr: "apicr",
    picr: "picr",
    subContAccountant: "sub-cont-accountant",
    subContAdmin: "sub-cont-admin", 
    sco: "sco",
    contAdmin: "cont-admin",
    contAccountant: "cont-accountant",
    co: "co",
    rpmsAdmin: "rpms-admin",
    rpmsAccountant: "rpms-accountant",
    super: "super-admin"
}

export const continentRoles = ["co","cont-accountant", "cont-admin"];
export const subContinentRoles = ["sco","sub-cont-accountant","sub-cont-admin"];
export const regionRoles = ["reg-asst-admin","reg-asst-accountant","reg-admin","reg-accountant","apicr","picr"];
export const provinceRoles = ["prov-asst-admin","prov-asst-accountant","prov-admin","prov-accountant","apicp-admin","picp", "apicp-csr"];
export const zoneRoles = ["pic-zone"];
export const areaRoles = ["area-admin","area-accountant", "pic-area"];
export const parishRoles = ["parish-admin","parish-accountant","pic-parish"];
export const otherRoles = ["rpms-accountant","rpms-admin"];
export const financeBankAccountApprovalAdminRoles = encryptStorage.getItem('financeBankAccountApprovalAdminRoles') || [];

export function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
}

export function makeLowerCaseFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}

export function getChurchesByType (type, user){
    type = type.toLowerCase();
    let churches = [];

    switch (type) {
      case 'continent':
        churches = getAllContinentHQs();
        break;
    
      case 'subcontinent':
        churches = getAllSubContinentHQs({continentCode: user.continent});
        break;
    
      case 'region':
        churches = getAllRegionHQs({subContinentCode: user.subContinent});
        break;
    
      case 'province':
        churches = getAllProvinceHQs({regionCode: user.region});
        break;

      case 'zone':
        churches = getAllZoneHQs({provinceCode: user.province});
        break;
  

      case 'area':
        churches = getAllAreaHQs({zoneCode: user.zone});
        break;
  
      case 'parish':
        churches = getAllParishes({areaCode: user.area});
        break;

      case 'department':
        churches = getAllDepartments();
        break;

        default:
            churches = [];
        break;
    }

    return churches;
  }

  export function generateRandomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

export function replaceUpperCaseLettersInAString(str){
    // const res = name.replace(/[A-Z][a-z]*/g, str => '-' + str.toLowerCase() + '-')
    return str.replace(/[A-Z][a-z]*/g, s => ` ${s.toLowerCase()} `)
    // Convert words to lower case and add hyphens around it (for stuff like "&")
    .replace('--', '-') // remove double hyphens
    .replace(/(^-)|(-$)/g, ''); // remove hyphens at the beginning and the end
}

export function handlePrint ({containerId}){
    let printContents = document.getElementById(containerId).innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents; 
}

export function downloadPDF({pdfRef, fileName = 'rccg-eremittance.pdf'}){
    const validFileExt = '.pdf';
    const ext = fileName.substring(fileName.length-4, fileName.length);
    fileName = validFileExt === ext ? fileName : fileName+validFileExt;
    const input = pdfRef.current;

    html2canvas(input).then((canvas)=>{
        const imgData = canvas.toDataURL('image/png');
        const pdf = new JsPDF('p', 'mm', 'a4', true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = (pdfWidth - imgWidth * ratio) / 2;
        const imgY = 0; // or 30 but to top margin will be high
        pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
        pdf.save(fileName);
    });
}

export function copyText(elemId){
    const elTable = document.querySelector(`#${elemId}`);
    let range;
    let sel;
    let isCopied = false;
    
    // Ensure that range and selection are supported by the browsers
    if (document.createRange && window.getSelection) {    
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
    
      try {
        range.selectNodeContents(elTable);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(elTable);
        sel.addRange(range);
      }    
      document.execCommand('copy');

      isCopied= true;
    }
    
    sel.removeAllRanges();    
    return isCopied;
  }

export function exportToExcel({dataArr, fileName}){
    const worksheet = XLSX.utils.json_to_sheet(dataArr);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
    // saveAs(blob, `${fileNameWithExt}`);
    saveAs(blob, `${fileName}.xlsx`);
};  

export const getChurchesInHierarchy = ({type, inType, inTypeCode}) => {
    type = type.toLowerCase();
    inType = inType.toLowerCase();
    inType = inType === 'subcontinent' ? 'subContinent' : inType;

    let churches = [];

    if(type === 'continent'){
        churches = getAllContinentHQs();
    }
    else{
        churches = allParishes?.filter((parish) => parish[`${getHQColumn(type)}`] === '1' && parish[`${inType}Code`] === inTypeCode );
    }

    return churches;
};

export const getHQColumn = (type) => {
    type = type.toLowerCase();
    let columnName = '';

    if(type === 'continent'){
        columnName = 'chq';
    }
    else if(type === 'subcontinent'){
        columnName = 'schq';
    }
    else if(type === 'region'){
        columnName = 'rhq';
    }
    else if(type === 'province'){
        columnName = 'phq';
    }
    else if(type === 'zone'){
        columnName = 'zhq';
    }
    else if(type === 'area'){
        columnName = 'ahq';
    }
    else if(type === 'parish'){
        columnName = 'parish';
    }

    return columnName;
}

export const userLevel = getUserHighestLevel(user?.roles);

export const getUserHighestType = (user) => {
    const userLevel = getUserHighestLevel(user.roles);
    return getTypeNameWithUserLevel(userLevel);
}

export const addOrMinusMonthFromMonthAndYear = ({ numOfMonthsToMinus}) => {
    let date = new Date(new Date());
    date.setMonth(date.getMonth() - numOfMonthsToMinus);
    let res = {
        month: date.getUTCMonth() + 1, 
        year: date.getUTCFullYear()
    }
    return res;

    // let obj = new Date(); 
    // let day = obj.getUTCDate(); 
    // let month = obj.getUTCMonth() + 1; 
    // let year = obj.getUTCFullYear(); 
    // // console.log('obj', obj, `Day: ${day}, Month: ${month}, Year: ${year}`);
}

export const getStartAndEndMonthAndYearBasedOnRedeemCalendarYear = () => {
    let curMonth = getCurrentMonth();
    let eYear = new Date().getFullYear();
    let sYear = eYear;    

    if(curMonth !== 'September' && curMonth !== 'October' && curMonth !== 'November' && curMonth !== 'December'){
        sYear--;
    }

    let res = {
        sMonth: 'September', 
        eMonth: curMonth, 
        sYear,
        eYear
    }
    return res;
}

export const getPositionName = (pos) => {
    let positionname = 'th';
    const len = pos.toString().length;
    const lastChar = pos.toString().substring(pos.length-1,1);
    
    if(lastChar === '1' && len === 1){
        positionname = 'st';
    }
    else if(lastChar === '2'){
        positionname = 'nd';
    }
    else if(lastChar === '3'){
        positionname = 'rd';
    }
    
    return positionname;
}

export const getUrlSegment = (segmentNo = 0) => {
    // Get the path from the URL
    const path = window.location.pathname;

    // Split the path into segments
    const segments = path.split('/').filter(Boolean); // filter(Boolean) removes empty strings

    // Get the first segment
    const segment = segments.length > 0 ? segments[segmentNo] : '';
    return segment;
}

export const handleAlert = ({ enqueueSnackbar, t }, { message, type = 'info', vertical = 'bottom', horizontal = 'center' }) => {
    enqueueSnackbar(t(message), {
        variant: type,
        anchorOrigin: {
            vertical,
            horizontal,
        },
        TransitionComponent: Zoom,
    });
};

export const restoreDepartments = async () => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_AUTH_AND_DIRECTORY_ENDPOINT_BASE_URL}/v1/parishDirectory/search?pageNo=1&pageSize=1000000`,
            {
                orAnd: 'and',
                params: [
                    {
                        columnName: 'parishType',
                        columnLogic: 'LIKE',
                        columnValue: 'DEPARTMENT'
                    },
                    {
                        columnName: 'status',
                        columnLogic: 'LIKE',
                        columnValue: '1'
                    }
                ]
            }
        );

        const { records } = res.data;

        if (Array.isArray(records) && records.length > 0) {
            encryptStorage.setItem(
                'allDepartments',
                JSON.stringify(records)
            );
        }
    } catch (err) {
        console.error('err: ', err);
    }
};

// export const paymentStatuses = ["NOT INITIATED", "INITIATED", "PARTIAL", "COMPLETED", "UNKNOWN"]
export const PAYMENT_STATUSES = {
    NOT_INITIATED: 'NOT INITIATED',
    INITIATED: 'INITIATED',
    PARTIAL: 'PARTIAL',
    COMPLETED: 'COMPLETED',
    UNKNOWN: 'UNKNOWN'
}

export const getUniqueMissionUnit = (churches, type) => {
    // Map of types to their respective keys    
    type = type?.toLowerCase();
    // console.log('type', type, churches);
    const typeMap = {
      region: { codeKey: 'regionCode', hqKey: 'rhq' },
      province: { codeKey: 'provinceCode', hqKey: 'phq' },
      zone: { codeKey: 'zoneCode', hqKey: 'zhq' },
      area: { codeKey: 'areaCode', hqKey: 'ahq' },
      parish: { codeKey: 'parishCode', hqKey: null },
      subcontinent: { codeKey: 'subContinentCode', hqKey: 'schq' },
      continent: { codeKey: 'continentCode', hqKey: 'chq' },
    };
  
    const { codeKey, hqKey } = typeMap[type];
    if (!codeKey) {
      console.error(`Invalid type: ${type}`);
      return [];
    }
  
    const filtered = churches.filter((church) => (hqKey ? church[hqKey] === '1' : true));
  
    // Deduplicate based on the `codeKey`
    const unique = [...new Map(filtered.map((item) => [item[codeKey], item])).values()];
  
    return unique.map((item) => ({
      [codeKey]: item[codeKey],
      ...(hqKey ? { [hqKey]: item[hqKey] } : {}),
      ...item
    }));
  };

  export const filterDuplicates = (array = [], key) => {
    if (!key) {
        throw new Error("A key or array of keys must be provided for filtering duplicates.");
    }

    const keyArray = Array.isArray(key) ? key : [key];
    const seen = new Set();

    return array.filter(item => {
        const identifier = keyArray.map(k => item[k]).join("|");
        if (seen.has(identifier)) {
            return false;
        }
        seen.add(identifier);
        return true;
    });
}

export const reFetchDepartments = async () => {
    let departments = [];

    try {
        const res = await axios.post(
            `${process.env.REACT_APP_AUTH_AND_DIRECTORY_ENDPOINT_BASE_URL}/v1/parishDirectory/search?pageNo=1&pageSize=1000000`,
            {
                orAnd: 'and',
                params: [
                    {
                        columnName: 'parishType',
                        columnLogic: 'LIKE',
                        columnValue: 'DEPARTMENT'
                    },
                    {
                        columnName: 'status',
                        columnLogic: 'LIKE',
                        columnValue: '1'
                    }
                ]
            }
        );

        const { records } = res.data;

        if (Array.isArray(records) && records.length > 0) {
            departments = records;
            encryptStorage.setItem(
                'allDepartments',
                JSON.stringify(records)
            );
        }        
    } catch (err) {
        console.error('err: ', err);
    }
     
    return departments;
}